<a
  tabindex="0"
  class="btn btn-lg border-0 p-0 btn-pop d-flex justify-content-center align-items-center"
  role="button"
  data-toggle="popover"
  data-trigger="hover"
  [attr.title]="title"
  [attr.data-content]="content"
  ><img src="assets/images/info.svg" alt="" title="" width="" height=""
/></a>
