import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { DateUtil } from '../utils/date-util';
import { TranslateService } from '@ngx-translate/core';
import { AlarmType } from '../enums/alarm-type.enum';
import { Utility } from '../utils/app-utils';
import { AlarmStatus } from '../enums/alarm-status.enum';

@Component({
	selector: 'app-alarm-details',
	templateUrl: './alarm-details.component.html',
	styleUrls: ['./alarm-details.component.scss']
})
export class AlarmDetailsComponent implements OnInit, OnDestroy {
	@Input() alarm: Alarm;
	appConstants = AppConstants;
	alarmType = AlarmType;
	utility = Utility;
	alarmInfo: string;
	formattedDate: string;
	activeSince: string;

	status = [
		{ status: AlarmStatus.OPEN, icon: 'fa-hourglass-end' },
		{ status: AlarmStatus.ACKNOWLEDGED, icon: 'fa-exclamation' },
		{ status: AlarmStatus.VERIFIED, icon: 'fa-check' },
		{ status: AlarmStatus.ESCALATED, icon: 'fa-arrow-up' },
		{ status: AlarmStatus.RESOLVED, icon: 'fa-check-circle' },
		{ status: AlarmStatus.FALSE, icon: 'fa-times' },
	];

	constructor(private authService: AuthenticationService, private translate: TranslateService, private cdr: ChangeDetectorRef) { }

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.alarm.currentValue) {
			this.alarm = changes.alarm.currentValue;
			this.activeSince = this.getActiveSince(this.alarm.activationTime);
			this.formattedDate = this.getFormatedDate(this.alarm.activationTime, this.appConstants.DATE_FORMAT);

			this.alarmInfo = this.alarm.info ? this.alarm.info : this.translate.instant('ALARM_DETAILS.NO_INFO');

			// manually trigger change detection
			this.cdr.detectChanges();
		}
	}

	ngOnInit() { }

	ngOnDestroy() { }

	/**
	 * Returns date in format dd/MM/yyyy;
	 * @param activationTime activation time
	 */
	getFormatedDate(activationTime: any, format: string) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * Returns since when the alarm is active.
	 * @param activationTime Time in milliseconds
	 */
	getActiveSince(activationTime: any) {
		// TODO CHANGE API to return date in number format
		activationTime = parseInt(activationTime, 10);
		if (!activationTime) {
			return;
		}
		return DateUtil.getActiveSince(activationTime, this.translate);
	}

	getTextToShow_address(type: string) {
		type = type.trim();
		return type.length > 30 ? type.substring(0, 29) + '...' : type;
	}

	getTextToShow_info(type: string) {
		type = type.trim();
		return type.length > 101 ? type.substring(0, 100) + '...' : type;
	}

	getIconForStatus(alarmStatus: string): string {
		const foundStatus = this.status.find(s => s.status === alarmStatus);
		return foundStatus ? foundStatus.icon : 'fa-hourglass-end';
	}

}
