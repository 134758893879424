<div class="stats-summary">
	<div class="stats-row-wrapper">
		<div class="stats-row" *ngFor="let row of statistics">
			<app-stats-tile *ngFor="let stats of row"
				[color]="stats.color"
				[value]="stats.count"
				[label]="stats.name"
				[icon]="stats.icon"
				(click)="setSelectedTile(stats.name)"
				class="cites-tiles"
				[class.unselected-tile]="isNotActive(stats.name)"
				[class.all-unselected-tiles]="selectedTile === null"></app-stats-tile>
		</div>
	</div>
</div>