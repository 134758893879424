<div *ngIf="!workflowComplete">
	<div class="question" (mouseover)='read(question?.label)' (mouseout)='stopRead()'>{{question?.label}}</div>
	<div *ngIf="question?.type==='list'" class="mt-3">
		<mat-form-field>
			<mat-select [(value)]="question.selectedId">
				<mat-option *ngFor="let item of question.data" [value]="item.id">
					<span class="select-name">{{item.name}}</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div *ngIf="question?.comment==true">
		<textarea matInput rows="3" [(ngModel)]="comment" [placeholder]="'COMMENT_FORM.PLACEHOLDER' | translate"></textarea>

	</div>
	<div class="d-flex justify-content-center buttons-section mt-3">
		<ng-container>
			<div *ngFor="let button of question?.buttons" class="mt-2">
				<button class="mr-2 btn btn-info" (click)="buttonClicked(button.action)" (mouseover)='read(button.label)'
					(mouseout)='stopRead()' [disabled]="question.disabledBtns"> {{button.label}}</button>
			</div>
		</ng-container>
	</div>
	<div class="hint" *ngIf="question?.hint" (mouseover)='read(question?.hint)' (mouseout)='stopRead()'>
		{{question.hint}}
	</div>
</div>