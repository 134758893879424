import { Component, OnInit } from '@angular/core';
import { ChartService } from 'src/app/services/chart.service';
import * as charts from 'highcharts';
import { AppConstants } from 'src/app/constants/app-constants';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { DateUtil } from 'src/app/utils/date-util';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Utility } from 'src/app/utils/app-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-operator-stats',
	templateUrl: './operator-stats.component.html',
	styleUrls: ['./operator-stats.component.scss']
})
export class OperatorStatsComponent implements OnInit {
	KPICharts: charts.Chart;
	KPI_CHART_ID = 'kpi-chart';
	KPI_CHART_Title = 'kpi-chart';
	operatorsHistoryDataSource = [];
	public selectedOperatorsHistoryId = null;
	public selectedOperatorsHistory;
	selectedAlarmIDs = [];
	filterType = 'OPERATORS';
	appConstants = AppConstants;
	displayedColumns = ['Operators', 'Region', 'RelatedSites', 'Sites', 'Status'];
	operatorsStatsChangeSubscriber: any;
	constructor(
		public chartService: ChartService,
		public analyticalDashboard: AnalyticalDashboardService,
		public authService: AuthenticationService,
		public translate: TranslateService
	) { }

	async ngOnInit() {
		this.getOperatorsStats();
		this.operatorsStatsChangeSubscriber = this.analyticalDashboard.getOperatorsStatsChangeNotification().subscribe(() => {
			this.operatorsHistoryDataSource = this.analyticalDashboard.operatorsReport;
			if (this.operatorsHistoryDataSource.length) {
				this.selectedOperatorsHistoryId = this.operatorsHistoryDataSource[0].id;
				this.selectedOperatorsHistory = this.operatorsHistoryDataSource[0];
				this.analyticalDashboard.selectAlarm(this.selectedOperatorsHistory);
			}
		});
		await this.analyticalDashboard.selectAlarm(this.selectedOperatorsHistory);
	}

	ngOnDestroy() {
		this.operatorsStatsChangeSubscriber = Utility.Unsubscribe(this.operatorsStatsChangeSubscriber);
	}

	/**
	 * used to render KPI chart
	 * call it to show KPI chart
	 */
	renderKPIChart() {
		this.KPICharts = this.chartService.renderKPIChart(this.KPI_CHART_ID, this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_KPI'));
	}

	/**
	 * called to highlight row in table and zoom to map
	 */
	highlight(row: any) {
		this.selectedOperatorsHistoryId = row.id;
		this.selectedOperatorsHistory = row;
		this.analyticalDashboard.selectAlarm(this.selectedOperatorsHistory);
	}

	/**
	 * called to get all operators stats
	 */
	async getOperatorsStats() {
		const response = await this.analyticalDashboard.getOperatorsReport();
		this.operatorsHistoryDataSource = response;
		if (this.operatorsHistoryDataSource.length) {
			this.selectedOperatorsHistoryId = this.operatorsHistoryDataSource[0].id;
			this.selectedOperatorsHistory = this.operatorsHistoryDataSource[0];
			this.analyticalDashboard.selectAlarm(this.selectedOperatorsHistory);
		}
	}

	/**
	 * called to format time from millisecond to required format
	 */
	getFormatedTime(activationTime: any, format: any) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * format multiple operators name by comma
	 */
	getOperatorName(alarmsHistory: any) {
		return alarmsHistory.operator ? `${alarmsHistory.operator.firstName} ${alarmsHistory.operator.lastName}` : '';
	}

	/**
	 * format multiple regions name by comma
	 */
	formatRegionsNames(regions: any) {
		return regions ? regions.map(regions => `${regions}`).join(', ') : '';
	}

	/**
	 * format multiple sites name by comma
	 */
	formatSitesNames(operators: any) {
		return operators ? operators.map(operator => `${operator.siteName}`).join(', ') : '';
	}

	/**
	 * called to get no. of sites
	 */
	formatSitesCounter(operator: any) {
		return operator ? operator.length : 0;
	}

	/**
	 * function called then map loading is done
	 */
	mapLoaded() {
		this.analyticalDashboard.selectAlarm(this.selectedOperatorsHistory);
	}

	/**
	 * called to change height of operator table to make it responsive
	 */
	changeTableSize() {
		this.analyticalDashboard.changeTableSizeOnAddedFilter(AppConstants.OPERATOR_TABLE_CONTAINER);
	}
}
