
import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import 'moment/locale/ar'; // Arabic
import 'moment/locale/ru'; // Russian
import { AppLocalStorage } from './app-local-storage-utils';

export class DateUtil {

	/**
	 * Returns alarm active since quote.
	 *
	 * @param date Timestamp in milliseconds.
	 * @param translateService Translation service instance.
	 */
	static getActiveSince(date: any, translateService: TranslateService) {
		const today = Date.now();
		const milliseconds = date;
		const differenceInMiliSeconds = (today - milliseconds); // milliseconds between now & Christmas
		const diffDays = Math.floor(differenceInMiliSeconds / 86400000); // days
		const diffMonth = Math.floor(diffDays / 30);
		const diffHrs = Math.floor((differenceInMiliSeconds % 86400000) / 3600000); // hours
		const diffMins = Math.round(((differenceInMiliSeconds % 86400000) % 3600000) / 60000);
		const diffSecs = Math.floor(differenceInMiliSeconds / 1000);

		if (diffMonth >= 1) {
			return diffMonth + ' ' + translateService.instant('DATE.MONTHS');
		} else if (diffDays >= 1) {
			return diffDays + ' ' + translateService.instant('DATE.DAYS');
		} else if (diffHrs >= 1) {
			return diffHrs + ' ' + translateService.instant('DATE.HOURS');
		} else if (diffMins >= 1) {
			return diffMins + ' ' + translateService.instant('DATE.MINS');
		} else {
			return diffSecs + ' ' + translateService.instant('DATE.SECS');
		}
	}

	/**
	 * Returns date in specified format
	 *
	 * @param milliSeconds Input date in epoc MilliSeconds.
	 * @param format Date format.
	 * @param timeZone Time zone.
	 */
	public static getFormattedDate(milliSeconds: any, format: string, timeZone: string) {
		const language = AppLocalStorage.getLanguage();
		moment.locale(language);
		// Remove 'a' for am/pm and use 24 hours format if the language is Russian
		if (language === 'ru') {
			format = format.replace('hh', 'HH').replace(' a', '');
		}

		if (timeZone === undefined) {
			return '';
		}

		milliSeconds = parseInt(milliSeconds, 10);
		if (!milliSeconds) {
			return;
		}

		return moment(milliSeconds).tz(timeZone).format(format);
	}


	//////////////////////////////////////////////////////////////////////////////////////////////////////

	// Format string date not milliseconds input
	public static formatDate(date: any, format: string, timeZone: string) {
		if (timeZone === undefined) {
			return '';
		}
		return moment(date).tz(timeZone).format(format);
	}

	//////////////////////////////////////////////////////////////////////////////////////////////////////

	/**
	 * Returns trial period remaining
	 *
	 * @param milliSeconds Input date in MilliSeconds.
	 */
	public static getTrialPeriodRemaining(milliSeconds: any) {
		const days = Math.floor(milliSeconds / (24 * 60 * 60 * 1000));
		const hours = Math.floor((milliSeconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
		const minutes = Math.floor((milliSeconds % (60 * 60 * 1000)) / (60 * 1000));
		const sec = Math.floor((milliSeconds % (60 * 1000)) / (1000));
		return { 'days': days, 'hours': hours, 'minutes': minutes, 'sec': sec };
	}
}
