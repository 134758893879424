import { Subject } from 'rxjs';

export class SnackbarUtil {

	static onSnackbarMessageAdded = new Subject();

	/**
	 * Return observable for snack bar.
	 */
	static getSnackBarAddedLister() {
		return SnackbarUtil.onSnackbarMessageAdded.asObservable();
	}

	/**
	 * Show message on snackbar.
	 * @param messages Error message
	 */
	static showSnackBar(error: any) {
		SnackbarUtil.onSnackbarMessageAdded.next(error);
	}
}
