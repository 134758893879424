import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, SimpleChanges } from '@angular/core';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { Utility } from 'src/app/utils/app-utils';

export interface RmSelectItemData { title: string; value: string; isChecked?: boolean; }
@Component({
	selector: 'app-rm-multi-select',
	templateUrl: './rm-multi-select.component.html',
	styleUrls: ['./rm-multi-select.component.scss']
})
export class RmMultiSelectComponent implements OnInit {

	@Input() placeholder: string;
	@Input() inputData: RmSelectItemData[];
	@Output() dataChanged = new EventEmitter();
	@Output() dropdownToggled = new EventEmitter();
	@Input() showDropDown = false;
	@Input() isSingleSelect = false;

	PreviousSelectedId: any = false;
	data: RmSelectItemData[] = [];
	originalData: RmSelectItemData[] = [];
	isPreviousSelectedValues = false;
	previousSelectedValuesPlaceholder = '';
	uniqueSelectId: string;
	PreviousSelected: any;
	allClearSubscriber: any;

	searchValue: string = '';

	private _dropdownData: RmSelectItemData[] = [];
	get dropdownData(): RmSelectItemData[] {
		return this.searchValue ? this.data : this._dropdownData;
	}
	set dropdownData(value: RmSelectItemData[]) {
		this._dropdownData = value;
		this.data = value;
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any) {
		if (!this.eRef.nativeElement.contains(event.target)) {
			this.changeDropDownStatus();
		}
	}

	constructor(
		public analyticalDashboard: AnalyticalDashboardService,
		public eRef: ElementRef
	) { }

	ngOnInit() {
		this.uniqueSelectId = 'select' + Math.random();
		// this.originalData = JSON.parse(JSON.stringify(this.inputData));
		// this.data = JSON.parse(JSON.stringify(this.originalData));
		this.originalData = this.inputData;
		this.data = this.originalData;

		/**
		 * Clear All filters data
		 */
		this.allClearSubscriber = this.analyticalDashboard.getAllClearNotification().subscribe(() => {
			this.clearSelections();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.inputData) {
			this.originalData = [...this.inputData];
			this.data = [...this.originalData];
			this.clearSelections();
		}
	}

	ngOnDestroy() {
		this.allClearSubscriber = Utility.Unsubscribe(this.allClearSubscriber);
	}

	changeDropDownStatus(toggle: boolean = false) {
		this.showDropDown = toggle;
		// Clear search when dropdown is minimized
		if (!this.showDropDown) {
			this.searchValue = '';
			this.filterOptions();
		}
		this.dropdownToggled.emit(this.showDropDown);
	}


	/**
	 * Persists all the selected items and builds the new placeholder with selected values.
	 */
	saveData() {
		const selectedValues = [];
		const selectedTitles = [];
		// Map the state of isChecked from `data` to `originalData`
		this.originalData.forEach((item) => {
			const dataItem = this.data.find(d => d.value === item.value);
			if (dataItem) {
				item.isChecked = dataItem.isChecked;
			}
		});

		if (this.isSingleSelect) {
			if (this.PreviousSelected) {
				selectedValues.push(this.PreviousSelected.value);
				selectedTitles.push(this.PreviousSelected.title);
				this.previousSelectedValuesPlaceholder = this.PreviousSelected.title;
				this.originalData.forEach((item) => {
					if (item.value !== this.PreviousSelected.value) {
						item.isChecked = false;
					}
				});
			} else {
				this.previousSelectedValuesPlaceholder = '';
			}
		} else {
			this.originalData.forEach((item) => {
				if (item.isChecked) {
					selectedValues.push(item.value);
					selectedTitles.push(item.title);
				}
			});
			this.previousSelectedValuesPlaceholder = selectedTitles.toString();
		}
		this.dataChanged.emit(selectedValues);
		this.changeDropDownStatus();
	}


	/**
	 * Triggered when dropdown toggles. Emits an event indicating dropdown has been toggled.
	 */
	onDropdownToggled() {
		this.showDropDown = !this.showDropDown;
		if (this.showDropDown) {
			this.data = [...this.originalData]; // Copy originalData array
		}
	}

	/**
	 * Unselect all the items && Clear all search filters.
	 */
	clearSelections() {
		this.searchValue = '';
		// this.data = JSON.parse(JSON.stringify(this.originalData));
		this.data = this.originalData;
		this.data.forEach(item => {
			item.isChecked = false;
		});
		this.PreviousSelected = false;
		this.saveData();
	}

	checkSingleSelected(selectedValue: any) {
		if (this.PreviousSelectedId === selectedValue.value) {
			this.PreviousSelectedId = false;
			this.PreviousSelected = false;
		} else {
			this.PreviousSelectedId = selectedValue.value;
			this.PreviousSelected = selectedValue;
		}
	}

	onItemSelectionChange(item: any) {
		const originalItem = this.originalData.find(i => i.value === item.value);
		if (originalItem) {
			originalItem.isChecked = item.isChecked;
		}
	}

	onSearchInputChange() {
		this.filterOptions();
	}

	filterOptions() {
		if (this.searchValue) {
			this.data = this.originalData.filter((item) =>
				item.title.toLowerCase().includes(this.searchValue.toLowerCase())
			);
		} else {
			this.data = this.originalData;
		}
	}
}
