import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpeechService } from '../../services/speech.service';

@Component({
	selector: 'app-add-comment',
	templateUrl: './add-comment.component.html',
	styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {
	@Input() comment: string;
	@Output() commentAdded = new EventEmitter();
	@Output() commentCancelled = new EventEmitter();
	@Output() commentsChange = new EventEmitter();
	showErrors = false;
	TITLE: string;
	REQUIRED_FIELD: string;
	PLACEHOLDER: string;
	BTN_CANCEL: string;
	BTN_COMMENT: string;

	constructor(public translate: TranslateService, private speechService: SpeechService) { }

	ngOnInit() {
		this.speechService.init();
		this.TITLE = this.translate.instant('COMMENT_FORM.TITLE');
		this.REQUIRED_FIELD = this.translate.instant('ERRORS.FIELD_REQUIRED');
		this.PLACEHOLDER = this.translate.instant('COMMENT_FORM.PLACEHOLDER');
		this.BTN_CANCEL = this.translate.instant('BTN.CANCEL');
		this.BTN_COMMENT = this.translate.instant('BTN.COMMENT');
	}

	addComment() {
		this.showErrors = true;
		if (!this.comment || this.comment === '') {
			return;
		}
		this.showErrors = false;
		this.commentAdded.emit(this.comment);
		this.comment = '';
		this.commentsChange.emit(this.comment);
	}

	cancelComment() {
		this.commentCancelled.emit();
		this.comment = '';
		this.commentsChange.emit(this.comment);
	}

	onCommentsChange() {
		this.commentsChange.emit(this.comment);
	}

	async read(data: any) {
		await this.speechService.read(data);
	}

	async stopRead() {
		await this.speechService.stopRead();
	}
}
