<div class="container-fluid">
  <div id="first-block" class="card-block dark page-container">
    <div class="card-wrap d-flex justify-content-between">
      <app-navbar (tabSelected)="onTabSelected($event)"></app-navbar>
      <div class="card-content">
        <div class="gi-row" *ngIf="selectedTab !== 'live'">
          <div class="gi-text">
            <h1 class="title fw-300 text-white custom-mb">
              {{ title }} <b>Insights</b>
            </h1>
            <div class="">
              <div *ngIf="isScreenSmall" class="datepicker-range-container">
                <app-date-picker (dateRangeSelected)="handleDateRangeSelected($event)"
                  [resetDatePicker]="resetDatePicker">
                </app-date-picker>
                <app-range-picker [ranges]="rangeData" [selectedRange]="selectedRange" [dateRange]="dateRange"
                  (rangeClick)="handleRangeClick($event)" (rangeReset)="onRangeReset()">
                </app-range-picker>
                <app-sites-insights *ngIf="selectedTab === 'overview'" [data]="siteInsightData"></app-sites-insights>
              </div>
              <div *ngIf="!isScreenSmall" class="row align-items-center">
                <div class="d-flex align-items-center gap-10x col-lg-2 col-xxl-6">
                  <app-date-picker (dateRangeSelected)="handleDateRangeSelected($event)"
                    [resetDatePicker]="resetDatePicker">
                  </app-date-picker>
                  <app-range-picker [ranges]="rangeData" [selectedRange]="selectedRange" [dateRange]="dateRange"
                    (rangeClick)="handleRangeClick($event)" (rangeReset)="onRangeReset()">
                  </app-range-picker>
                </div>
                <app-sites-insights class="col-lg-9 col-xxl-6 align-content-center pl-0"
                  *ngIf="selectedTab === 'overview'" [data]="siteInsightData"></app-sites-insights>
              </div>
            </div>
          </div>

        </div>
        <div *ngIf="selectedTab === 'overview'">
          <div *ngIf="!isScreenSmall" class="row first-widget-container" [style.minHeight]="overviewMinHeight+'vh'">
            <div class="col-lg-12 col-xxl-6  custom-px ">
              <app-top-sites></app-top-sites>
            </div>
            <div class="col-lg-12 col-xxl-6  pl-0 pr-3 right-container">
              <div class="right-inner-container">
                <app-total-alarms class="d-flex flex-column flex-grow-1"></app-total-alarms>
              </div>
              <div class="right-inner-container">
                <app-alarm-operator-ratio class="d-flex flex-column flex-grow-1"></app-alarm-operator-ratio>
              </div>
            </div>
          </div>
          <div *ngIf="isScreenSmall" id="top-sites-alarm" class="carousel slide" data-ride="carousel"
            data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="col-xxl-6 px-3 d-flex flex-column">
                  <app-top-sites class="d-flex flex-column flex-grow-1" [style.minHeight]="overviewMinHeight+'vh'"
                    id="top-sites-alarm1"></app-top-sites>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-xxl-6 px-3  d-flex flex-column" [style.minHeight]="overviewMinHeight+'vh'">
                  <app-total-alarms id="top-sites-alarm2" class="d-flex flex-column flex-grow-1"></app-total-alarms>

                  <app-alarm-operator-ratio class="d-flex flex-column flex-grow-1"></app-alarm-operator-ratio>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#top-sites-alarm" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#top-sites-alarm" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedTab === 'alarm'">
          <div *ngIf="!isScreenSmall" class="row first-widget-container" [style.minHeight]="alarmMinHeight+'vh'">
            <div class="col-xxl-6  custom-px">
              <app-false-rate></app-false-rate>
            </div>
            <div class="col-xxl-6  pl-0 pr-3">
              <app-repeated-alarms></app-repeated-alarms>
            </div>
          </div>
          <div *ngIf="isScreenSmall" id="false-rate-alarms" class="carousel slide" data-ride="carousel"
            data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="col-xxl-6 py-3 px-4 d-flex flex-column" [style.minHeight]="alarmMinHeight+'vh'">
                  <app-false-rate class="d-flex flex-column flex-grow-1" id="false-rate-alarms1"></app-false-rate>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-xxl-6 py-3 px-4 d-flex flex-column" [style.minHeight]="alarmMinHeight+'vh'">
                  <app-repeated-alarms class="d-flex flex-column flex-grow-1"
                    id="false-rate-alarms2"></app-repeated-alarms>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#false-rate-alarms" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#false-rate-alarms" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedTab === 'operator'">
          <div id="operator-activity-tab" class="carousel slide" data-ride="carousel" data-interval="5000">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="col-xxl-12  px-4 d-flex flex-column" [style.minHeight]="operatorMinHeight+'vh'">
                  <app-operator-activity class="d-flex flex-column flex-grow-1" id="operator2"></app-operator-activity>
                </div>
              </div>
              <div class="carousel-item">
                <div class="col-xxl-12  px-4 d-flex flex-column" [style.minHeight]="operatorMinHeight+'vh'">
                  <app-operator-ranking class="d-flex flex-column flex-grow-1" id="operator2"></app-operator-ranking>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#operator-activity-tab" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#operator-activity-tab" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedTab === 'detailed'" class="card-wrap d-flex justify-content-between flex-column">
          <div class="row">
            <div class="col-lg-12 d-flex flex-column custom-mb" [style.minHeight]="'2vh'">
              <app-alarm-severity class="d-flex flex-column flex-grow-1" [data]="severityData"></app-alarm-severity>
            </div>
            <div class="col-lg-12  d-flex flex-column" [style.minHeight]="timeOfTheDayMinHeight+ 'vh'">
              <app-alarm-time-of-the-day class="d-flex flex-column flex-grow-1"></app-alarm-time-of-the-day>
            </div>
          </div>
        </div>
        <div *ngIf="isScreenHeightSmall && selectedTab === 'live'" id="second-block" class="card-block dark mt-1">
          <div class="card-wrap">
            <div [style.minHeight.px]="45">
              <app-incidents-till-now [data]="incidentsData"></app-incidents-till-now>
            </div>
            <app-realtime-alarms></app-realtime-alarms>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bot-assist dark my-2">
    <app-chat-bar></app-chat-bar>
  </div>

  <div *ngIf="!isScreenHeightSmall" id="second-block" class="card-block dark mt-1">
    <div class="card-wrap">
      <div [style.minHeight.px]="75" class="custom-mb">
        <app-incidents-till-now [data]="incidentsData"></app-incidents-till-now>
      </div>
      <div class="d-flex flex-column" [style.minHeight]="realTimeMinHeight+'vh'">
        <app-realtime-alarms class="d-flex flex-column flex-grow-1"></app-realtime-alarms>
      </div>
    </div>
  </div>
</div>