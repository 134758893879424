<div class="heading" (mouseover)='read(TITLE)' (mouseout)='stopRead()'>
	{{ TITLE }}
</div>
<div class="content mt-1">
	<textarea matInput (mouseover)='read(PLACEHOLDER)' (mouseout)='stopRead()' #commentText
		required	rows="4" (change)="onCommentsChange()" [(ngModel)]="comment"
		[placeholder]="PLACEHOLDER">
	</textarea>
	<div class="error-message" (mouseover)='read(REQUIRED_FIELD)' (mouseout)='stopRead()'
		*ngIf="!commentText.checkValidity() && showErrors">
		{{ REQUIRED_FIELD }}
	</div>
</div>
<div class="d-flex justify-content-end mt-2">
	<button class="btn btn-info" (click)="cancelComment()" (mouseover)='read(BTN_CANCEL)' 
	(mouseout)='stopRead()'>
		{{ BTN_CANCEL }}
	</button>
	<button class="ml-1 btn btn-info"	(click)="addComment()" (mouseover)='read(BTN_COMMENT)' 
	(mouseout)='stopRead()'>
		{{ BTN_COMMENT }}
	</button>
</div>