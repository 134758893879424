import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Alarm } from 'src/models/alarm.model';
import { ApiResponse } from 'src/models/api-response.model';
import { Manager } from 'src/models/manager.model';
import { AlarmService } from '../services/alarm.service';
import { WorkflowService } from '../services/workflow.service';
import { Utility } from '../utils/app-utils';
import { QuestionService } from './question/question.service';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { AppConstants } from '../constants/app-constants';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { SpeechService } from '../services/speech.service';
import { Subscription } from 'rxjs';
import { ChatService } from '../services/chat.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-workflow',
	templateUrl: './workflow.component.html',
	styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {
	actionPanelOpenState: any;
	alarmPanelOpenState: any;
	closeAll: any = false;
	managers: Manager[];
	question: any;
	@Input() alarm: Alarm;
	isActionHistoryExpanded = true;
	isAlarmHistoryExpanded = true;
	utility = Utility;
	isCommentBoxShown: boolean = false;
	isEscalationConfirmationShown = false;
	isSendMailBoxShown: boolean = false;
	isChatShown: boolean = false;
	previousComments: string = '';
	workflowComplete: boolean = false;
	alarmStatus = AlarmStatus;
	EscalatedAlarm: string = '';
	calledFrom = AppConstants.WORKFLOW_TEXT;
	public title: string = '';
	public header: string = '';
	questionChangeSubscriber: Subscription;
	alarmStatusSub: Subscription;

	constructor(
		private workflowService: WorkflowService,
		private alarmService: AlarmService,
		public dialog: MatDialog,
		private questionService: QuestionService,
		private stateService: UserSessionStateService,
		private authService: AuthenticationService,
		public translate: TranslateService,
		private speechService: SpeechService,
		public chatService: ChatService,
		public _snackBar: MatSnackBar
	) { }

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.alarm.currentValue) {
			this.alarm = changes.alarm.currentValue;
			this.questionService.previousStatus = this.alarm ? this.alarm.status : '';
			this.workflowComplete = false;
			const comments = this.stateService.getComments(this.alarm.id);
			this.previousComments = comments ? comments : '';
			const wasCommentBoxOpen = this.stateService.getCommentBoxOpenState(this.alarm.id);
			this.setCommentBoxVisibility(wasCommentBoxOpen);
			const wasDirectEscalationOpen = this.stateService.getDirectEscalationOpenState(this.alarm.id);
			this.setDirectEscalationVisibility(wasDirectEscalationOpen);
			const wasSendMailBoxOpen = this.stateService.getSendMailBoxOpenState(this.alarm.id);
			this.setSendMailBoxVisibility(wasSendMailBoxOpen);
			const wasChatBoxOpen = this.stateService.getChatBoxOpenState(this.alarm.id);
			this.setChatBoxVisibility(wasChatBoxOpen);

			// Force re-rendering of question component, so that we don't see
			// same answers / comments that was filled for last alarm selection.
			this.question = null;
			this.questionService.getQuestion(this.alarm.id);
			this.getManagers();
		}
	}

	async ngOnInit() {
		/**
		 * Set constants for confirmation bar
		 */
		this.translate.get('ALARM_STATUS.ESCALATED').subscribe((res: string) => {
			this.EscalatedAlarm = res;
		});

		this.translate.get('WORKFLOW.WORKFLOW_ESCALATE_TITLE_TEXT').subscribe((res: string) => {
			this.title = res;
		});

		this.translate.get('WORKFLOW.WORKFLOW_ESCALATE_HEADER_TEXT').subscribe((res: string) => {
			this.header = res;
		});

		this.questionChangeSubscriber = this.questionService.getQuestionChangeListener().subscribe((question) => {
			if (question) {
				this.question = { ...question };
				if (this.question.type === 'list') {
					this.question.selectedId = this.question.data[0].id;
				}
			} else {
				this.workflowComplete = true;
			}
		});
	}

	ngOnDestroy() {
		this.questionChangeSubscriber = Utility.Unsubscribe(this.questionChangeSubscriber);
		this.alarmStatusSub = Utility.Unsubscribe(this.alarmStatusSub);
	}

	/**
	 * Fetches list of managers of the branch from where the alarm is activated.
	 */
	async getManagers() {
		this.managers = [];
		const response: ApiResponse = await this.workflowService.getManagers(this.alarm.branch.id);
		if (response.success === true) {
			this.managers = response.data;
			for (let i = 0; i < this.managers.length; i++) {
				if (this.managers[i].designation === 'Guard') {
					this.managers[i].designation = this.translate.instant('WORKFLOW.GUARD_ROLE');
				} else if (this.managers[i].designation === 'Branch Guard') {
					this.managers[i].designation = this.translate.instant('WORKFLOW.GUARD_ROLE');
				} else if (this.managers[i].designation === 'Manager') {
					this.managers[i].designation = this.translate.instant('WORKFLOW.MANAGER_ROLE');
				}
			}
		}
	}

	/**
	 * Call service method to change status of alarm to 'Escalated'.
	 */
	escalateAlarm() {
		const data = {
			action: this.translate.instant('WORKFLOW.ALARM_ESCALATED'),
			step: this.translate.instant('WORKFLOW.ALARM_ESCALATED'),
			timeStamp: new Date().getTime(),
			user: {
				id: this.authService.currentUserSession.activeProfile.client._id,
				firstName: this.authService.currentUserSession.firstName,
				lastName: this.authService.currentUserSession.lastName,
				userName: this.authService.currentUserSession.role.name
			}
		};
		this.workflowService.addActionHistory(data);
		this.alarmService.changeStatusAlarmNotification(this.alarmService.selectedAlarm, this.translate.instant('ALARM_STATUS.ESCALATED'));
		this.alarmService.changeAlarmHistoryStatus(this.alarmService.selectedAlarm.id, this.translate.instant('ALARM_STATUS.ESCALATED'));
		if (this.authService.hasRole(AppConstants.Admin)) {
			this.alarmService.changeAlarmStatus(this.alarmService.selectedAlarm.id, this.translate.instant('ALARM_STATUS.ESCALATED'));
		} else {
			this.alarmService.escalateAlarm(this.alarm);
		}
		this.isEscalationConfirmationShown = false;
		this.setDirectEscalationVisibility(false)
		this.setCommentBoxVisibility(false);
		this.setSendMailBoxVisibility(false);
		this.setChatBoxVisibility(false);
		this.question = null;
	}

	/**
	 * Call service method to post comment.
	 * @param comment Comment text.
	 */
	addComment(comment: string) {
		if (comment && comment !== '') {
			this.setCommentBoxVisibility(false);
			this.workflowService.addWorkflowComment(this.alarm.id, comment);
		}
	}

	onCommentCancel() {
		// const comments = this.stateService.getComments(this.alarm.id);
		// this.stateService.saveComments(this.alarm.id, comments);
		// this.previousComments = comments ? comments : '';
		this.setCommentBoxVisibility(false);
	}

	/**
	 * Saves comment state when a high priority alarm is activated.
	 * @param comment Comment text.
	 */
	onCommentsTextChange(comment: string) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.stateService.saveComments(alarmId, comment);
		this.stateService.saveCommentBoxOpenState(alarmId, this.isCommentBoxShown);
	}

	private setCommentBoxVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isCommentBoxShown = !!isVisible;
		this.stateService.saveCommentBoxOpenState(alarmId, this.isCommentBoxShown);
	}

	private setDirectEscalationVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isEscalationConfirmationShown = !!isVisible;
		this.stateService.saveDirectEscalationOpenState(alarmId, this.isEscalationConfirmationShown);
	}

	private setSendMailBoxVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isSendMailBoxShown = !!isVisible;
		this.stateService.saveSendMailBoxOpenState(alarmId, this.isSendMailBoxShown);
	}

	private setChatBoxVisibility(isVisible: boolean) {
		const alarmId = this.alarmService.selectedAlarm.id;
		this.isChatShown = !!isVisible;
		// this.stateService.saveChatBoxOpenState(alarmId, this.isChatShown);
	}

	/**
	 * Displays comment box.
	 */
	showCommentBox() {
		this.alarmService.checkSelectedAlarmAssigned();
		this.setCommentBoxVisibility(true);
		const alarmId = this.alarmService.selectedAlarm.id;
		const comments = this.stateService.getComments(alarmId);
		this.previousComments = comments ? comments : '';
		this.setDirectEscalationVisibility(false);
		this.setSendMailBoxVisibility(false);
		this.setChatBoxVisibility(false);
	}

	/**
	 * Displays escalation confirmation message.
	 */
	showEscalationConfirmation() {
		this.alarmService.checkSelectedAlarmAssigned();
		this.setDirectEscalationVisibility(true);
		this.setCommentBoxVisibility(false);
		this.setSendMailBoxVisibility(false);
		this.setChatBoxVisibility(false);
	}

	// Displays comment box.
	showSendMailBox() {
		this.alarmService.checkSelectedAlarmAssigned();
		this.setSendMailBoxVisibility(true);
		this.setCommentBoxVisibility(false);
		this.setDirectEscalationVisibility(false);
		this.setChatBoxVisibility(false);
	}

	// Displays Chat box.
	async showChatBox() {
		let res = await this.chatService.getAlarmRoom(this.alarm.id);
		if (res && res.success === true && res.data.length !== 0) {
			await this.getChatRoom(this.alarm);
		} else {
			this.alarmService.checkSelectedAlarmAssigned();
			this.setChatBoxVisibility(true);
			this.setSendMailBoxVisibility(false);
			this.setCommentBoxVisibility(false);
			this.setDirectEscalationVisibility(false);
		}
	}

	async getChatRoom(alarm: any) {
		let res = await this.chatService.getUserRooms(alarm.id);
		if (res && res.success === true && res.data.rooms.length !== 0) {
			this.alarmService.checkSelectedAlarmAssigned();
			this.setChatBoxVisibility(true);
			this.setSendMailBoxVisibility(false);
			this.setCommentBoxVisibility(false);
			this.setDirectEscalationVisibility(false);
		} else {
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const message = this.translate.instant('CHAT.NO_ACCESS') + ' : ' + alarm.shortAlarmId;
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => { });
		};
	}

	openSnackBar(message: string, action: string) {
		return this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

	toggleAudio() {
		this.speechService.allowAudio = !this.speechService.allowAudio;
	}

}
