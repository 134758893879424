import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { AppConstants } from 'src/app/constants/app-constants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InsightsService } from 'src/app/services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-alarm-operator-ratio',
  templateUrl: './alarm-operator-ratio.component.html',
  styleUrls: ['./alarm-operator-ratio.component.scss'],
})
export class AlarmOperatorRatioComponent
  implements AfterViewInit, AfterViewChecked {

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService, public authenticationService: AuthenticationService) { }

  dynamicWidth: number = 300;
  dynamicHeight: number = 30;
  dynamicIntervals: number = 30;
  alarmsPercentage: number = 0;
  operatorsPercentage: number = 0;
  totalAlarms: number = 0;
  OperatorsPerformance: number = 0;
  alarmRatio: number = 0;

  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;
  isSupervisor: boolean = false;

  async ngOnInit() {
    this.listenToDateRangeChanges();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getRatio(this.startDate, this.endDate);
    });
  }

  async getRatio(startDate?: number, endDate?: number) {
    let res = await this.insightsService.getAlarmsManagementOverview(startDate, endDate);
    if (res && res.success && res?.data && res.data?.results) {
      const results = res.data.results;
      this.totalAlarms = results.alarmsForOperators.currentPeriodAlarmsCount;
      this.alarmsPercentage = Math.round(results.alarmsForOperators.percentage);
      this.alarmRatio = results.alarmsForOperators.alarmsRelatedToOperatorsCount;

      this.isSupervisor = this.authenticationService.hasRole(AppConstants.Supervisor);
      if (this.isSupervisor) {
        this.operatorsPercentage = Math.round(results.allOperatorsActivity.percentage);
        this.OperatorsPerformance = results.allOperatorsActivity.totalAlarmsHandledByOperators;
      } else {
        this.operatorsPercentage = Math.round(results.individualOperatorActivity.percentage);
        this.OperatorsPerformance = results.individualOperatorActivity.totalAlarmsHandledByOperator;
      }
    } else {
      this.alarmsPercentage = 0;
      this.operatorsPercentage = 0;
      this.totalAlarms = 0;
      this.OperatorsPerformance = 0;
    }
  }

  ngAfterViewInit(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }
  ngAfterViewChecked(): void {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateSvgDimensions();
    this.cdr.detectChanges();
  }

  private updateSvgDimensions() {
    const parentWidth = document
      .querySelector('#operator-alarm')
      ?.getBoundingClientRect().width;

    const screenWidth = window.innerWidth;
    if (screenWidth > 3000) {
      this.dynamicWidth = parentWidth ? parentWidth - 100 : 500;
      this.dynamicHeight = 40;
      this.dynamicIntervals = 60;
    } else {
      this.dynamicWidth = parentWidth ? parentWidth - 110 : 300;
      this.dynamicHeight = 30;
      this.dynamicIntervals = 30;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }
}
