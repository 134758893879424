import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { InsightsService } from 'src/app/services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-total-alarms',
  templateUrl: './total-alarms.component.html',
  styleUrls: ['./total-alarms.component.scss'],
})
export class TotalAlarmsComponent implements AfterViewInit, AfterViewChecked {
  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }

  dynamicWidth: number = 240;
  dynamicHeight: number = 30;
  dynamicPercentage: number = 100;
  dynamicIntervals: number = 30;
  alarmInsightsData = [];
  totalAlarms: number = 0;
  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  async ngOnInit() {
    this.listenToDateRangeChanges();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getAlarmsData(this.startDate, this.endDate);
    });
  }

  async getAlarmsData(startDate?: number, endDate?: number) {
    let res = await this.insightsService.getTotalAlarms(startDate, endDate);
    if (res && res.success && res.data && res.data.results) {
      this.alarmInsightsData = this.formatAlarmData(res.data.results);
      this.totalAlarms = res.data.results.currentPeriodAlarmsCount;
      // Check if rate is null and assign 100, otherwise round the rate value
      this.dynamicPercentage = res.data.results.rate !== null ? Math.round(res.data.results.rate) : 100;
    } else {
      this.alarmInsightsData = [];
      this.totalAlarms = 0;
      this.dynamicPercentage = 0;
    }
  }

  private formatAlarmData(data: any): any[] {
    return [
      { label: 'New', key: 'new', value: data.new.toString() },
      { label: 'Active', key: 'active', value: data.active.toString() },
      { label: 'Closed', key: 'closed', value: data.closed.toString() },
    ];
  }

  ngAfterViewInit() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
  }

  private updateChartWidth() {
    const screenWidth = window.innerWidth;
    if (screenWidth > 3000) {
      this.dynamicWidth = 300;
      this.dynamicHeight = 40;
      this.dynamicIntervals = 40;
    } else {
      this.dynamicWidth = 240;
      this.dynamicHeight = 30;
      this.dynamicIntervals = 30;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }
}
