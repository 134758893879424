import { SessionConstants } from '../constants/session-constants';

export class AppSessionStorage {

	static setToken(token: string) {
		sessionStorage.setItem(SessionConstants.SESSION_TOKEN, token);
	}

	static getToken() {
		return sessionStorage.getItem(SessionConstants.SESSION_TOKEN);
	}

	static setUser(user: any) {
		sessionStorage.setItem(SessionConstants.SESSION_USER, JSON.stringify(user));
	}

	static getUser() {
		return JSON.parse(sessionStorage.getItem(SessionConstants.SESSION_USER));
	}

	static logoutUser() {
		// sessionStorage.clear();
		sessionStorage.removeItem('user');
	}
}
