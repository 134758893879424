import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { HomeComponent } from './home/home.component';
import { AnalyticalDashboardComponent } from './analytical-dashboard/analytical-dashboard.component';
import { CanActivateRouteGuard } from './can-activate-route-guard';
import { AppConstants } from './constants/app-constants';

export const appRoutes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{
		path: 'home',
		component: HomeComponent,
		children: [
			{ path: 'dashboard', component: DashboardComponent },
			{ path: 'reports', component: ReportsComponent },
			{
				path: 'analyticalDashboard', component: AnalyticalDashboardComponent,
				canActivate: [CanActivateRouteGuard], data: { role: AppConstants.Supervisor }
			}
		]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
