import L, { PointExpression, marker, icon } from 'leaflet';
import { Branch } from 'src/models/branch.model';
import { Utility } from './app-utils';
import { BranchStatus } from '../enums/branch-status.enum';
import { TranslateService } from '@ngx-translate/core';

export class MapUtils {
	static ICON_SIZE: PointExpression = [50, 50];
	static ICON_ANCHOR: PointExpression = [13, 41];
	static SHADOW_URL = 'assets/marker-shadow.png';
	static IMAGE_LINK_OK = 'link_OK.png';
	static IMAGE_LINK_DOWN = 'link_down.png';
	static IMAGE_UNDER_MAINTENANCE = 'under_maintenance.gif';
	static IMAGE_ALARM_TRIGGERED = 'alarm _triggered.png';

	/**
	 * Returns relative URL of marker stored in assets folder
	 *
	 * @param iconName Name of the icon.
	 */
	static getIconUrl(iconName: string) {
		return 'assets/' + iconName;
	}

	/**
	 * Creates and returns marker object by using the branch details and binds a tooltip to it.
	 *
	 * @param branch Branch object.
	 * @param translateService Translation service object.
	 */
	static createMarker(branch: Branch, translateService: TranslateService) {
		var popup = `<div class="marker-popup">
		<div class="item-row">
		<p class="key">
		${translateService.instant('MAP.NAME')}
		</p>
		<p class="value">
		${branch.branchName}
		</p>
		</div>
		<div>
		<p class="key">
		${translateService.instant('MAP.ADDRESS')}
		</p>
		<p class="value">
		${branch.address}
		</p>
		</div>
		<div class="item-row">
		<p class="key">
		${translateService.instant('MAP.CONTACT_PERSON')}
		</p>
		<p class="value">
		${Utility.getPath(branch, 'managers.0.name')}
		</p>
		</div>
		<div class="item-row">
		<p class="key">
		${translateService.instant('MAP.TELEPHONE_NUMBER')}
		</p>
		<p class="value">
		${Utility.getPath(branch, 'managers.0.contact')}
		</p>
		</div>
		<div class="item-row">
		<p class="key">
		${translateService.instant('MAP.LONGITUDE')}
		</p>
		<p class="value">
		${branch.longitude}
		</p>
		</div>
		<div class="item-row">
		<p class="key">
		${translateService.instant('MAP.LATITIUDE')}
		</p>
		<p class="value" mr-2>
		${branch.latitude}
		</p>
		</div>
		<div class="d-flex align-items-center item-row">
		<div class="key mr-2">
		${translateService.instant('MAP.STATUS')}</div>
		<div class="value">
		${branch.status}</div>
		</div></div>`

		let _marker = marker(
			[branch.latitude, branch.longitude],
			{
				icon: icon({
					iconSize: MapUtils.ICON_SIZE,
					iconAnchor: MapUtils.ICON_ANCHOR,
					iconUrl: branch.icon,
					shadowUrl: MapUtils.SHADOW_URL
				})

			}).bindPopup(
				popup, { autoPan: true, closeButton: false, offset: L.point({ x: 0, y: 0 }) }
			);

		_marker.on('mouseclick', function (e) {
			_marker.openPopup();
		})

		// _marker.on('mouseout', function (e) {
		// 	_marker.closePopup();
		// })
		return _marker
	}

}
