<div class="alerts-module">
	<div class="alerts-list-module">
		<div class="alerts-header">
			<i class="fa fa-bell-o" aria-hidden="true"></i>
			<span class="alerts-title">{{ 'ALERTS.ALARMS' | translate }}</span>
			<!-- <i [class]="alarmService.allowAudio ? 'fa fa-volume-up icon-style' : 'fa fa-volume-off icon-style red'"
				aria-hidden="true" (click)="toggleAudio()">
			</i> -->
		</div>

		<div class="alerts-sorting">
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.status)">
				<span class="sorting-title">{{'ALERTS.STATUS' | translate}}</span>
				<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
			</div>
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.type)">
				<span class="sorting-title"> {{'ALERTS.TYPE' | translate}} </span>
				<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
			</div>
			<!-- <div class="sorting-type" (click)="sortAlarms(alarmSortFields.location)">
				<span class="sorting-title"> {{'ALERTS.LOCATION' | translate}} </span>
						<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
			</div>
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.time)">
				<span class="sorting-title">{{'ALERTS.DATE_TIME' | translate}}</span>
						<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
			</div> -->
			<div class="sorting-type" (click)="sortAlarms(alarmSortFields.severity)">
				<span class="sorting-title">{{'ALERTS.SEVERITY' | translate}}</span>
				<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
			</div>

			<div class="alarms-search-bar">
				<input matInput type="text" placeholder="Search" class="alarms-search" [(ngModel)]="searchText">
				<i class="fa fa-search search-icon" aria-hidden="true"></i>
			</div>
		</div>

		<mat-tab-group (selectedTabChange)="onTabChange($event)">
			<mat-tab label="In Progress">
				<mat-list class="alerts-list" *ngIf="alarms">
					<ng-container
						*ngFor="let alarm of alarms| alarmSearchFilter:searchText:authService.getUserTimezone():toggleOnAlarmListChange; trackBy: trackByAlarmId;">
						<mat-list-item class="alerts-list-items" [class.newAlarm]="newAlarm"
							[class.selected]="alarm.id === selectedAlarmId"
							[class.repeatedAlarm]="alarm.repetition > 1" (click)="selectAlarm(alarm)">
							<div matListAvatar class="alerts-type">
								<img [src]="alarm.icon" class="alerts-type-logo" [matTooltip]='alarm.type' alt="sds">
								<span class="alerts-type-text">{{getTextToShowBelowIcon(alarm.type)}}</span>
							</div>
							<div class="alerts-details">
								<span class="branch-name" [matTooltip]='alarm.branch.name'>{{alarm.branch.name}}</span>
								<span class="alerts-time">{{getDate(alarm.activationTime)}}</span>
							</div>
							<div class="alerts-condition">
								<span class="alerts-severity">{{alarm.severity}}</span>
								<span class="alerts-status" [class]="getColorClass(alarm.percent)">{{ alarm.percent }}%</span>
							</div>
							<div class="ric-status">
								<i [ngClass]="getIconDetails(alarm).iconClass" [class]="getIconDetails(alarm).colorClass"
									aria-hidden="true"></i>
							</div>
						</mat-list-item>
					</ng-container>
				</mat-list>
			</mat-tab>
			<mat-tab label="All">
				<mat-list class="alerts-list" *ngIf="alarms">
					<ng-container
						*ngFor="let alarm of alarms| alarmSearchFilter:searchText:authService.getUserTimezone():toggleOnAlarmListChange; trackBy: trackByAlarmId;">
						<mat-list-item class="alerts-list-items" [class.newAlarm]="newAlarm"
							[class.selected]="alarm.id === selectedAlarmId"
							[class.repeatedAlarm]="alarm.repetition > 1" (click)="selectAlarm(alarm)">
							<div matListAvatar class="alerts-type">
								<img [src]="alarm.icon" class="alerts-type-logo" [matTooltip]='alarm.type' alt="sds">
								<span class="alerts-type-text">{{getTextToShowBelowIcon(alarm.type)}}</span>
							</div>
							<div class="alerts-details">
								<span class="branch-name" [matTooltip]='alarm.branch.name'>{{alarm.branch.name}}</span>
								<span class="alerts-time">{{getDate(alarm.activationTime)}}</span>
							</div>
							<div class="alerts-condition">
								<span class="alerts-severity">{{alarm.severity}}</span>
								<span class="alerts-status" [class]="getColorClass(alarm.percent)">{{ alarm.percent }}%</span>
							</div>
							<div class="ric-status">
								<i [ngClass]="getIconDetails(alarm).iconClass" [class]="getIconDetails(alarm).colorClass"
									aria-hidden="true"></i>
							</div>
						</mat-list-item>
					</ng-container>
				</mat-list>
			</mat-tab>
		</mat-tab-group>
	</div>

	<!-- <div class="alarms-counts">
		<div class="title"><span>{{'ALERTS.TOTAL_UNATTENDED_ALARMS' | translate}}</span></div>
		<div class="count"><span>{{unAttentedAlarmCount}}</span></div>
	</div>
	<div class="alarms-counts">
		<span class="title">{{'ALERTS.ESCALATED_ALARMS' | translate}}</span>
		<span class="count">{{escalatedAlarmCount}}</span>
	</div> -->

</div>