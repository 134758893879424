<div class="asset-list">
	<p class="assets-header">
		{{"ASSETDOCS.HEADER_TEXT" | translate}}
	</p>

	<ul>
		<li *ngIf="!assetsDocs || assetsDocs.length === 0">
			<p class="no-assets-text">{{"ASSETDOCS.NO_ASSETS" | translate}}</p>
		</li>
		<li *ngFor="let asset of assetsDocs" class="asset-li" (click)="showAsset(asset)">
			<div [class.selected]="asset.id === selectedAssetDocId">
				<a>
					<i class="fa fa-file-text file-icon" aria-hidden="true"></i>
					<p class="asset-name">{{ asset.name }}</p>
					<p>
						<span class="asset-time">
							{{"ASSETDOCS.LASTMODIFIED" | translate}} :
							{{getFormatedDate(asset?.lastModifiedDate, appConstants.SHORT_DATE_FORMAT)}}
						</span>
					</p>
					<hr class="hr">
				</a>
			</div>
		</li>
	</ul>
</div>