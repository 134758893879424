<div class="chart-block chartone-block radius-15 box-border d-flex flex-column pb-0 flex-grow-1">
  <div class="block-head d-flex align-items-center">
    <h2 class="title text-white">Repeated Alarm Types</h2>
    <app-popover
      [title]="'Repeated alarms'"
      [content]="'Top 5 alarm types based on number of alarms raised'">
    </app-popover>
  </div>
  <div class="chart-wrap d-flex justify-content-center align-items-center" *ngIf="treemapData?.children?.length > 0">
    <div class="chart">
      <app-treemap
        [data]="treemapData"
        [width]="dynamicWidth"
        [height]="dynamicHeight"
        [colors]="['#08306b', '#08519c', '#2171b5', '#4292c6', '#6baed6']">
      </app-treemap>
    </div>
  </div>
  <div *ngIf="treemapData?.children?.length === 0" class="h-100 d-flex justify-content-center align-items-center flex-grow-1"  >
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>
