<ul class="card-nav d-flex flex-column">
  <li [class.active]="selectedTab === 'overview'" (click)="selectTab('overview')">
    <a>
      <img class="cursor-pointer navbar-icon" src="assets/images/overview.svg" />
    </a>
  </li>
  <li [class.active]="selectedTab === 'alarm'" (click)="selectTab('alarm')">
    <a>
      <i class="fa fa-bell-o cursor-pointer" aria-hidden="true"></i>
    </a>
  </li>
  <li [class.active]="selectedTab === 'operator'" (click)="selectTab('operator')">
    <a><img class="cursor-pointer navbar-icon" src="assets/images/operator-insights .svg" /></a>
  </li>
  <li [class.active]="selectedTab === 'detailed'" (click)="selectTab('detailed')">
    <a><img class="cursor-pointer navbar-icon" src="assets/images/streamline.svg" /></a>
  </li>
  <li *ngIf="isScreenHeightSmall" [class.active]="selectedTab === 'live'" (click)="selectTab('live')">
    <a><img class="cursor-pointer navbar-icon" src="assets/images/liveTab.svg" /></a>
  </li>
</ul>