<div class="chart-block chartthree-block radius-15 box-border d-flex flex-column flex-grow-1">
  <div class="block-head d-flex align-items-center">
    <h2 class="title text-white">Alarm Management Overview</h2>
    <app-popover [title]="'Alarm to operator ratio'"
      [content]="'Shows the percentage of alarms requiring manual intervention out of the total alarms and the operator’s impact on handling those alarms.'"></app-popover>
  </div>
  <!-- <div *ngIf="alarmsPercentage > 0 && operatorsPercentage > 0 "> -->
  <div>
    <div id="operator-alarm"
      class="chart-wrap d-flex justify-content-between align-items-center alarm-operator-ratio-alarm-container">
      <div class="chart">
        <app-alarm-operator-progress [percentage]="alarmsPercentage" [width]="dynamicWidth" [height]="dynamicHeight"
          [intervals]="dynamicIntervals" [chartId]="'alarm-operator-ratio-alarm'"
          [colors]="['#f67630', '#f26427', '#ef5920']">
        </app-alarm-operator-progress>
      </div>
      <div class="chart-value d-flex border-0">
        <div class="item-value align-items-center d-flex">
          <div class="d-flex flex-column">
            <span>Manual Alarms</span>
            <p class="paragraph-text">{{ alarmRatio }} / {{ totalAlarms }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="chart-wrap d-flex justify-content-between align-items-center">
      <div class="chart">
        <app-alarm-operator-progress [percentage]="operatorsPercentage" [width]="dynamicWidth" [height]="dynamicHeight"
          [intervals]="dynamicIntervals" [chartId]="'alarm-operator-ratio-operator'"
          [colors]="['#32cc87', '#35a6e5', '#1b7aaf']"></app-alarm-operator-progress>
      </div>
      <div class="chart-value d-flex border-0">
        <div class="item-value align-items-center d-flex">
          <div class="d-flex flex-column">
            <span>{{ isSupervisor ? 'Ownership Taken' : 'Your Impact' }}</span>
            <p class="paragraph-text">{{ OperatorsPerformance }} / {{ alarmRatio }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="alarmsPercentage === 0 && operatorsPercentage === 0"
    class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div> -->
</div>