export class AppConstants {
  public static SESSION = "session";
  public static SESSION_TOKEN = "authToken";
  public static LANGUAGE = "language";
  public static ROLE = "role";
  public static SUCCESS = "success";
  public static DATE_TIME_FORMAT = "DD MMM YYYY | hh:mm:ss a";
  public static CHAT_DATE_TIME_FORMAT = "dd MMM YY - hh:mm a";
  public static DATE_FORMAT = "DD/MM/YYYY";
  public static SHORT_DATE_FORMAT = "DD/MM/YY";
  public static TIME_FORMAT = "hh: mm: ss a";
  public static APP_CONFIG_INIT_TIMER = 1000;
  public static MINUTE_TO_MILLISECONDS = 60000;
  public static PDF = "pdf";
  public static CSV = "csv";
  public static Admin = "admin";
  public static Guard = "guard";
  public static Supervisor = "supervisor";
  public static Operator = "operator";
  public static USER_DATE_FORMAT = "E, MMM d,";
  public static USER_TIME_FORMAT = "hh:mm a";
  public static LOADMASK_SRC = "../../../../assets/loading.gif";
  public static PDF_TEXT = "pdf";
  public static JSON_TEXT = "json";
  public static CSV_TEXT = "csv";
  public static ACTION_ASSIGN = "assign";
  public static WORKFLOW_TEXT = "workflow";
  public static OVERLOADED_TEXT = "overloaded";
  public static ACTION_UN_ASSIGN = "unassign";
  public static ACTION_ESCALATE = "escalate";
  public static SUPERVISOR_DETAILS = "supervisor_details";
  public static REGION = "region";
  public static SITES = "sites";
  public static OPERATORS = "operators";
  public static ACTIVE_OPERATORS = "activeOperators";
  public static ONLINE_OPERATORS = "onlineOperators";
  public static TYPE = "type";
  public static STATUS = "status";
  public static TIME_RANGE = "timeRange";
  public static SITE_PRIORITY = "sitePriority";
  public static ALARMS_SEVERITY = "alarmsSeverity";
  public static SHORT_ALARM_ID = "shortAlarmID";
  public static ALARM_INFO = "alarmInfo";
  public static CONTENT_TYPE_KEY = "Content-Type";
  public static CONTENT_TYPE_VALUE = "application/json";
  public static NOT_AT_DESK_TEXT = "not At Desk";
  public static NORMAL = "Normal";
  public static WUHD = "WUHD";
  public static HALF_WUHD = "HALF-WUHD";
  public static WUXGA = "WUXGA"
  public static WFHD = "WFHD";
  public static HALF_WFHD = "HALf-WFHD";
  public static FULL_HD = "FULL_HD";
  public static TABLE_CONTAINER_ID = "table-container";
  public static OPERATOR_TABLE_CONTAINER = "operator-table-container";
  public static SITES_TABLE_CONTAINER = "sites-table-container";
  public static MAP_LOADING_CONFIG_TIMER = 100;
  public static PAGINATION_OPTION = [25, 50, 75];
  public static DEFAULT_PAGE_SIZE = 50;
  public static PAGE_DIFF_CONSTANT = 1;
  public static PAGE_START_CONSTANT = 1;
  public static IPAD_PRO = 'iPadPro';
}
