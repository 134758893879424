import { Component, OnInit } from '@angular/core';
import { AnalyticalDashboardService } from '../../services/analytical-dashboard.service';
import { Utility } from '../../utils/app-utils';

@Component({
	selector: 'app-site-stats-summary',
	templateUrl: './site-stats-summary.component.html',
	styleUrls: ['./site-stats-summary.component.scss']
})
export class SiteStatsSummaryComponent implements OnInit {
	statistics = [];
	selectedTile = null;
	branchChangeSubscriber: any;
	constructor(private dashboardService: AnalyticalDashboardService) { }

	async ngOnInit() {
		this.branchChangeSubscriber = this.dashboardService.getBranchNotification().subscribe((isChange) => {
			if (isChange) {
				const branchStats = this.dashboardService.getBranchStatuses();
				this.statistics = Utility.formatIntoRows(branchStats);
			}
		});
		this.dashboardService.getUpdateNotification().subscribe(() => {
			this.selectedTile = null;
		});
		this.dashboardService.getAllClearNotification().subscribe(() => {
			this.selectedTile = null;
		});
	}

	ngOnDestroy() {
		this.branchChangeSubscriber = Utility.Unsubscribe(this.branchChangeSubscriber);
	}

	/**
	 * select or unselect a tile and filter table related to that
	 */
	setSelectedTile(branchStatuses) {
		this.dashboardService.SelectedStatusesForTile = [];
		if (this.selectedTile !== null && this.selectedTile !== branchStatuses) {
			this.dashboardService.SelectedStatusesForTile.push(branchStatuses);
			this.filterOptionsParams(this.selectedTile);
		} else if (this.selectedTile === branchStatuses) {
			this.filterOptionsParams(branchStatuses);
		} else {
			this.dashboardService.SelectedStatusesForTile.push(branchStatuses);
		}
		this.selectedTile = this.selectedTile === branchStatuses ? null : branchStatuses;
		this.dashboardService.filterSitesList(this.dashboardService.branchParams);
	}

	filterOptionsParams(filterBy: string) {
		this.dashboardService.branchParams.branchStatuses = (this.dashboardService.branchParams.branchStatuses || []).filter(alarmStatus => alarmStatus !== filterBy);
		if (!this.dashboardService.branchParams.branchStatuses.length) {
			delete this.dashboardService.branchParams['branchStatuses'];
		}
	}

	/**
	 * check tile selected or not
	 */
	isNotActive(item) {
		return !(this.selectedTile === item);
	}
}
