import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

	title: string;
	message: string;
	confirmText: string;
	cancelText: string;
	typeOfDialog = {
		yesNoDialog: 1,
		okayDialog: 2
	};
	selectedTypeofDialog;

	/**
	 *
	 * @param dialogRef is Reference of dialog box
	 * @param data is Used to set values of parameters
	 */
	constructor(
		public dialogRef: MatDialogRef<ConfirmDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		this.title = data.title;
		this.message = data.message;
		this.confirmText = data.confirmText;
		this.cancelText = data.cancelText;
		this.selectedTypeofDialog = data.typeOfDialog;
	}

	ngOnInit() { }

	onConfirm(): void {
		// Close the dialog, return true
		this.dialogRef.close(true);
	}

	onDismiss(): void {
		// Close the dialog, return false
		this.dialogRef.close(false);
	}
}
/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {

	constructor(
		public title: string,
		public message: string,
		public confirmText: string,
		public cancelText: string,
		public typeOfDialog: number
	) {
	}
}
