export const formatValue = (value: number) => {
    const valueAbs = Math.abs(value);
    return valueAbs < 10 ? '0' + valueAbs : valueAbs
}

export const trimTexts = (text: any, maxLength: number = 13): string => {
    // Check if text is undefined or null
    if (text == null) {
        return '';
    }
    text = text.toString();
    if (maxLength) {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '..';
        }
    }

    return text;
}