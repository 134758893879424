<div class="expansion-box">
	<div class="alarm-details">
		<app-alarm-details [alarm]="alarm"></app-alarm-details>
	</div>

	<p class="history-header">{{ "ACTION_HISTORY.ACTION_HISTORY" | translate }}</p>

	<div class="table-container">
		<table mat-table [dataSource]="actionsDataSource" *ngIf="actions && actions.length > 0; else noActions"
			aria-describedby="action-history">

			<ng-container matColumnDef="timeStamp">
				<th scope="col" mat-header-cell *matHeaderCellDef>
					{{ "ACTION_HISTORY.DATE_TIME" | translate }}
				</th>
				<td mat-cell *matCellDef="let element" class="time-stamp-data">{{ getDate(element.timeStamp) }} </td>
			</ng-container>

			<ng-container matColumnDef="message">
				<th scope="col" mat-header-cell *matHeaderCellDef> {{ "ACTION_HISTORY.ACTION" | translate }}</th>
				<td mat-cell *matCellDef="let element" class="action-data" matTooltip="{{element.action}}">
					{{element.action}}
					<i class="fa fa-picture-o photo-icon" aria-hidden="true" *ngIf="element.type ==='photo'"
						(click)="displayMedia(element)"></i>
					<i class="fa fa-play-circle-o video-icon" aria-hidden="true" *ngIf="element.type ==='video'"
						(click)="displayMedia(element)"></i>
					<audio *ngIf="element.type ==='audio'" alt="audio" controls controlsList="nodownload noplaybackrate">
						<source [src]="element.url" type="audio/mp3" />
					</audio>
				</td>
			</ng-container>

			<ng-container matColumnDef="name">
				<th scope="col" mat-header-cell *matHeaderCellDef> {{ "ACTION_HISTORY.NAME" | translate }} </th>
				<td mat-cell *matCellDef="let element" class="name-data"
					matTooltip="{{element.user.firstName + ' ' + element.user.lastName}}">
					{{element.user.firstName + ' ' + element.user.lastName}} </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
		<ng-template #noActions>
			<div class="box-content-no-data">
				{{'ALARM_HISTORY.NO_DATA' | translate}}
			</div>
		</ng-template>
	</div>
</div>