import { Injectable } from '@angular/core';
import { ApiResponse } from "src/models/api-response.model";
import { ApiConstants } from "../constants/api-constants";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  public dateRangeSource = new BehaviorSubject<{ startDate: number | undefined, endDate: number | undefined }>({ startDate: undefined, endDate: undefined });
  currentDateRange = this.dateRangeSource.asObservable();

  constructor(private http: HttpClient) { }

  updateDateRange(startDate: number | undefined, endDate: number | undefined) {
    this.dateRangeSource.next({ startDate, endDate });
  }

  private createParams(startDate?: number, endDate?: number): HttpParams {
    let params = new HttpParams();
    if (startDate && endDate) {
      params = params.set('__startDate', startDate.toString());
      params = params.set('__endDate', endDate.toString());
    }
    return params;
  }

  async getTopSitesRaisingAlarms(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_TOP_SITES, { params }));
    return response;
  }

  async getTotalAlarms(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_TOTAL_ALARMS, { params }));
    return response;
  }

  async getSitesInsights() {
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SITES_INSIGHTS));
    return response;
  }

  async getOperatorsActionRanking(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATORS_ACTIONS_RANKING, { params }));
    return response;
  }

  async getAlarm_operator_ratio(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARM_OPERATOE_RATIO, { params }));
    return response;
  }

  async getLiveAlarms() {
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_LIVE_ALARMS));
    return response;
  }

  async getLiveIncidents(slotMinutes: number) {
    let params = new HttpParams().set('__slotMinutes', slotMinutes.toString());
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_LIVE_INCIDENTS, { params }));
    return response;
  }

  async getOperatorActivity(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_OPERATOR_ACTIVITY, { params }));
    return response;
  }

  async getRepeatedAlarmTypes(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_REPEATED_ALARM_TYPES, { params }));
    return response;
  }

  async getFalseAlarmsRate(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_FALSE_ALARMS_RATE, { params }));
    return response;
  }

  async getAlarmsBySeverity(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_BY_SEVERITY, { params }));
    return response;
  }

  async getAlarmsByTimeOfTheDay(startDate?: number, endDate?: number, status?: string, type?: string, severity?: string) {
    let params = this.createParams(startDate, endDate);

    if (status) {
      params = params.set('status[]', status);
    }

    if (type) {
      params = params.set('type[]', type);
    }

    if (severity) {
      params = params.set('severity[]', severity);
    }

    const response = await lastValueFrom(
      this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_BY_TIME_OF_THE_DAY, { params })
    );
    return response;
  }

  async getAlarmsManagementOverview(startDate?: number, endDate?: number) {
    const params = this.createParams(startDate, endDate);
    const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_MANAGEMENT_OVERVIEW, { params }));
    return response;
  }

}


