<div class="chart">
  <div class="chart-table" [style.min-height.px]="dynamicMinHeight">
    <table class="sticky-header-table" [style.min-height.px]="dynamicMinHeight">
      <thead>
        <tr>
          <th *ngFor="let column of data?.columns || []">
            {{ column.label || '' }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data?.values">
          <td *ngFor="let column of data?.columns || []">
            {{ row[column.key] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>