<div class="thermal-feed">
	<div class="player-view" *ngIf="isImg">
		<div class="video-window-header">
			<div class="video-control-bar">
				<img class="ctrl-icon" alt="full_scrn_image" (click)="showFullScreenImage()"
					[src]="utility.getImagePath('full_scrn_video.png')" />
			</div>
		</div>
		<div>
			<img class="thermal" [src]="scShootURL" alt="scShot">
		</div>
	</div>
	<div *ngIf="!isImg">
		{{"THERMAL_FEED.NO_IMAGE" | translate}}
	</div>
</div>

<div class="thermal-feed">
	<div class="player-view" *ngIf="isVid">
		<div class="video-window-header">
			<div class="video-control-bar">
				<img class="ctrl-icon" alt="full_scrn_video" (click)="showFullScreenVideo()"
					[src]="utility.getImagePath('full_scrn_video.png')" />
			</div>
		</div>
		<div>
			<video class="thermal" [src]="videoURL" alt="video" autoplay loop controls>
				<track label="English" kind="captions" srclang="en" default>
				<track label="Arabic" kind="captions" srclang="ar">
				Your browser does not support HTML5 video.
			</video>
		</div>
	</div>

	<div *ngIf="!isVid">
		<span>{{"THERMAL_FEED.NO_VIDEO" | translate}}</span>
		<i class="fa fa-refresh ctrl-icon" aria-hidden="true" (click)="updateAlarm()"></i>
	</div>
</div>

<div *ngIf="fullScreenVideo" class="full-screen-player" [ngStyle]="fullScreenVideoStyle">
	<video class="full-screen-player" [ngStyle]="fullScreenVideoStyle" style="object-fit:fill" [src]="videoURL"
		alt="video" autoplay loop>
		<track label="English" kind="captions" srclang="en" default>
		<track label="Arabic" kind="captions" srclang="ar">
	</video>
	<nb-icon nbPrefix icon="close-outline" pack="eva" class="close-th" (click)="closeFullScreenVideo()">
	</nb-icon>
</div>

<div *ngIf="fullScreenImage" class="full-screen-player" [ngStyle]="fullScreenVideoStyle">
	<img class="full-screen-player" [ngStyle]="fullScreenVideoStyle" [src]="scShootURL" alt="scShot">

	<nb-icon nbPrefix icon="close-outline" pack="eva" class="close-th" (click)="closeFullScreenImage()">
	</nb-icon>
</div>