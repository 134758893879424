<mat-toolbar class='app-header'>
    <div class='app-logo col-4'>
        <img [src]="utility.getImagePath('RamsysSentrl.png')" alt="app-logo" class="app-logo-img"
            (click)="goToDashBoard()" draggable="false">
    </div>

    <div class='client-logo col-4'>
        <img class="client-logo-img" alt="client-logo" [src]="logoUrl" draggable="false">
    </div>

    <div class='user-info col-4'>
        <div *ngIf="trialPeriodRemaining && trialPeriodRemaining.days < 30 " class='trial-period'>
            <span>
                <span class="evaluation-text">{{ "HEADER.EVALUATION_LICENSE_TEXT" | translate }}</span> {{
                "HEADER.TRIAL_DAYS_REMAINING_TEXT" | translate }} {{trialPeriodRemaining.days}}
            </span>
        </div>

        <div class='data-time'>
            <span class="current-date">{{ currentDate }}</span>,
            <span class="current-time">{{ currentTime }}</span>
        </div>

        <div (clickOutside)="isShowingUserDropdown=false" appClickOutside>
            <div class="user-details">
                <img [src]="userImage" alt="profile" class="avatar" draggable="false">
                <div class="user-profile">
                    <span class="user-name" matTooltip="{{session?.firstName + ' ' + session?.lastName}}">
                        {{session?.firstName}} {{session?.lastName}}
                    </span>
                    <span class="user-role">{{session?.role?.name}}</span>
                </div>
                <i class="fa fa-angle-down" aria-hidden="true" (click)="showUserDropDown()"></i>
            </div>

            <div class="user-dropdown row" *ngIf="isShowingUserDropdown">
                <ul>
                    <li class="component-section">
                        <div class="hoverable-action select-drop-down-div" (click)="goToDashBoard()">
                            {{ "HEADER.DASHBOARD" | translate }}</div>
                        <div *ngIf="isOperator || isAdmin" class="hoverable-action li-values select-drop-down-div"
                            (click)="goToReportsPage()">{{ "HEADER.HISTORY" | translate }}</div>
                        <div *ngIf="isSupervisor"
                            class="hoverable-action li-values li-values-last  select-drop-down-div"
                            (click)="goTOAnalyticalDashboard()">{{ "HEADER.ANALYTICAL_DASHBOARD" | translate }}</div>
                        <!-- <div class="hoverable-action select-drop-down-div" *ngIf="!arabic" (click)="switchToArabic()">Arabic</div> -->
                        <!-- <div class="hoverable-action select-drop-down-div" *ngIf="arabic" (click)="switchToEnglish()">English</div> -->
                    </li>
                    <li class="hoverable-action logout-action" (click)="logout()">
                        {{ "HEADER.SIGN_OUT" | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</mat-toolbar>