<div class="asset-docs-container">
	<mat-sidenav-container>
		<!--navbar main container -->
		<mat-sidenav-content class="navbar-main">
			<button mat-button (click)="sidenav.toggle()" class="navbar-toggle">
				<i class="fa fa-bars fa-lg" aria-hidden="true"></i>
			</button>
			<!-- if Asset -->
			<div *ngIf="url">
				<!-- if PDF is to be shown -->
				<div *ngIf="isPdf">
					<pdf-viewer class="sites-pdf-container" [src]="url" (error)="onAssetError()" [show-all]="true"
						[render-text]="false" [original-size]="true" [zoom]="zoom" cursor="HAND/H">
					</pdf-viewer>
					<button ion-button class="zoom-btn zoom-in-btn" (click)="pdfZoomIn()" title="Zoom in">
						+
					</button>
					<button ion-button class="zoom-btn zoom-out-btn" (click)="pdfZoomOut()" title="Zoom out">
						-
					</button>
				</div>
				<!-- if IMG is to be shown -->
				<div *ngIf="!isPdf">
					<div>
						<img class="show-image" alt="show-image" id="show-image" [src]="url" (error)="onAssetError()">

						<button ion-button class="zoom-btn zoom-in-btn" (click)="imgZoomIn()" title="Zoom in">
							+
						</button>
						<button ion-button class="zoom-btn zoom-out-btn" (click)="imgZoomOut()" title="Zoom out">
							-
						</button>
					</div>
				</div>
			</div>
			<div *ngIf="!url">
				<p class="error">{{ "ERRORS.PDF_LOADING_ERROR" | translate }}</p>
			</div>
		</mat-sidenav-content>

		<!-- navbar side container -->
		<mat-sidenav #sidenav opened="true" [mode]="over" position="end" class="navbar-side">
			<div class="asset-list">
				<h1>
					<i (click)="sidenav.toggle()" class="fa fa-arrow-right fa-md navbar-toggle-icon nav-close-icon"
						aria-hidden="true">
					</i>
					<span class="title">
						{{"ASSETDOCS.HEADER_TEXT" | translate}}
					</span>
				</h1>
				<ul>
					<li *ngFor="let asset of assetsDocsData" (click)="onAssetSelect(asset)">
						<div class="asset-li" [class.selected]="asset.id === selectedAssetId">
							<a>
								<i class="fa fa-file-text file-icon" aria-hidden="true"></i>
								<p class="asset-name">{{ asset.name }}</p>
								<p>
									<span>{{"ASSETDOCS.LASTMODIFIED" | translate}}</span>
									{{getFormatedDate(asset?.lastModifiedDate,appConstants.SHORT_DATE_FORMAT)}}
								</p>
							</a>
						</div>
					</li>
				</ul>
			</div>

		</mat-sidenav>
	</mat-sidenav-container>
</div>