import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { AppSessionStorage } from '../utils/app-session-storage-utils';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { AppConstants } from '../constants/app-constants';

@Component({
  selector: 'app-chat-users',
  templateUrl: './chat-users.component.html',
  styleUrls: ['./chat-users.component.scss']
})

export class ChatUsersComponent implements OnInit {
  @Input() alarm: any;
  @Output() ChatBoxCancelled = new EventEmitter();

  relatedUsers: Array<any> = [];
  otherUsers: Array<any> = [];
  currentUser: any;
  currentUser_rolePosition: number;
  userMap = new Map();

  allUsersArray: Array<any> = [];
  displayedColumns: string[] = ['name', 'select', 'notify'];
  roomId: string;

  originalUsersState: any[] = [];

  constructor(public chatService: ChatService, private stateService: UserSessionStateService,
    public alarmService: AlarmService, private cdRef: ChangeDetectorRef) { }

  async ngOnInit() {
    this.getAlarmChat(this.alarm);
  }

  ngOnChanges(changes: SimpleChanges) {
    let firstChange = changes.alarm.firstChange;
    if (!firstChange) {
      this.getAlarmChat(this.alarm);
    }
  }

  async getRelatedUsers(alarm: any) {
    this.relatedUsers = await this.alarmService.getAlarmRelatedUsers(alarm.id);
    this.relatedUsers.forEach((user: any) => {
      this.userMap.set(user._id, { role: user.role[0], name: `${user.firstName} ${user.lastName}` });
    });
    this.currentUser = AppSessionStorage.getUser();
    let chatUsers = this.relatedUsers.filter((user: any) => user._id !== this.currentUser.userId);
    let otherUsers = chatUsers.map((user: any) => ({
      name: `${user.firstName} ${user.lastName}`,
      role: user.role[0],
      id: user._id,
      rolePosition: user.rolePosition
    }));
    this.allUsersArray = otherUsers.map(user => ({ ...user, selected: true, notify: false }));
    // Sort users by rolePosition and then by name alphabetically in a case-insensitive manner
    this.allUsersArray.sort((a, b) => {
      // First, compare by rolePosition
      if (a.rolePosition < b.rolePosition) return -1;
      if (a.rolePosition > b.rolePosition) return 1;
      // If rolePosition is the same, compare names alphabetically, case-insensitive
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    this.cdRef.detectChanges();

    // get currentUser rolePosition
    const currentUserInSystem = this.relatedUsers.find(user => user._id === this.currentUser.userId);
    this.currentUser_rolePosition = currentUserInSystem.rolePosition;
  }

  anyUserSelected(): boolean {
    return this.allUsersArray.some(user => user.selected);
  }

  onSelectChange(user: any) {
    if (!user.selected) {
      user.notify = false;
    }
  }

  async getAlarmChat(alarm: any) {
    if (!alarm.id || !alarm.type) {
      alarm.id = alarm.alarmId;
      alarm.type = alarm.alarmType;
    };
    await this.getRelatedUsers(alarm);
    let res = await this.chatService.getUserRooms(alarm.id);
    if (res && res.success === true && res.data.rooms.length !== 0) {
      const room = res.data.rooms[0];
      this.roomId = room._id;
      this.markUsersAsSelectedOrNotified(room);
    } else {
      this.reset();
      this.roomId = null;
    };

    // Update the original users states
    this.originalUsersState = this.allUsersArray.map(user => ({
      ...user,
      originalSelected: user.selected,
      originalNotify: user.notify
    }));

    const currentAlarm = {
      alarm: alarm,
      enableReply: true
    };
    this.chatService.setCurrentAlarm(currentAlarm);
  }

  markUsersAsSelectedOrNotified(room: any) {
    const { members, notified } = room;
    this.allUsersArray.forEach(user => {
      user.selected = members.includes(user.id);
      user.notify = notified.includes(user.id);
    });
    this.cdRef.detectChanges();
  }

  reset() {
    this.allUsersArray.forEach(user => {
      user.selected = true;
      user.notify = false;
    });
  }

  canDeselect(user: any): boolean {
    const originalState = this.originalUsersState.find(u => u.id === user.id);
    return originalState
      ? user.rolePosition < this.currentUser_rolePosition || !originalState.originalSelected
      : true;
  }

  canUnnotify(user: any): boolean {
    const originalState = this.originalUsersState.find(u => u.id === user.id);
    return originalState
      ? user.rolePosition < this.currentUser_rolePosition || !originalState.originalNotify
      : true;
  }

  async chat() {
    const selectedUsers = this.allUsersArray.filter(user => user.selected);
    const notifiedUsers = this.allUsersArray.filter(user => user.notify);
    const selectedUserIds = selectedUsers.map(user => user.id);
    const notifiedUserIds = notifiedUsers.map(user => user.id);
    if (this.roomId) {
      selectedUserIds.push(this.currentUser.userId);
      notifiedUserIds.push(this.currentUser.userId);
      const room = await this.chatService.updateRoomMembers(this.roomId, selectedUserIds, notifiedUserIds);
      this.chatService.openChatWindow(room.data);
    } else {
      let room = await this.chatService.createAlarmRoom(this.alarm, selectedUserIds, notifiedUserIds);
      this.chatService.openChatWindow(room);
    }

    if (this.currentUser.role.name === AppConstants.Operator && this.alarm.status === AlarmStatus.OPEN) {
      this.alarmService.acknowledgeAlarm(this.alarm);
    }

    this.originalUsersState = this.allUsersArray.map(user => ({
      ...user,
      originalSelected: user.selected,
      originalNotify: user.notify
    }));
    this.cancel();
  }

  cancel() {
    this.ChatBoxCancelled.emit();
    if (!this.alarm.alarmId) {
      this.stateService.saveChatBoxOpenState(this.alarm.id, false);
    }
  }

}
