import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { HierarchyNode } from 'd3';
import { trimTexts } from 'src/app/utils/commonfunctions';

interface treemapObject {
  label: string;
  key: string;
  value: number;
  percentage: number;
}

interface treemapData {
  children: treemapObject[];
}
@Component({
  selector: 'app-treemap',
  templateUrl: './treemap.component.html',
  styleUrls: ['./treemap.component.scss'],
})
export class TreemapComponent implements OnInit {
  @Input() data: treemapData = {
    children: [],
  };
  @Input() width: number = 320;
  @Input() height: number = 300;
  @Input() colors = ['#08306b', '#08519c', '#2171b5', '#4292c6', '#6baed6'];

  constructor() { }

  ngOnInit(): void {
    this.createTreemap();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['data'] && !changes['data'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['colors'] && !changes['colors'].firstChange)
    ) {
      d3.select('#treemap svg')?.remove();
      this.createTreemap();
    }
  }

  private createTreemap(): void {
    const minSize = window.innerHeight == 1024 ? 0.4 : 0.2;
    const maxSize = 1;


    const customScale = (value: number) => {
      const scaledValue = Math.pow(value, 0.6);
      return minSize + (maxSize - minSize) * (scaledValue / Math.pow(100, 0.6));
    };

    const treemapLayout = d3
      .treemap<treemapData>()
      .size([this.width, this.height])
      .padding(2)
      .round(true);

    const root = d3
      .hierarchy<{
        children: {
          label: string;
          key: string;
          value: number;
          percentage: number;
        }[];
      }>(this?.data)
      .sum((d: any) => customScale(d.percentage));

    treemapLayout(root as unknown as HierarchyNode<treemapData>);

    const svg = d3
      .select('#treemap')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height);

    const nodes = svg
      .selectAll('g')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('transform', (d: any) => `translate(${d.x0},${d.y0})`);

    nodes
      .append('rect')
      .attr('id', (d: any, index: number) => `treemap-rect-${index}`)
      .attr('width', (d: any) => Math.max(d.x1 - d.x0, 0))
      .attr('height', (d: any) => Math.max(d.y1 - d.y0, 0))
      .attr('fill', (d: any, index: number) => this.colors[index % this.colors.length])
      .attr('rx', 5)
      .attr('ry', 5);

    nodes
      .append('foreignObject')
      .attr('x', 2)
      .attr('y', 2)
      .attr('width', (d: any) => Math.max(d.x1 - d.x0 - 4, 0))
      .attr('height', (d: any) => Math.max(d.y1 - d.y0 - 4, 0))
      .append('xhtml:div')
      .style('color', 'white')
      .style('overflow', 'hidden')
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .style('justify-content', 'space-between')
      .style('padding', '0rem 0.2rem')
      .style('height', '100%')
      .style('width', '100%')
      .html((d: any) => {
        const boxWidth = d.x1 - d.x0;
        const boxHeight = d.y1 - d.y0;
        const fontSize = 1;
        const imageSize = Math.min(boxWidth / 3, boxHeight / 3, 30);

        return `
        <div style="display: flex; justify-content: space-between; align-items: flex-start;">
          <span title="${d.data.label}" style="overflow: hidden; text-overflow: ellipsis; word-wrap: break-word; max-width: 70%; font-size: ${fontSize * 0.8}rem; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">${d.data.label}</span>
          <img src="${(d.data.icon)}" style="width: ${imageSize}px; height: ${imageSize}px;" />
        </div>
        <div style="display: flex; justify-content: space-between; align-items: flex-end; font-size: ${fontSize}rem;align-items: center;">
          <span style="font-weight: bold; font-size: ${fontSize * 1.2}rem; color: #fac500;">${d.data.value}</span>
          <span style="font-size: ${fontSize * 0.8}rem;">${d.data.percentage}%</span>
        </div>
      `;
      });
  }

}
