import { Component, OnInit, Input } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { Subscription } from 'rxjs';
import { AppSessionStorage } from '../utils/app-session-storage-utils';
import { Alarm } from 'src/models/alarm.model';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { AlarmService } from '../services/alarm.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})

export class ChatComponent implements OnInit {
  @Input() id = null;
  newMessageSubscriber: Subscription;
  messages: Array<any> = [];
  userSession: any;
  timeFormat = AppConstants.CHAT_DATE_TIME_FORMAT;
  windowState: Subscription;
  enableReply: boolean = true;
  currentAlarm: Alarm;

  constructor(public chatService: ChatService, public authService: AuthenticationService,
    public alarmService: AlarmService) { }

  async ngOnInit() {
    this.userSession = AppSessionStorage.getUser();
    this.getRoomData(this.id);
    this.getAlarmData();
    this.newMessageListner();
    this.getWindowState();
  }

  newMessageListner(): void {
    this.newMessageSubscriber = this.chatService.newMessageListner().subscribe(async (message) => {
      const roomId = message.room;
      if (roomId !== this.id) {
        return;
      }
      message.type = message.type.toLowerCase();
      message.reply = this.userSession.userId == message.owner._id;
      this.messages.push(message);
    });
  }

  async sendMessage(event: any) {
    const files = !event.files ? [] : event.files.map((file: any) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });

    var message = {
      content: event.message,
      files: files,
      type: files.length ? 'file' : 'text',
      owner: {
        firstName: this.userSession.firstName,
        lastName: this.userSession.lastName,
      }
    }

    if (message.type === "file") {
      let blobs = [];
      let fileArrayBuffer = [];
      fileArrayBuffer.push(message.files.response);
      blobs.push(new Blob(fileArrayBuffer));
      const formData = new FormData();
      formData.append('image', blobs[0]);
      let res = await this.chatService.uploadMedia(formData, this.userSession.userId);
    } else {
      this.chatService.createRoomMessage(this.id, message.type, message.content);
    }

    if (this.userSession.role.name === AppConstants.Operator && this.currentAlarm
      && this.currentAlarm.status === AlarmStatus.OPEN) {
      this.alarmService.acknowledgeAlarm(this.currentAlarm);
    }
  }

  getWindowState() {
    this.windowState = this.chatService.windowStateListner().subscribe(async (data) => {
      if (data.roomId === this.id && data.state.newState === "maximized" && data.state.oldState) {
        this.getRoomData(this.id);
      }
    });
  }

  async getRoomData(roomId: string) {
    let res = await this.chatService.getRoomById(roomId);
    if (res && res.success === true) {
      let messages = res.data.messages;
      this.messages = messages.map((message: any) => {
        message.type = message.type.toLowerCase();
        message.reply = this.userSession.userId == message.owner._id;
        return message;
      });
    };
  }

  async getAlarmData() {
    let data = this.chatService.getCurrentAlarm();
    if (data && data.alarm) {
      this.currentAlarm = data.alarm;
      // control chat reply if alarm is false or resolved.
      if (data.alarm.status === AlarmStatus.FALSE || data.alarm.status === AlarmStatus.RESOLVED) {
        this.enableReply = data.enableReply
      }
    }
  }

  ngOnDestroy(): void {
    if (this.newMessageSubscriber) {
      this.newMessageSubscriber.unsubscribe();
    }
    this.windowState.unsubscribe();
  }

}