<div class="dialog-div">
	<h1 mat-dialog-title class="title">
		{{title}}
	</h1>

	<div mat-dialog-content class="message">
		<p>{{message}}</p>
	</div>

	<div mat-dialog-actions class="dialog-action-btn" *ngIf="typeOfDialog.yesNoDialog === selectedTypeofDialog">
		<button mat-button class="no-btn" (click)="onDismiss()">{{cancelText}}</button>
		<button mat-raised-button color="primary" class="filter-btn action" (click)="onConfirm()">{{confirmText}}
		</button>
	</div>
	<div mat-dialog-actions class="dialog-action-btn" *ngIf="typeOfDialog.okayDialog === selectedTypeofDialog">
		<button mat-raised-button color="primary" class="filter-btn action" (click)="onConfirm()">{{confirmText}}
		</button>
	</div>
</div>