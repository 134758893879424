import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Injectable()
export class CanActivateRouteGuard implements CanActivate {

	constructor(public authService: AuthenticationService) { }

	canActivate(route: ActivatedRouteSnapshot): boolean {
		return this.authService.hasRole(route.data.role);
	}
}
