import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Utility } from '../utils/app-utils';
import { CdkDragMove, DragRef } from '@angular/cdk/drag-drop';
import { Point } from 'leaflet';

@Component({
  selector: 'app-vnc-dialog',
  templateUrl: './vnc-dialog.component.html',
  styleUrls: ['./vnc-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VncDialogComponent implements OnInit {
  iframeUrl: any;
  utility = Utility;
  dragBoundary: HTMLElement | null = null;

  constructor(public dialog: MatDialog, private dialogRef: MatDialogRef<VncDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.iframeUrl = this.data;

    const appElement = document.querySelector('app-root');
    if (appElement) {
      this.dragBoundary = appElement as HTMLElement;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(VncDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  dragBoundaryFunction = (point: Point, dragRef: DragRef): Point => {
    const elementRect = window.document.body.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Ensure at least 100px of the element remains visible
    const minVisiblePx = 100;

    return new Point(
      Math.max(minVisiblePx - elementRect.width, Math.min(point.x, viewportWidth - minVisiblePx)),
      Math.max(0, Math.min(point.y, viewportHeight - minVisiblePx))
    );
  }

}
