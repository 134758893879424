import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-stats-tile',
	templateUrl: './stat-tile.component.html',
	styleUrls: ['./stat-tile.component.scss']
})
export class StatTileComponent {
	@Input() value;
	@Input() label;
	@Input() color;
	@Input() icon;
	constructor() { }
}
