import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-ask-ramsys',
  templateUrl: './ask-ramsys.component.html',
  styleUrls: ['./ask-ramsys.component.scss']
})
export class AskRamsysComponent implements AfterViewInit {
  @Input() iframeUrl: string;
  @ViewChild('iframeElement') iframeElement!: ElementRef<HTMLIFrameElement>;
  session_token: string;
  loginUrl: string;

  constructor(private authenticationService: AuthenticationService) { }

  ngAfterViewInit() {
    this.session_token = this.authenticationService.getAuthToken();

    setTimeout(() => {
      this.loginUrl = this.iframeUrl.endsWith('/') ? `${this.iframeUrl}login` : `${this.iframeUrl}/login`;

      this.iframeElement.nativeElement.onload = () => {
        const message = { session: this.session_token };
        this.iframeElement.nativeElement.contentWindow?.postMessage(message, this.loginUrl);
      };
    }, 0);
  }

}
