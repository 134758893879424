<div class="chart-block charttwo-block time-of-day radius-15 box-border d-flex flex-column flex-grow-1">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Alarm by time of the day</h2>
      <app-popover [title]="'Alarm by time of the day'"
        [content]="'Alarm details comparison between two adjacent time periods (selected period and adjacent previous period).'">
      </app-popover>
    </div>
    <div class="filter-block d-flex align-items-center">
      <select id="status-select" class="filter-select" name="status" [(ngModel)]="selectedStatus"
        (change)="onFilterChange()">
        <option value="" disabled selected>Status</option>
        <option value="">--</option>
        <option *ngFor="let status of statuses" [value]="status">
          {{status}}
        </option>
      </select>
      <select id="type-select" class="filter-select" name="type" [(ngModel)]="selectedType" (change)="onFilterChange()">
        <option value="" disabled selected>Type</option>
        <option value="">--</option>
        <option *ngFor="let type of alarmTypes" [value]="type">
          {{type}}
        </option>
      </select>
      <select id="severity-select" class="filter-select" name="severity" [(ngModel)]="selectedSeverity"
        (change)="onFilterChange()">
        <option value="" disabled selected>Severity</option>
        <option value="">--</option>
        <option *ngFor="let severity of severities" [value]="severity">
          {{severity}}
        </option>
      </select>
    </div>
  </div>
  <div class="chart-wrap" id="time-of-the-day" *ngIf="data?.today?.length> 0 && data?.previous_day?.length> 0">
    <div class="chart">
      <app-time-of-the-day-linechart [data]="data" [height]="dynamicHeight" [width]="dynamicWidth"
        [colors]="['#2B79ED', '#7A68F6']" [legends]="['Current period', 'Previous period']">
      </app-time-of-the-day-linechart>
    </div>
  </div>
  <div *ngIf="data?.today?.length === 0 && data?.previous_day?.length === 0"
    class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>