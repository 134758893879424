import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppConstants } from '../constants/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { SpeechService } from '../services/speech.service';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { WorkflowService } from '../services/workflow.service';
import { Utility } from '../utils/app-utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {
  @Output() sendEmailCancelled = new EventEmitter();
  utility = Utility;
  message: any;
  success_send: boolean = false;
  failure_send: boolean = false;
  // Language params
  QUESTION: string;
  YES: string;
  NO: string;
  selectedAlarmId: any;

  selectedAlarmSubscriber: Subscription;

  constructor(public translate: TranslateService, private speechService: SpeechService, private workflowService: WorkflowService, private stateService: UserSessionStateService,
    private alarmService: AlarmService) { }

  ngOnInit() {
    this.speechService.init();
    this.QUESTION = this.translate.instant('SEND_MAIL.QUESTION');
    this.YES = this.translate.instant('SEND_MAIL.YES');
    this.NO = this.translate.instant('SEND_MAIL.NO');
    this.selectedAlarmId = this.alarmService.selectedAlarm.id;

    this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm) => {
      if (alarm) {
        this.selectedAlarmId = alarm.id;
        this.success_send = false;
        this.failure_send = false;
      }
    });
  }

  yesClicked() {
    const alarmId = this.alarmService.selectedAlarm.id;
    this.sendMail(alarmId);
  }

  noClicked() {
    this.sendEmailCancelled.emit();
    const alarmId = this.alarmService.selectedAlarm.id;
    this.stateService.saveSendMailBoxOpenState(alarmId, false);
  }

  async sendMail(alarmId: string) {
    let response = await this.workflowService.sendMail(alarmId);
    if (response.success === true) {
      if (alarmId === this.selectedAlarmId) {
        this.message = response.data[0].msg;
        this.success_send = true;
      }
    } else {
      if (alarmId === this.selectedAlarmId) {
        this.message = response.errorMessages[0].message;
        this.failure_send = true;
      }
    }
    // this.message = this.translate.instant('SEND_MAIL.NETWORK_ERROR');
  }

  closeCard() {
    this.noClicked();
  }

  async read(data: any) {
    await this.speechService.read(data);
  }

  async stopRead() {
    await this.speechService.stopRead();
  }

  async ngOnDestroy() {
    this.selectedAlarmSubscriber.unsubscribe();
  }
}
