import { Component, Input, OnInit } from '@angular/core';
import { formatValue } from 'src/app/utils/commonfunctions';

@Component({
  selector: 'app-incidents-till-now',
  templateUrl: './incidents-till-now.component.html',
  styleUrls: ['./incidents-till-now.component.scss']
})
export class IncidentsTillNowComponent implements OnInit {

  constructor() { }
  @Input() data: string[] = [];

  ngOnInit(): void {
  }

  formatIncidentValue(value: number) {
    return formatValue(value);
  }
}
