import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { AlarmService } from '../services/alarm.service';
import { Alarm } from '../../models/alarm.model';
import { AssetDoc } from '../../models/asset-doc.model';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AppConstants } from '../constants/app-constants';
import { DateUtil } from '../utils/date-util';
import { AuthenticationService } from '../services/authentication.service';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';
import { Utility } from '../utils/app-utils';

@Component({
	selector: 'app-asset-docs',
	templateUrl: './asset-docs.component.html',
	styleUrls: ['./asset-docs.component.scss']
})

export class AssetDocsComponent implements OnInit {
	alarmOrBranchId: string;
	public assetsDocs: AssetDoc[] = [];
	public selectedAssetDocId: any = null;
	@Output() selectedAssetDoc = new EventEmitter<any>();
	appConstants = AppConstants;
	component: any;
	utility = Utility;
	@Input() alarm: Alarm;

	constructor(
		private alarmService: AlarmService,
		private userSessionStateService: UserSessionStateService,
		private authService: AuthenticationService,
		public confidenceMonitoringService: ConfidenceMonitoringService
	) { }

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.alarm.currentValue) {
			this.alarm = changes.alarm.currentValue;
			this.assetsDocs = [];
			this.selectedAssetDocId = null;
			if (this.alarm) {
				this.alarmOrBranchId = this.alarm.id;
				const branchId = this.alarm.branch['id'];
				await this.getAssetData(branchId);
				this.restorePrevSelection(this.alarm.id);
			}
		}
	}

	ngOnInit() { }

	ngOnDestroy() { }

	showAsset(assetDoc: AssetDoc) {
		this.selectedAssetDocId = assetDoc ? assetDoc.id : undefined;
		this.selectedAssetDoc.emit(assetDoc);
		this.userSessionStateService.saveSelectedAssetId(this.alarmOrBranchId, this.selectedAssetDocId);
	}

	async getAssetData(branchId: string) {
		this.assetsDocs = await this.alarmService.getAssetData(branchId);
	}

	/**
	 * Returns date in format dd/MM/yyyy;
	 * @param activationTime activation time
	 */
	getFormatedDate(activationTime: any, format: string) {
		return DateUtil.getFormattedDate(activationTime, format, this.authService.getUserTimezone());
	}

	/**
	 * Restore previous state of the asset doc selection in the asset doc list
	 */
	restorePrevSelection(alarmId: string) {
		if (this.assetsDocs && this.assetsDocs.length > 0) {
			// Get previous selected asset doc id.
			const docId: string = this.userSessionStateService.getSelectedAssetDocId(alarmId);
			let selDoc: AssetDoc = null;
			if (docId) {
				selDoc = this.assetsDocs.find((doc) => doc.id === docId);
			}
			if (selDoc) {
				this.showAsset(selDoc);
			}
		}
	}
}
