import { Component, OnInit, OnDestroy, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { VideoStreamService } from '../services/video-stream.service';
import { VideoFeed } from '../../models/video-feed.model';
import { Subscription } from 'rxjs';
import { Utility } from '../utils/app-utils';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';

@Component({
	selector: 'app-video-feeds',
	templateUrl: './video-feeds.component.html',
	styleUrls: ['./video-feeds.component.scss']
})
export class VideoFeedsComponent implements OnInit, OnDestroy, OnChanges {
	private videoSourceChangedSub: Subscription;
	private zoneVideoSourceChangedSub: Subscription;
	// Flag used to detect whether user viewing alarm from other branch than last selected.
	public lastBranchId = null;
	public branchId = null;
	selectedAlarm: any;
	public lastselectedAlarmId = null;
	lastZoneId = null;
	public videoFeeds: VideoFeed[] = [];
	public fullScreenVideo: VideoFeed = null;
	public fullScreenVideoStyle: any = {};
	sitesChangedSubscriber: Subscription;
	@Input() shouldCloseFullScreen = false;
	@Output() videoSourceChange = new EventEmitter();
	utility = Utility;
	windowVideos = [0, 1];
	alarmsExist: boolean = false;

	constructor(public videoStreamService: VideoStreamService, private userSessionStateService: UserSessionStateService, public alarmService: AlarmService,
		public confidenceMonitoringService: ConfidenceMonitoringService) { }

	async ngOnChanges() {
		if (this.shouldCloseFullScreen) {
			this.closeFullScreenVideo();
		};
	}

	ngOnInit() {
		this.videoStreamService.setToken();
		this.zoneVideoSourceChangedSub = this.videoStreamService.videoSourceZoneNotification().subscribe(async (sources) => {
			this.selectedAlarm = this.alarmService.selectedAlarm;
			if (this.selectedAlarm) {
				if (this.selectedAlarm.id !== this.lastselectedAlarmId) {
					this.closeFullScreenVideo();
				}
				this.lastselectedAlarmId = this.selectedAlarm.id;
			};
			this.lastBranchId = null;
			if (sources) {
				if (this.lastZoneId !== (this.selectedAlarm?.sensor?.zone?._id || this.selectedAlarm?.zone?.id)) {
					await this.videoStreamService.setEmptyVideoSources();
					this.fullScreenVideo = null;
					this.lastZoneId = this.selectedAlarm?.sensor?.zone?._id || this.selectedAlarm?.zone?.id;
					await this.loadVideoFeeds_zones(sources);
					this.videoSourceChange.emit();
					this.windowVideos = [0, 1];
				}
			} else {
				this.fullScreenVideo = null;
				this.videoFeeds = [];
				this.lastZoneId = null;
				this.videoSourceChange.emit();
				await this.videoStreamService.setEmptyVideoSources();
			}
		});

		// called when site is selected in Confidence Monitoring mode
		this.sitesChangedSubscriber = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
			this.windowVideos = [0, 1];
			this.lastBranchId = null;
			this.lastZoneId = null;
		});

		this.ChangeVideoSource();
	}

	ChangeVideoSource() {
		this.videoSourceChangedSub = this.videoStreamService.videoSourceBranchNotification().subscribe(async (branchId) => {
			if (this.alarmService.alarmList) {
				this.alarmService.alarmList.length > 0 ? this.alarmsExist = true : this.alarmsExist = false;
			};

			this.selectedAlarm = this.alarmService.selectedAlarm;
			if (this.selectedAlarm) {
				if (this.selectedAlarm.id !== this.lastselectedAlarmId) {
					this.closeFullScreenVideo();
				}
				// update lastselectedAlarmId
				this.lastselectedAlarmId = this.selectedAlarm.id;
			};

			this.lastZoneId = null;
			if (branchId && this.alarmsExist) {
				if (this.lastBranchId !== branchId) {
					await this.videoStreamService.setEmptyVideoSources();
					this.fullScreenVideo = null;
					this.lastBranchId = branchId;
					this.loadVideoFeeds(branchId);
					this.videoSourceChange.emit();
					this.windowVideos = [0, 1];
				}
			} else {
				this.fullScreenVideo = null;
				this.lastBranchId = null;
				this.lastZoneId = null;
				this.videoFeeds = [];
				this.videoSourceChange.emit();
			}
		});
	}

	ngOnDestroy() {
		window.onresize = null;
		this.videoSourceChangedSub.unsubscribe();
		this.zoneVideoSourceChangedSub.unsubscribe();
		this.videoStreamService.setEmptyVideoSources();
		this.sitesChangedSubscriber.unsubscribe();
	}

	async loadVideoFeeds(branchId: string) {
		this.videoFeeds = await this.videoStreamService.fetchVideoSourcesList(branchId);
	}

	async loadVideoFeeds_zones(sources: any) {
		this.videoFeeds = await this.videoStreamService.fetchVideoSourcesList_zones(sources);
	}

	async shareVideo(windoNo: number) {
		let alarmType = this.selectedAlarm.type;
		if (windoNo === 0) {
			await this.videoStreamService.shareVideo(this.videoStreamService.sourceOneName, alarmType);
		} else if (windoNo === 1) {
			await this.videoStreamService.shareVideo(this.videoStreamService.sourceTwoName, alarmType);
		}
	}

	async unShareVideo() {
		await this.videoStreamService.unShareVideo();
	}

	async controlVideo(windoNo: number, videoFeed: any) {
		if (windoNo === 0) {
			let response = await this.videoStreamService.controlVideo(this.videoStreamService.sourceOneIp);
			if (response === true) {
				this.showFullScreenVideo(videoFeed);
			}
		} else if (windoNo === 1) {
			let response = await this.videoStreamService.controlVideo(this.videoStreamService.sourceTwoIp);
			if (response === true) {
				this.showFullScreenVideo(videoFeed);
			}
		}
	}

	showFullScreenVideo(videoFeed: VideoFeed) {
		this.closeFullScreenVideo();
		// Let Angular CD to detect changes.
		setTimeout(() => {
			this.fullScreenVideo = videoFeed;
			this.updateFullscreenPlayerStyle();
			window.onresize = () => this.updateFullscreenPlayerStyle();
		}, 50);
		this.videoStreamService.fullScreenVideo = true;
	}

	closeFullScreenVideo() {
		this.fullScreenVideo = null;
		window.onresize = null;
		this.videoStreamService.fullScreenVideo = false;
	}
	/**
	 * Show full screen player just above map
	 */
	updateFullscreenPlayerStyle() {
		const div = document.getElementsByClassName('asset-view-section')[0];
		if (div) {
			const mapAreaDimension = div.getBoundingClientRect();
			this.fullScreenVideoStyle = {
				height: mapAreaDimension.height + 1 + 'px',
				width: mapAreaDimension.width + 2 + 'px',
				top: mapAreaDimension.top - 1 + 'px',
				left: mapAreaDimension.left - 1 + 'px'
			};
		}
	}

}
