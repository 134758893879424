import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticalDashboardService } from '../services/analytical-dashboard.service';
import { AppConstants } from '../constants/app-constants';
import { DateUtil } from '../utils/date-util';
import { AnalyticalChartsComponent } from './analytical-charts/analytical-charts.component';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from '../services/socket.service';
import { WEB_SOCKET_EVENTS } from '../enums/web-socket-events';
import { AuthenticationService } from '../services/authentication.service';
import { BranchService } from '../services/branch.service';
import { Router } from '@angular/router';
import { RouteConstants } from '../constants/route-constants';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-analytical-dashboard',
	templateUrl: './analytical-dashboard.component.html',
	styleUrls: ['./analytical-dashboard.component.scss']
})
export class AnalyticalDashboardComponent implements OnInit {

	lastUpdateMessage: string;
	filterType = null;
	selectedChartSubscription: Subscription;

	@ViewChild(AnalyticalChartsComponent, { static: true }) analyticalChartsComponent: AnalyticalChartsComponent;

	constructor(
		public dashboard: AnalyticalDashboardService,
		public translate: TranslateService,
		private socketService: SocketService,
		private authenticationService: AuthenticationService,
		private branchService: BranchService,
		private router: Router,
		private userSessionStateService: UserSessionStateService,
		public alarmService: AlarmService,
	) { }

	ngOnInit() {
		this.selectedChartSubscription = this.dashboard.OnSelectedChartNotification().subscribe((chartType) => {
			if (chartType === 1) {
				this.filterType = "ALARMS";
			} else if (chartType === 2) {
				this.filterType = "OPERATORS";
			} else if (chartType === 3) {
				this.filterType = "SITES";
			}
		});

		this.userSessionStateService.modifyAnalyticalDashboardState(true);
		this.dashboard.isFilterDataLoaded = false;
		this.dashboard.lastUpdatedTime = new Date();
		this.dashboard.fetchAlarmsStatistics();
		this.dashboard.fetchOperatorsStatistics();
		this.dashboard.fetchSitesStatistics();
		this.dashboard.filterOperatorReport(AppConstants.JSON_TEXT);
		setInterval(() => {
			this.setLastUpdatedTime();
		}, AppConstants.APP_CONFIG_INIT_TIMER);

		// this.refreshSupervisorRoutesEvent();
	}

	ngOnDestroy() {
		this.dashboard.isFilterDataLoaded = false;
		this.dashboard.chartSelected = undefined;
		// this.socketService.closeSocket();
		this.userSessionStateService.modifyAnalyticalDashboardState(false);
		this.selectedChartSubscription.unsubscribe();
		this.dashboard.SelectedChartNotification.next(1);
	}

	/**
	 * sets last updated timer
	 */
	setLastUpdatedTime() {
		const differenceBetweenTimes = DateUtil.getTrialPeriodRemaining(Math.abs(new Date().getTime() - new Date(this.dashboard.lastUpdatedTime).getTime()));
		if (differenceBetweenTimes.days === 0 && differenceBetweenTimes.hours === 0 && differenceBetweenTimes.minutes > 0) {
			this.lastUpdateMessage = this.translate.instant('ANALYTICAL_DASHBOARD.LAST_UPDATED') + ' ' + differenceBetweenTimes.minutes + ' ' + this.translate.instant('ANALYTICAL_DASHBOARD.MINUTES_AGO');
		} else if (differenceBetweenTimes.days === 0 && differenceBetweenTimes.hours > 0 && differenceBetweenTimes.minutes > 0) {
			this.lastUpdateMessage = this.translate.instant('ANALYTICAL_DASHBOARD.LAST_UPDATED') + ' ' + differenceBetweenTimes.hours + ' ' + this.translate.instant('ANALYTICAL_DASHBOARD.HOURS') + ' ' + differenceBetweenTimes.minutes + ' ' + this.translate.instant('ANALYTICAL_DASHBOARD.MINUTES_AGO');
		} else if (differenceBetweenTimes.days > 0) {
			this.lastUpdateMessage = this.translate.instant('ANALYTICAL_DASHBOARD.LAST_UPDATED') + ' ' + differenceBetweenTimes.days + ' ' + this.translate.instant('ANALYTICAL_DASHBOARD.DAYS_AGO');
		} else if (differenceBetweenTimes.minutes === 0) {
			this.lastUpdateMessage = this.translate.instant('ANALYTICAL_DASHBOARD.LAST_UPDATED') + ' ' + this.translate.instant('ANALYTICAL_DASHBOARD.MINUTE');
		}
	}

	/**
	 * Reload the component and fetch data
	 */
	updateData() {
		this.dashboard.isFilterDataLoaded = false;
		this.dashboard.lastUpdatedTime = new Date();
		this.analyticalChartsComponent.selectChart(this.analyticalChartsComponent.chartMenus[this.dashboard.chartSelected - 1]);
		this.setInitFilterState();
	}

	//Real time component refresh
	refreshSupervisorRoutesEvent() {
		this.socketService.initSocket();
		const socketId = localStorage.getItem('socketId');
		if (!socketId) {
			// socketId is missing hence listening to event is ignored
			return;
		}
		const eventNotification = this.socketService.onEvent<any>(socketId);
		if (eventNotification) {
			eventNotification.subscribe((socketEvent) => {
				const data = socketEvent.data;
				// Received web socket event", socketEvent
				switch (socketEvent.type) {
					case WEB_SOCKET_EVENTS.REFRESH_SUPER_ROUTES:
					// commenting next function due to #228 staging
					// this.updateData();
					// break;
					case WEB_SOCKET_EVENTS.UPDATE_MAP:
						this.updateMap();
						break;
					case WEB_SOCKET_EVENTS.LOG_OUT:
						this.Logout(data);
						break;
					case WEB_SOCKET_EVENTS.MESSAGE:
						this.onMessage(data);
						break;
					default:
				}
			});
		}
	}

	Logout(data: any) {
		let currentToken = this.authenticationService.token;
		if (currentToken === data.session_token) {
			this.router.navigate([RouteConstants.PAGES + RouteConstants.LOGIN]);
		}
	}

	async updateMap() {
		await this.branchService.getMapBranches2();
	}

	setInitFilterState() {
		this.dashboard.initFilterState(true);
	}

	/**
 * Change the size of table related to selected filter
 */
	changeTableSize() {
		this.dashboard.changeTableSizeOnAddedFilter(AppConstants.TABLE_CONTAINER_ID);
	}

	onMessage(data: any) {
		this.alarmService.onMessage(data)
	}

}
