<div class="map-wrapper">
	<div class="map-container night-mode-map"
		id="map-container"
		*ngIf="options"
		leaflet
		[leafletOptions]="options"
		[leafletLayers]="markers"
		[(leafletCenter)]="center"
		[(leafletZoom)]="zoom"
		(leafletMapReady)="onMapReady($event)">
	</div>

	<div class="search-input-text" *ngIf="shouldShowSearchInput && shouldMakeSearchVisible">
		<div class="row">
			<div class="search-bar">
				<input type="text" matInput [matAutocomplete]="auto" (keyup)="searchBranch()" class="search-input"
					[(ngModel)]="searchText">
				<i class="fa fa-search search-icon" aria-hidden="true"></i>
				<mat-autocomplete #auto="matAutocomplete">
					<mat-option *ngFor="let branch of branchSearchResult" [value]="branch"
						(click)="zoomToBranch(branch)">{{branch.branchName}}</mat-option>
				</mat-autocomplete>
			</div>
		</div>
	</div>
	
</div>