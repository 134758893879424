import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomPaginatorIntlService extends MatPaginatorIntl {

  constructor(public translate: TranslateService) {
    super();
    this.updatePaginationLabels();
    this.translate.onLangChange.subscribe(() => {
      this.updatePaginationLabels();
    });
  }

  updatePaginationLabels() {
    this.itemsPerPageLabel = this.translate.instant('TABLE.ITEMS_PER_PAGE');
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, length);
    const rangeLabel = `${startIndex} - ${endIndex} ${this.translate.instant('TABLE.OF')} ${length}`;
    return this.translate.instant(rangeLabel);
  };
}
