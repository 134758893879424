import { Component, OnInit, Input } from '@angular/core';
import { trimTexts } from 'src/app/utils/commonfunctions';

interface AlarmInsightsData {
  label: string;
  key: string;
  value: string;
}

@Component({
  selector: 'app-alarm-insights',
  templateUrl: './alarm-insights.component.html',
  styleUrls: ['./alarm-insights.component.scss'],
})
export class AlarmInsightsComponent implements OnInit {
  @Input() data: AlarmInsightsData[] = [{ label: '', key: '', value: '' }];

  constructor() { }

  ngOnInit(): void { }

  getTrimmedText(text: string) {
    const trim = window.innerWidth >= 1800 && window.innerWidth <= 1920 ? 6 : null
    return trimTexts(text, trim);
  }
}
