<div class="stats-row-wrapper"
	*ngIf="statistics.length > 0">
	<div class="stats-row" *ngFor="let row of statistics">
		<app-stats-tile *ngFor="let stats of row"
			[color]="stats.color"
			[value]="stats.count"
			[label]="stats.name"
			[icon]="stats.icon"
			class="alarms-tiles"
			[class.unselected-tile]="isNotActive(stats.name)"
			[class.all-unselected-tiles]="selectedTile === null"></app-stats-tile>
	</div>
</div>