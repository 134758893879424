import { Injectable } from '@angular/core';
import * as charts from 'highcharts';
import { AnalyticalDashboardService } from './analytical-dashboard.service';
import { AppConstants } from '../constants/app-constants';
import { Utility } from '../utils/app-utils';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './../app-config.service';
import { AuthenticationService } from './authentication.service';
import { AppLocalStorage } from 'src/app/utils/app-local-storage-utils';

@Injectable({
	providedIn: 'root'
})
export class ChartService {
	public isSelectEventFired: any = false;

	constructor(public dashboard: AnalyticalDashboardService, public translate: TranslateService, public appConfigService: AppConfigService,
		private authenticationService: AuthenticationService) { }
	isLegendRenderComplete = true;
	public lang: any;

	/**
	 * called to get pie chart options
	 */
	buildPieChartOptions(chartOptions?) {
		const _this = this;
		let previousSelected: any = false;
		const defaultOptions = {
			width: 760,
			spacingLeft: 150,
			shouldShowLegend: true,
			shouldCountInTooltip: false,
			spacingBottom: 415,
			fontSize: '32px',
			symbolHeight: 30,
			titleFont: '32px',
			chartOptions: 150,
			lineHeight: '30x',
			itemMarginBottom: 20,
			customLegend: false,
			legendHight: '50px'
		};

		chartOptions = {
			...defaultOptions,
			...chartOptions
		};
		const options = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				backgroundColor: null,
				height: 650,
				width: chartOptions.width,
				verticalAlign: 'top',
				spacingBottom: chartOptions.spacingBottom,
				spacingLeft: chartOptions.spacingLeft,
				textAlign: 'center',
				x: 0,
				marginTop: null,
			},
			title: {
				align: 'center',
				verticalAlign: 'middle',
				floating: true,
				y: 165,
				style: {
					fontSize: chartOptions.titleFont,
					color: 'var(--text_color)',
					fontFamily: 'inherit'
				}
			},
			tooltip: {
				backgroundColor: 'var(--hover-color)',
				shared: false,
				borderWidth: 0,
				borderRadius: 60,
				outside: true,
				shadow: false,
				formatter: function () {
					if (chartOptions.shouldCountInTooltip) {
						return this.point.name + ' ' + this.point.count;
					}
					return this.point.name + ' ' + this.y + '%';
				},
				pointFormat: '{point.percentage:.1f}%',
				style: {
					color: 'var(--text_color)',
					fontSize: 24,
					opacity: 0.73,
					fontFamily: 'inherit'
				}
			},
			legend: {
				enabled: chartOptions.shouldShowLegend,
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'middle',
				floating: false,
				backgroundColor: null,
				symbolHeight: chartOptions.symbolHeight,
				useHTML: true,
				itemStyle: {
					textTransform: 'capitalize',
					fontWeight: 'normal',
					color: 'var(--text_color)',
					fontSize: chartOptions.fontSize,
					lineHeight: chartOptions.lineHeight,
					height: chartOptions.legendHight,
					fontFamily: 'inherit'
				},
				y: chartOptions.legendY,
				itemMarginBottom: chartOptions.itemMarginBottom,
				labelFormatter: function () {
					if (chartOptions.customLegend) {
						if (_this.isLegendRenderComplete) {
							_this.isLegendRenderComplete = false;
							return chartOptions.legendData;
						} else {
							return null;
						}
					} else {
						return this.name + ' : ' + this.options.count;
					}
				},
			},
			plotOptions: {
				pie: {
					showInLegend: true,
					allowPointSelect: true,
					states: {
						select: {
							plotBackgroundColor: null,
							plotBorderWidth: 0,
							plotShadow: false,
							backgroundColor: null,
						}
					},
					borderWidth: 0,
					dataLabels: {
						overflow: 'none',
						crop: false,
						enabled: true,
						distance: 0,
						style: {
							fontWeight: 'bold',
							fontSize: 23
						}
					},
					startAngle: 0,
					endAngle: 360,
					center: ['50%', '75%'],
					size: '100%',
					innerSize: '68%',
				}
			},
			credits: {
				enabled: false
			},
			series: [{
				cursor: 'pointer',
				type: 'pie',
				enabled: true,
				states: {
					hover: {
						halo: {
							size: 0,
						}
					}
				},
				point: {
					events: {
						// click: function (e) {
						// 	this.isSelectEventFired = false;
						// 	_this.toggleSeriesSelectionStyle(chartOptions.containerId, true);
						// 	_this.setPaginationOption();
						// },
						legendItemClick: function () {
							return false;
						}
						// ,
						// unselect: function (e) {
						// 	if (!this.isSelectEventFired) {
						// 		_this.toggleSeriesSelectionStyle(chartOptions.containerId, false);
						// 		previousSelected = false;
						// _this.onPieChartSeriesUnselect(e);
						// }
						// },
						// select: function (e) {
						// _this.onPieChartSeriesSelect(e.target.name, previousSelected);
						// previousSelected = e.target.name;
						// this.isSelectEventFired = true;
						// this.slice();
						// }
					},
				},
				style: {
					fontWeight: 'bold',
					color: 'var(--text_color)',
					fontSize: 23
				},
				dataLabels: {
					enabled: false,
					distance: -5,
					formatter: function () {
						return this.key + '<br>' + this.y + '%';
					}
				}
			}
			],
		};
		return options;
	}

	setPaginationOption() {
		this.dashboard.filterParams.start = AppConstants.PAGE_START_CONSTANT;
		this.dashboard.filterParams.pageSize = AppConstants.DEFAULT_PAGE_SIZE;
	}

	/**
	 * called to show tooltip on alarms chart title
	 */
	showTooltipOnTitle(chartDivId, getToolTipContent: Function) {
		const pieChart = document.getElementById(chartDivId);
		const title = pieChart.getElementsByClassName('highcharts-title')[0];
		const tootlTip = getToolTipContent();
		const div = document.createElement('div');
		div.style.display = 'none';
		div.innerHTML = tootlTip;
		div.classList.add('highchart-title-tooltip');
		document.body.appendChild(div);

		title.addEventListener('mouseover', (event: any) => {
			div.classList.add('title-tooltip-active');
			div.style.display = 'block';
		});

		title.addEventListener('mousemove', (event: any) => {
			const left = event.clientX - 100 + 'px';
			const top = event.clientY - 70 + 'px';
			div.style.left = left;
			div.style.top = top;
			div.style.display = 'block';
		});

		title.addEventListener('mouseout', (event) => {
			div.classList.remove('title-tooltip-active');
			div.style.display = 'none';
		});
	}

	/**
	 * called to change style of selected and unSelected chart series
	 */
	toggleSeriesSelectionStyle(containerId, selected) {
		const className = 'has-series-selction';
		const container: HTMLElement = document.querySelector(`#${containerId}>div`);
		if (selected) {
			container.classList.add(className);
		} else {
			container.classList.remove(className);
		}
	}

	/**
	 * called when a series is selected from chart
	 */
	// onPieChartSeriesSelect(alarmType, previousSelected) {
	// 	this.dashboard.SelectedSeriesForChartOne = [];
	// 	if (this.dashboard.chartSelected === this.dashboard.chartType.ALARM) {
	// 		this.selectAlarmsFilter(alarmType, previousSelected);
	// 	} else if (this.dashboard.chartSelected === this.dashboard.chartType.OPERATOR) {
	// 		this.selectOperatorsFilter(alarmType, previousSelected);
	// 	} else {
	// 		this.selectSitesFilter(alarmType, previousSelected);
	// 	}
	// }

	// RemovePreviousFilter(filterList, alarmType, previousSelected) {
	// 	if (previousSelected) {
	// 		filterList = (filterList || []).filter(listItem => listItem !== previousSelected);
	// 	}
	// 	filterList = filterList || [];
	// 	filterList.push(alarmType);
	// 	return filterList;
	// }

	// selectAlarmsFilter(alarmType, previousSelected) {
	// 	this.dashboard.SelectedSeriesForChartOne.push(alarmType);
	// 	this.dashboard.filterParams.alarmTypes = this.RemovePreviousFilter(this.dashboard.filterParams.alarmTypes, alarmType, previousSelected);
	// 	this.dashboard.filterAlarmsHistoryList(this.dashboard.filterParams);
	// }

	// selectOperatorsFilter(alarmType, previousSelected) {
	// 	this.dashboard.SelectedSeriesForChartOne.push(alarmType);
	// 	this.dashboard.operatorsFilterParams.regions = this.RemovePreviousFilter(this.dashboard.operatorsFilterParams.regions, alarmType, previousSelected);
	// 	this.dashboard.filterOperatorsReportList(this.dashboard.operatorsFilterParams);
	// }

	// selectSitesFilter(alarmType, previousSelected) {
	// 	let filterUsed = false;
	// 	this.dashboard.sitesStats.regions.forEach(region => {
	// 		if (region.name === alarmType) {
	// 			filterUsed = true;
	// 			this.dashboard.SelectedSeriesForChartOne.push(alarmType);
	// 			this.dashboard.branchParams.areaNames = this.RemovePreviousFilter(this.dashboard.branchParams.areaNames, alarmType, previousSelected);
	// 			this.dashboard.filterSitesList(this.dashboard.branchParams);
	// 		}
	// 	});
	// 	if (!filterUsed) {
	// 		this.dashboard.SelectedSeriesForChartTwo = [];
	// 		this.dashboard.SelectedSeriesForChartTwo.push(alarmType);
	// 		this.dashboard.branchParams.branchTypes = this.RemovePreviousFilter(this.dashboard.branchParams.branchTypes, alarmType, previousSelected);
	// 		this.dashboard.filterSitesList(this.dashboard.branchParams);
	// 	}
	// }

	/**
	 * called when chart series is unSelected
	 */
	// onPieChartSeriesUnselect(e) {
	// 	if (this.dashboard.chartSelected === this.dashboard.chartType.ALARM) {
	// 		this.unSelectAlarmsFilter(e.target.name);
	// 	} else if (this.dashboard.chartSelected === this.dashboard.chartType.OPERATOR) {
	// 		this.unSelectOperatorsFilter(e.target.name);
	// 	} else {
	// 		this.unSelectSitesFilter(e.target.name);
	// 	}
	// }

	// removeFilterFromFilterList(filterList, ItemToBeRemoved) {
	// 	this.dashboard.SelectedSeriesForChartOne = [];
	// 	filterList = (filterList || []).filter(alarmType => alarmType !== ItemToBeRemoved);
	// 	return filterList.length ? filterList : null;
	// }

	// unSelectAlarmsFilter(alarmTypes) {
	// 	this.dashboard.filterParams.alarmTypes = this.removeFilterFromFilterList(this.dashboard.filterParams.alarmTypes, alarmTypes);
	// 	this.dashboard.filterAlarmsHistoryList(this.dashboard.filterParams);
	// }

	// unSelectOperatorsFilter(operatorRegion) {
	// 	this.dashboard.operatorsFilterParams.regions = this.removeFilterFromFilterList(this.dashboard.operatorsFilterParams.regions, operatorRegion);
	// 	this.dashboard.filterOperatorsReportList(this.dashboard.operatorsFilterParams);
	// }

	// unSelectSitesFilter(selected) {
	// 	let filterUsed = false;
	// 	this.dashboard.sitesStats.regions.forEach(region => {
	// 		if (region.name === selected) {
	// 			filterUsed = true;
	// 			this.dashboard.SelectedSeriesForChartOne = [];
	// 			this.dashboard.branchParams.areaNames = (this.dashboard.branchParams.areaNames || []).filter(areaName => areaName !== selected);
	// 			if (!this.dashboard.branchParams.areaNames.length) {
	// 				delete this.dashboard.branchParams['areaNames'];
	// 			}
	// 			this.dashboard.filterSitesList(this.dashboard.branchParams);
	// 		}
	// 	});
	// 	if (!filterUsed) {
	// 		this.dashboard.SelectedSeriesForChartTwo = [];
	// 		this.dashboard.branchParams.branchTypes = (this.dashboard.branchParams.branchTypes || []).filter(branchType => branchType !== selected);
	// 		if (!this.dashboard.branchParams.branchTypes.length) {
	// 			delete this.dashboard.branchParams['branchTypes'];
	// 		}
	// 		this.dashboard.filterSitesList(this.dashboard.branchParams);
	// 	}
	// }

	/**
	 * called to get KPI chart option
	 */
	buildKPIChartsOptions() {
		const options = {
			chart: {
				type: 'spline',
				backgroundColor: '#2c3b47',
				scrollablePlotArea: {
					minWidth: 1100,
					scrollPositionX: 1
				}
			},
			title: {
				text: 'Wind speed during two days',
				align: 'left',
				useHTML: true,
				style: {
					color: 'var(--text_color)',
					fontSize: '30px',
					'background-color': '#127bbd',
					'padding-left': '20px',
					'padding-right': '20px'
				}
			},
			credits: {
				enabled: false
			},
			xAxis: {
				type: 'datetime',
				labels: {
					overflow: 'justify',
					style: {
						color: 'var(--text_color)',
						fontSize: '17px'
					}
				},
				style: {
					color: 'var(--text_color)'
				}
			},
			yAxis: {
				title: false,
				labels: {
					overflow: 'justify',
					style: {
						color: 'var(--text_color)',
						fontSize: '17px'
					}
				},
				minorGridLineWidth: 0,
				gridLineWidth: 0,
				alternateGridColor: null,
				plotBands: [{
					from: 0.3,
					to: 1.5,
					color: 'rgba(68, 170, 213, 0.1)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}, {
					from: 1.5,
					to: 3.3,
					color: 'rgba(0, 0, 0, 0)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}, {
					from: 3.3,
					to: 5.5,
					color: 'rgba(68, 170, 213, 0.1)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}, {
					from: 5.5,
					to: 8,
					color: 'rgba(0, 0, 0, 0)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}, {
					from: 8,
					to: 11,
					color: 'rgba(68, 170, 213, 0.1)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}, {
					from: 11,
					to: 14,
					color: 'rgba(0, 0, 0, 0)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}, {
					from: 14,
					to: 15,
					color: 'rgba(68, 170, 213, 0.1)',
					label: {
						style: {
							color: 'var(--text_color)'
						}
					}
				}]
			},
			tooltip: {
				valueSuffix: ' m/s'
			},
			legend: {
				itemStyle: {
					color: 'var(--text_color)',
					fontSize: '23px'
				},
				labelFormatter: function () {
					let total = 0;
					for (let i = 0; i < this.yData.length; i++) {
						total += this.yData[i];
					}
					const avg = total / this.yData.length;
					return this.name + ' Max : ' + Math.max.apply(null, this.yData) +
						' Min : ' + Math.min.apply(null, this.yData) + ' Avg: ' + avg.toFixed(2) + ' <br>';
				}
			},
			plotOptions: {
				spline: {
					lineWidth: 4,
					states: {
						hover: {
							lineWidth: 5
						}
					},
					marker: {
						enabled: false
					},
					pointInterval: 3600000,
					pointStart: Date.UTC(2018, 1, 13, 0, 0, 0)
				}
			},
			series: [{
				name: 'op1',
				data: [
					3.7, 3.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4,
					6.9, 6.0, 6.8, 4.4, 4.0, 3.8, 5.0, 4.9, 9.2, 9.6, 9.5, 6.3,
					9.5, 10.8, 14.0, 11.5, 10.0, 10.2, 10.3, 9.4, 8.9, 10.6, 10.5, 11.1,
					10.4, 10.7, 11.3, 10.2, 9.6, 10.2, 11.1, 10.8, 13.0, 12.5, 12.5, 11.3,
					10.1
				]

			}, {
				name: 'op2',
				data: [
					0.2, 0.1, 0.1, 0.1, 0.3, 0.2, 0.3, 0.1, 0.7, 0.3, 0.2, 0.2,
					0.3, 0.1, 0.3, 0.4, 0.3, 0.2, 0.3, 0.2, 0.4, 0.0, 0.9, 0.3,
					0.7, 1.1, 1.8, 1.2, 1.4, 1.2, 0.9, 0.8, 0.9, 0.2, 0.4, 1.2,
					0.3, 2.3, 1.0, 0.7, 1.0, 0.8, 2.0, 1.2, 1.4, 3.7, 2.1, 2.0,
					1.5
				]
			},
			{
				name: 'op3',
				data: [
					2.7, 4.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4,
					6.9, 6.0, 6.8, 4.4, 4.0, 23.8, 5.0, 4.9, 9.2, 9.6, 9.5, 6.3,
					9.5, 10.8, 4.0, 11.5, 1.0, 4.2, 5.3, 9.4, 8.9, 0.6, 10.5, 11.1,
					10.4, 1.7, 1.3, 10.2, 6.6, 0.2, 1.1, 10.8, 3.0, 12.5, 12.5, 11.3,
					10.1
				]

			}],
			navigation: {
				menuItemStyle: {
					fontSize: '23px'
				}
			}
		};
		return options;
	}

	/**
	 * called to render pieChart
	 */
	async renderPieChart(containerId: string, title: string, seriesData: [], chartOptions?: any) {
		this.isLegendRenderComplete = true;
		this.lang = AppLocalStorage.getLanguage();
		chartOptions = chartOptions ? chartOptions : {};
		chartOptions.containerId = containerId;
		let options: any = this.buildPieChartOptions(chartOptions);
		const chart = charts.chart(containerId, options);
		const resolutionOfScreen = Utility.getScreenSize();
		if (title === this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_ALARMS')) {
			if (resolutionOfScreen === AppConstants.NORMAL) {
				chart.setTitle({ text: title, x: -165 });
			} else if (resolutionOfScreen === AppConstants.HALF_WFHD) {
				chart.setTitle({ text: title, x: -160, y: 155 });
			} else if (resolutionOfScreen === AppConstants.WFHD) {
				chart.setTitle({ text: title, x: -150, y: 120 });
			} else if (resolutionOfScreen === AppConstants.HALF_WUHD) {
				chart.setTitle({ text: title, x: -150, y: 105 });
			} else if (resolutionOfScreen === AppConstants.FULL_HD) {
				chart.setTitle({ text: title, x: -150, y: 105 });
				// options = {
				// 	chart :{
				// 		width: 600,
				// 		spacingLeft: 95
				// 	}
				// }
				// chart.update(options);
			} else if (resolutionOfScreen === AppConstants.WUXGA) {
				chart.setTitle({ text: title, x: -165, y: 135 });
			} else {
				chart.setTitle({ text: title, x: -170, y: 250 });
			}
		} else if (title === this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_OPERATORS')) {
			if (resolutionOfScreen === AppConstants.WUHD) {
				chart.setTitle({ text: title, x: -160, y: 250 });
			} else if (resolutionOfScreen === AppConstants.NORMAL) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -70 });
				} else if (this.lang === "ru") {
					chart.setTitle({ text: title, x: -150 });
				} else {
					chart.setTitle({ text: title, x: -140 });
				}
			} else if (resolutionOfScreen === AppConstants.HALF_WFHD) {
				chart.setTitle({ text: title, x: -140, y: 155 });
			} else if (resolutionOfScreen === AppConstants.WFHD) {
				chart.setTitle({ text: title, x: -110, y: 120 });
			} else if (resolutionOfScreen === AppConstants.HALF_WUHD) {
				chart.setTitle({ text: title, x: -110, y: 105 });
			} else if (resolutionOfScreen === AppConstants.FULL_HD) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -110, y: 105 });
				} else if (this.lang === "ru") {
					chart.setTitle({ text: title, x: -100, y: 105 });
				} else {
					chart.setTitle({ text: title, x: -90, y: 105 });
				}
			} else if (resolutionOfScreen === AppConstants.WUXGA) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -110, y: 105 });
				} else {
					chart.setTitle({ text: title, x: -140, y: 115 });
				}
			} else {
				chart.setTitle({ text: title, x: -470, y: 250 });
			}
		} else if (title === this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_BRANCH_STATUS')) {
			if (resolutionOfScreen === AppConstants.WUHD) {
				chart.setTitle({ text: title, x: -175, y: 245 });
			} else if (resolutionOfScreen === AppConstants.NORMAL) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -80, y: 155 });
				} else {
					chart.setTitle({ text: title, x: -155, y: 155 });
				}
			} else if (resolutionOfScreen === AppConstants.HALF_WFHD) {
				chart.setTitle({ text: title, x: -160, y: 160 });
			} else if (resolutionOfScreen === AppConstants.WFHD) {
				chart.setTitle({ text: title, x: -90, y: 120 });
			} else if (resolutionOfScreen === AppConstants.HALF_WUHD) {
				chart.setTitle({ text: title, x: -100, y: 105 });
			} else if (resolutionOfScreen === AppConstants.FULL_HD) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -70, y: 105 });
				} else {
					chart.setTitle({ text: title, x: -110, y: 105 });
				}
			} else if (resolutionOfScreen === AppConstants.WUXGA) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -130, y: 150 });
					// options = {
					// 	chart: {
					// 		spacingLeft: 200
					// 	}
					// }
					// chart.update(options);
				} else {
					chart.setTitle({ text: title, x: -115, y: 150 });
				}
			} else {
				chart.setTitle({ text: title, x: -200, y: 250 });
			}
		} else if (title === this.translate.instant('ANALYTICAL_CHARTS.CHART_TITLE_BRANCH_PRIORITY')) {
			if (resolutionOfScreen === AppConstants.WUHD) {
				chart.setTitle({ text: title, x: -63, y: 245 });
			} else if (resolutionOfScreen === AppConstants.NORMAL) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -60, y: 155 });
				} else {
					chart.setTitle({ text: title, x: -63, y: 155 });
				}
			} else if (resolutionOfScreen === AppConstants.HALF_WFHD) {
				chart.setTitle({ text: title, x: -63, y: 160 });
			} else if (resolutionOfScreen === AppConstants.WFHD) {
				chart.setTitle({ text: title, x: -90, y: 120 });
			} else if (resolutionOfScreen === AppConstants.HALF_WUHD) {
				chart.setTitle({ text: title, x: -100, y: 105 });
			} else if (resolutionOfScreen === AppConstants.FULL_HD) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -190, y: 105 });
				} else {
					chart.setTitle({ text: title, x: -40, y: 105 });
				}
			} else if (resolutionOfScreen === AppConstants.WUXGA) {
				if (this.lang === "ar") {
					chart.setTitle({ text: title, x: -120, y: 150 });
					// options = {
					// 	chart: {
					// 		spacingLeft: 200
					// 	}
					// }
					// chart.update(options);
				} else {
					chart.setTitle({ text: title, x: -120, y: 150 });
				}
			} else {
				chart.setTitle({ text: title, x: -200, y: 250 });
			}
		}
		else {
			if (resolutionOfScreen === AppConstants.WUHD) {
				chart.setTitle({ text: title, x: -75, y: 250 });
			} else if (resolutionOfScreen === AppConstants.NORMAL) {
				chart.setTitle({ text: title, x: -60, y: 155 });
			} else if (resolutionOfScreen === AppConstants.HALF_WFHD) {
				chart.setTitle({ text: title, x: -60, y: 160 });
			} else if (resolutionOfScreen === AppConstants.WFHD) {
				chart.setTitle({ text: title, x: 10, y: 120 });
			} else if (resolutionOfScreen === AppConstants.HALF_WUHD) {
				chart.setTitle({ text: title, x: -10, y: 105 });
			} else if (resolutionOfScreen === AppConstants.FULL_HD) {
				chart.setTitle({ text: title, x: -10, y: 105 });
			} else if (resolutionOfScreen === AppConstants.WUXGA) {
				chart.setTitle({ text: title, x: -10, y: 105 });
			} else {
				chart.setTitle({ text: title, x: 0, y: 250 });
			}
		}
		chart.series[0].setData(seriesData);

		if (chartOptions.hasOwnProperty('titleTooltip')) {
			this.showTooltipOnTitle(containerId, () => {
				return chartOptions.titleTooltip;
			});
		}
		return chart;
	}

	/**
	 * called to render KPI chart
	 */
	renderKPIChart(containerId: string, title?: string, seriesData?: []) {
		const options: any = this.buildKPIChartsOptions();
		const chart = charts.chart(containerId, options);
		chart.setTitle({ text: title });
		// chart.series[1].setData(seriesData);
		return chart;
	}

}
