import { Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  startDateSelected: string = '';
  endDateSelected: string = '';
  @ViewChild('picker') picker!: MatDateRangePicker<Date>;
  @Output() dateRangeSelected = new EventEmitter<{ startDate: number, endDate: number }>();
  @Input() resetDatePicker: boolean = false;

  constructor() {
    const currentDate = new Date();
    this.maxDate = currentDate;
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resetDatePicker'] && this.resetDatePicker) {
      this.resetDates();
    }
  }

  resetDates() {
    this.startDateSelected = '';
    this.endDateSelected = '';
    if (this.picker) {
      this.picker.select(null);
    }
  }

  onStartDateChange(event: any): void {
    this.startDateSelected = event.value;
    this.endDateSelected = '';
  }

  onEndDateChange(event: any): void {
    this.endDateSelected = event.value;
    this.emitDateRange();
  }

  emitDateRange() {
    if (this.startDateSelected && this.endDateSelected) {
      const startDate = moment(this.startDateSelected)
        .startOf('day')
        .valueOf();

      const endMoment = moment(this.endDateSelected);
      const isToday = endMoment.isSame(moment(), 'day');

      const endDate = isToday
        ? moment().valueOf()
        : endMoment.endOf('day').valueOf();

      this.dateRangeSelected.emit({ startDate, endDate });
    }
  }

  openDatePicker(): void {
    this.picker.open();
  }
}
