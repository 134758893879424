import { Component, OnInit, OnDestroy, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-top-sites',
  templateUrl: './top-sites.component.html',
  styleUrls: ['./top-sites.component.scss'],
})
export class TopSitesComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService
  ) { }

  dynamicWidth: number = 200;
  dynamicHeight: number = 200;
  dynamicRadius: number = 90;
  arcSize: number = 25;
  pieData = [];
  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  async ngOnInit() {
    this.listenToDateRangeChanges();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getTopSites(this.startDate, this.endDate);
    });
  }

  async getTopSites(startDate?: number, endDate?: number) {
    let res = await this.insightsService.getTopSitesRaisingAlarms(startDate, endDate);
    if (res && res.success === true && res.data.results && res.data.results.length > 0) {
      this.pieData = res.data.results.map(site => ({
        label: site.branchName,
        value: site.currentPeriodAlarmsCount,
        percentage: site.rate === null ? 100 : site.rate
      })).sort((a, b) => b.value - a.value);
      this.cdr.detectChanges();
    } else {
      this.pieData = [];
    }
  }

  ngAfterViewInit() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  private updateChartWidth() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    if (screenWidth > 3000 && screenHeight >= 1080) {
      this.dynamicWidth = 400;
      this.dynamicHeight = 368;
      this.dynamicRadius = 130;
      this.arcSize = 35;
    }
    else if (screenHeight >= 1024) {
      this.dynamicWidth = 200;
      this.dynamicHeight = 240;
      this.dynamicRadius = 90;
      this.arcSize = 25;
    }
    else {
      this.dynamicWidth = 200;
      this.dynamicHeight = 245;
      this.dynamicRadius = 90;
      this.arcSize = 25;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }

}
