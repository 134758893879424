import { Component, OnInit, Input } from '@angular/core';
import { formatValue } from 'src/app/utils/commonfunctions';
interface severityData {
  label: string;
  value: number;
}
@Component({
  selector: 'app-alarm-severity',
  templateUrl: './alarm-severity.component.html',
  styleUrls: ['./alarm-severity.component.scss'],
})
export class AlarmSeverityComponent implements OnInit {
  @Input() data: severityData[] = [{ label: '', value: 0 }];
  constructor() {}

  ngOnInit(): void {}

  formatSeverityValue(value: number) {
    return formatValue(value);
  }
}
