import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AppConstants } from '../constants/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { SpeechService } from '../services/speech.service';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AlarmService } from '../services/alarm.service';
import { QuestionService } from '../workflow/question/question.service';

@Component({
	selector: 'app-escalation-confirmation',
	templateUrl: './escalation-confirmation.component.html',
	styleUrls: ['./escalation-confirmation.component.scss']
})
export class EscalationConfirmationComponent implements OnInit {
	@Input() title: string = null;
	@Input() header: string = null;
	@Input() calledFrom: string = null;
	@Output() alarmEscalated = new EventEmitter();
	@Output() assignAlarmToOperator = new EventEmitter();
	@Output() alarmEscalationCancelled = new EventEmitter();
	YES: string;
	NO: string;

	constructor(public translate: TranslateService, private speechService: SpeechService, private questionService: QuestionService, 
		private stateService: UserSessionStateService, private alarmService: AlarmService) { }

	ngOnInit() {
		this.speechService.init();
		this.YES = this.translate.instant('ESCALATION_CONFIRMATION.YES');
		this.NO = this.translate.instant('ESCALATION_CONFIRMATION.NO');
	}

	yesClicked() {
		if (this.calledFrom === AppConstants.WORKFLOW_TEXT) {
			this.alarmEscalated.emit();
			// this.questionService.questionChangedListener.next(null);
		} else {
			this.assignAlarmToOperator.emit();
		}
	}

	noClicked() {
		this.alarmEscalationCancelled.emit();
		const alarmId = this.alarmService.selectedAlarm.id;
		this.stateService.saveDirectEscalationOpenState(alarmId, false);
	}

	async read(data) {
		await this.speechService.read(data);
	}

	async stopRead() {
		await this.speechService.stopRead();
	}

}
