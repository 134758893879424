import { Injectable } from "@angular/core";
import * as socketIo from "socket.io-client";
import { ApiConstants } from "../constants/api-constants";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SocketService {
  public socket: any;

  constructor() { }

  public initSocket(): void {
    if (this.socket) {
      // [SocketService] socket connection is already set up
      return;
    }

    // [SocketService] Establishing socket connection"
    this.socket = socketIo(ApiConstants.API_SERVER, {
      transports: ["polling"],
      secure: true,
      reconnect: true,
      rejectUnauthorized: false
    });
  }

  public onEvent<T>(event: string): Observable<T> {
    if (!this.socket) {
      // [SocketService] Socket is not initilized yet.
      return null;
    }

    return new Observable<T>(observer => {
      // [SocketService] Listening to socket message  [name: " + event + "]"
      this.socket.on(event, data => observer.next(data));
    });
  }

  public closeSocket() {
    if (this.socket) {
      // [SocketService] Closing web socket
      this.socket.close();
      this.socket = null;
    } else {
      // [SocketService] Socket was not initialized
    }
  }
}
