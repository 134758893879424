<div *ngFor="let video of [videoStreamService.videoSourceOne, videoStreamService.videoSourceTwo]; 
let windowNo = index;" class="video-feed">
	<div class="player-view" *ngIf="video.url">
		<div class="video-window-header">
			<select [(ngModel)]="windowVideos[windowNo]" class="video-dropdown"
				(change)="videoStreamService.setVideoSource(windowNo, windowVideos[windowNo])">
				<option *ngFor="let v of videoStreamService.videoFeeds;let i=index" [value]="i">{{ v?.name }}
				</option>
			</select>
			<div class="video-control-bar" [class.disabled]="video.invalidUrl">
				<img class="ctrl-icon" alt="unshare_video" (click)="unShareVideo(windowNo)"
					[title]="'VIDEO_CONTROL.UNSHARE' | translate" [src]="utility.getImagePath('unshare_video.png')" />
				<img class="ctrl-icon" alt="share_video" (click)="shareVideo(windowNo)"
					[title]="'VIDEO_CONTROL.SHARE' | translate" [src]="utility.getImagePath('share_video.png')" />
				<i class="fa fa-hand-pointer-o ctrl-icon" aria-hidden="true" (click)="controlVideo(windowNo,video)"
					[title]="'VIDEO_CONTROL.CONTROL' | translate"></i>
				<img class="ctrl-icon" alt="full_scrn_video" (click)="showFullScreenVideo(video)"
					[title]="'VIDEO_CONTROL.FULL_SCREEN' | translate" [src]="utility.getImagePath('full_scrn_video.png')" />
			</div>
		</div>

		<!-- Iframe/Video player for streams -->
		<div class="iframe_container" *ngIf="!video.invalidUrl">
			<iframe width="100%" height="100%" frameBorder="0" [src]="video.url | safe" scrolling="no"></iframe>
		</div>
		<div *ngIf="video.invalidUrl" class="invalid">{{"VIDEO_FEED.INVALID_URL" | translate}}</div>

	</div>

	<div *ngIf="!video.url">{{"VIDEO_FEED.NO_VIDEO" | translate}}</div>

</div>

<div *ngIf="fullScreenVideo" class="full-screen-player" [ngStyle]="fullScreenVideoStyle">
	<iframe width="100%" height="100%" frameBorder="0" [src]="fullScreenVideo.url | safe" scrolling="no"
		class="full-screen-frame">
	</iframe>

	<nb-icon nbPrefix icon="close-outline" pack="eva" class="close-vid" (click)="closeFullScreenVideo()">
	</nb-icon>
</div>