import { Component, OnInit } from '@angular/core';
import { AnalyticalDashboardService } from 'src/app/services/analytical-dashboard.service';
import { Utility } from 'src/app/utils/app-utils';
import { AppConstants } from 'src/app/constants/app-constants';

@Component({
	selector: 'app-operators-stats-summary',
	templateUrl: './operators-stats-summary.component.html',
	styleUrls: ['./operators-stats-summary.component.scss']
})

export class OperatorsStatsSummaryComponent implements OnInit {
	public operatorsSummary: any = [];
	public statistics = [];
	public selectedTile = null;
	operatorsStatsChangeSubscriber: any;
	constructor(private analyticalDashboard: AnalyticalDashboardService) { }

	ngOnInit() {
		this.operatorsStatsChangeSubscriber = this.analyticalDashboard.getOperatorsStatsChangeNotification().subscribe((isChange) => {
			if (isChange) {
				this.operatorsSummary = this.analyticalDashboard.getOperatorsSummary();
				this.statistics = Utility.formatIntoRows(this.operatorsSummary);
			}
		});
		this.analyticalDashboard.getUpdateNotification().subscribe(() => {
			this.selectedTile = null;
		});
		this.analyticalDashboard.getAllClearNotification().subscribe(() => {
			this.selectedTile = null;
		});
	}

	ngOnDestroy() {
		this.statistics = [];
		this.operatorsStatsChangeSubscriber = Utility.Unsubscribe(this.operatorsStatsChangeSubscriber);
	}

	/**
	 * check tile selected or not
	 */
	isNotActive(item) {
		return !(this.selectedTile === item);
	}

	/**
	 * select or unselect a tile and filter table related to that
	 */
	async setSelectedTile(alarmsName) {
		this.analyticalDashboard.SelectedStatusesForTile = [];
		if (this.selectedTile !== null && this.selectedTile !== alarmsName) {
			this.analyticalDashboard.SelectedStatusesForTile.push(alarmsName);
			this.filterOptionsParams(this.selectedTile);
		} else if (this.selectedTile === alarmsName) {
			this.filterOptionsParams(alarmsName);
		} else {
			this.analyticalDashboard.SelectedStatusesForTile.push(alarmsName);
		}
		this.selectedTile = this.selectedTile === alarmsName ? null : alarmsName;
		await this.analyticalDashboard.filterOperatorsReportList(this.analyticalDashboard.operatorsFilterParams);
	}

	filterOptionsParams(filterBy: string) {
		this.analyticalDashboard.operatorsFilterParams.statuses = (this.analyticalDashboard.operatorsFilterParams.statuses || []).filter(alarmStatus => alarmStatus !== filterBy);
		this.analyticalDashboard.operatorsFilterParams.statuses = this.analyticalDashboard.operatorsFilterParams.statuses.length ? this.analyticalDashboard.operatorsFilterParams.statuses : null;
	}

}
