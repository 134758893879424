<div class="full-height">
	<div class="full-height filters-and-table-wrapper">

		<!-- <div class="filter-content"> -->
		<!-- <app-alarms-filter [filterType]="filterType"
				(changeHeight)="changeTableSize()"></app-alarms-filter> -->
		<!-- </div> -->

		<app-sites-table (selectedBranchEvent)="changeSelectedBranch($event)"
			[displayedColumns]="displayedColumns"></app-sites-table>

	</div>
	<div class="sites-map">
		<div class="sites-asset-action">
			<button mat-raised-button class="filter-btn action"
				(click)="changeSitesAsset(analyticalDashboard.sitesAsset.VIDEO)">
				<i class="fa fa-youtube-play" aria-hidden="true"></i>
				{{ "ANALYTICAL_ALARM_HISTORY.VIDEO" | translate }}
			</button>
			<button mat-raised-button class="filter-btn action"
				(click)="changeSitesAsset(analyticalDashboard.sitesAsset.MAP)">
				<i class="fa fa-map-marker" aria-hidden="true"></i>
				{{ "ANALYTICAL_ALARM_HISTORY.Map" | translate }}
			</button>
			<button mat-raised-button class="filter-btn action"
				(click)="changeSitesAsset(analyticalDashboard.sitesAsset.ASSET_DOCS)">
				<i class="fa fa-file-text-o" aria-hidden="true"></i>
				{{ "ANALYTICAL_ALARM_HISTORY.ASSET_DOCS" | translate }}
			</button>

			<button mat-raised-button class="filter-btn action"
				(click)="changeSitesAsset(analyticalDashboard.sitesAsset.ADD_ALARM)">
				<i class="fa fa-plus" aria-hidden="true"></i>
				{{ "ADD_ALARM.HEADER" | translate }}
			</button>

		</div>
		<div class="asset-div">
			<app-map (doneLoading)="mapLoaded()"
				[hidden]="analyticalDashboard.chartSelected === analyticalDashboard.chartType.SITES && analyticalDashboard.selectedSitesAsset !== analyticalDashboard.sitesAsset.MAP">
			</app-map>
			<app-sites-asset-docs class="analytical-sites-asset-docs"
				*ngIf="analyticalDashboard.chartSelected === analyticalDashboard.chartType.SITES && analyticalDashboard.selectedSitesAsset === analyticalDashboard.sitesAsset.ASSET_DOCS"
				[selectedBranch]="selectedBranch">
			</app-sites-asset-docs>
			<app-sites-video-feeds
				*ngIf="analyticalDashboard.chartSelected === analyticalDashboard.chartType.SITES && analyticalDashboard.selectedSitesAsset === analyticalDashboard.sitesAsset.VIDEO"
				[selectedBranch]="selectedBranch">
			</app-sites-video-feeds>
			<app-supervisor-add-alarm class="add-alarm"
				*ngIf="analyticalDashboard.chartSelected === analyticalDashboard.chartType.SITES && analyticalDashboard.selectedSitesAsset === analyticalDashboard.sitesAsset.ADD_ALARM"
				[supervisor_selectedBranch]="selectedBranch">
			</app-supervisor-add-alarm>
		</div>

	</div>
</div>