<div class="chart-block charttwo-block radius-15 box-border d-flex flex-column flex-grow-1">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Operator Ranking</h2>
      <app-popover [title]="'Operator ranking'"
        [content]="'Top operators based on number of alarms acknowledged'"></app-popover>
    </div>
  </div>
  <div class="chart-wrap" *ngIf="tableData?.values?.length > 0">
    <app-operator-ranking-table [data]="tableData"></app-operator-ranking-table>
  </div>
  <div *ngIf="tableData?.values?.length === 0"
    class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>