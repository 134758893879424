import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../utils/app-utils';
import { VideoStreamService } from '../services/video-stream.service';
import { UserSessionStateService } from '../services/user-session-state.service';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { Alarm } from 'src/models/alarm.model';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
	selectedAlarmId: string;
	public id: any;
	public assetsDocs: any;
	public assetDoc: Subject<any> = new Subject();
	public assetDocSelectedChanged = false;
	getThermalStateSubscriber: Subscription;
	getThermalSubscriber: boolean;
	windowOneVideo = 0;
	windowTwoVideo = 1;
	utility = Utility;
	isThermal: boolean = false;
	selectedAlarmSubscriber: Subscription;
	selectedAlarm: Alarm;

	constructor(private alarmService: AlarmService,
		public videoStreamService: VideoStreamService,
		public authService: AuthenticationService,
		public translate: TranslateService,
		public userSessionStateService: UserSessionStateService,
		private locationStrategy: LocationStrategy
	) { }

	ngOnInit() {
		this.getThermalSubscriber = this.userSessionStateService.getThermalState();
		this.selectedAlarmListener();

		this.getThermalStateSubscriber = this.userSessionStateService.getThermalStateChangeNotification().subscribe((state) => {
			this.getThermalSubscriber = state;
			if (this.alarmService.selectedAlarm) {
				this.selectedAlarmId = this.alarmService.selectedAlarm.id;
			}
			this.isThermal = this.getThermalSubscriber;
		});

		this.preventBackButton();
	}

	selectedAlarmListener() {
		this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			if (alarm) {
				this.selectedAlarm = alarm;
			}
		});
	}

	ngOnDestroy() {
		this.getThermalStateSubscriber = Utility.Unsubscribe(this.getThermalStateSubscriber);
		this.selectedAlarmSubscriber = Utility.Unsubscribe(this.selectedAlarmSubscriber);
	}

	// Disable back button
	preventBackButton() {
		history.pushState(null, null, location.href);
		this.locationStrategy.onPopState(() => {
			history.pushState(null, null, location.href);
		})
	}

}
