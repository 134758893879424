import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from './constants/api-constants';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AppConfigService {

	appConfig: any;

	constructor(private http: HttpClient) { }

	async loadAppConfig() {
		return firstValueFrom(this.http.get('../assets/config/config.json'))
			.then(data => {
				this.appConfig = data;
				ApiConstants.setData(this.appConfig.API_SERVER);
			});
	}
}
