<div class="video-container">
	<mat-sidenav-container>
		<!--navbar main container -->
		<mat-sidenav-content class="navbar-main">
			<button mat-button (click)="sidenav.toggle()" class="navbar-toggle">
				<i class="fa fa-bars fa-lg navbar-toggle-icon" aria-hidden="true"></i>
			</button>
			<div class="video-feed" *ngIf="selectedVideoFeed !== null">
				<div class="player-view" *ngIf="videoStreamService.videoSourceOne.url">
					<div class="video-control-bar" *ngIf="false">
						<img class="ctrl-icon" alt="unshare_video" (click)="unShareVideo()"
							[src]="utility.getImagePath('unshare_video.png')" />
						<img class="ctrl-icon" alt="share_video" (click)="shareVideo()"
							[src]="utility.getImagePath('share_video.png')" />
						<img class="ctrl-icon" alt="ctrl_video" (click)="controlVideo()"
							[src]="utility.getImagePath('ctrl_video.png')" />
					</div>
					<!-- Iframe/Video player for streams -->
					<div class="sites-video-player" *ngIf="!videoStreamService.videoSourceOne.invalidUrl">
						<iframe width="100%" height="100%" frameBorder="0" [src]="videoStreamService.videoSourceOne.url | safe"	scrolling="no"></iframe>
					</div>
					<div *ngIf="videoStreamService.videoSourceOne.invalidUrl" class="no-video">
						{{"VIDEO_FEED.INVALID_URL" | translate}}
					</div>
				</div>

			</div>
			<div *ngIf="!videoStreamService.videoSourceOne.url" class="no-video">
				{{"VIDEO_FEED.NO_VIDEO" | translate}}
			</div>
		</mat-sidenav-content>

		<!-- navbar side container -->
		<mat-sidenav #sidenav opened="true" mode="over" position="end" class="navbar-side">

			<div class="video-list">
				<h1>
					<i (click)="sidenav.toggle()" class="fa fa-arrow-right fa-md navbar-toggle-icon nav-close-icon"
						aria-hidden="true"></i>
					<span class="title">
						{{"ANALYTICAL_ALARM_HISTORY.VIDEOS" | translate}}
					</span>
				</h1>
				<ul>
					<li *ngFor="let video of videoFeeds; let videoNo = index;" (click)="onVideoSelect(videoNo)" [value]="videoNo">
						<div class="asset-li" [class.selected]="videoNo == selectedVideoFeedNo">
							<a>
								<p class="asset-name">{{ video.name }}</p>
							</a>
						</div>
					</li>
				</ul>
			</div>

		</mat-sidenav>

	</mat-sidenav-container>
</div>