<div
  class="chart-block chartfour-block radius-15 box-border d-flex justify-content-between align-items-center gap-5x flex-grow-1">
  <div class="block-head-alarm-rate d-flex align-items-center">
    <h2 class="title text-white">Alarms By Severity</h2>
    <app-popover [title]="'Alarms by severity'" [content]="'Number of alarms based on severity'"></app-popover>
  </div>
  <div class="chart-wrap d-flex justify-content-between align-items-center flex-column" *ngIf="data?.length > 0">
    <div class="chart-value d-flex border-0 flex-wrap custom-width justify-content-between">
      <div *ngFor="let severity of data">
        <div class="item-value text-center d-flex align-items-center">
          <span>{{ severity.label }}</span>
          <p class="paragraph-text fw-700">
            {{ severity.value }}
          </p>
          <div class="down" *ngIf="severity.value !== 0">
            <img [src]="severity.value > 0 ? 'assets/images/uparrow.svg' : 'assets/images/downarrow.svg'"
              [alt]="severity.value > 0 ? 'Up' : 'Down'" class="arrow-img" title="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="data?.length === 0" class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>