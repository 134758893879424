import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConstants } from '../constants/api-constants';
import { ApiResponse } from '../../models/api-response.model';
import { VideoFeed } from '../../models/video-feed.model';
import { Subject, lastValueFrom } from 'rxjs';
import { VncDialogComponent } from '../vnc-dialog/vnc-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../analytical-dashboard/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from './authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class VideoStreamService {
	videoSourceBranchSubj = new Subject<any>();
	videoSourceZoneSubj = new Subject<any>();
	public videoFeeds: VideoFeed[] = [];
	public videoSourceOne: VideoFeed = this.getEmptyVideoSource();
	public videoSourceTwo: VideoFeed = this.getEmptyVideoSource();

	public userChannels: number[] = [];
	sourceOneUrl: any;
	sourceTwoUrl: any;
	sourceOneIp: any;
	sourceTwoIp: any;
	sourceOneName: any;
	sourceTwoName: any;
	token: string;
	fullScreenVideo: boolean = false;

	constructor(private http: HttpClient, public dialog: MatDialog, public translate: TranslateService,
		public authService: AuthenticationService, public _snackBar: MatSnackBar) { }

	setToken() {
		this.token = this.authService.getAuthToken();
	}

	videoSourceBranchNotification() {
		return this.videoSourceBranchSubj.asObservable();
	}

	playVideoFeedsFromBranch(branchId: string) {
		this.videoSourceBranchSubj.next(branchId);
	}

	videoSourceZoneNotification() {
		return this.videoSourceZoneSubj.asObservable();
	}

	playVideoFeedsFromZones(sources: any) {
		if (sources && sources.length > 0) {
			this.videoSourceZoneSubj.next(sources);
		} else {
			this.setEmptyVideoSources();
			this.videoSourceZoneSubj.next(null);
		}
	}

	async setEmptyVideoSources() {
		this.videoSourceOne = this.getEmptyVideoSource();
		this.videoSourceTwo = this.getEmptyVideoSource();
	}

	// async fetchVideoSourcesList(branchId: string) {
	// Issue 419 limit rse usage by setting only one video source
	async fetchVideoSourcesList(branchId: string, setOnlyOneSource?: boolean) {
		this.videoFeeds = [];
		await this.fetchUserChannelList();
		const URL = ApiConstants.URL_LIST_VIDEO_SOURCES + '/' + branchId;
		const response = await lastValueFrom(this.http.get<ApiResponse>(URL));
		if (response.success && response.data && response.data.length > 0) {
			this.videoFeeds = response.data;
			try {
				if (this.videoFeeds.length >= 2) {
					await this.setVideoSourceOne(0);
					if (setOnlyOneSource !== true) {
						await this.setVideoSourceTwo(1);
					}
				} else if (this.videoFeeds.length === 1) {
					await this.setVideoSourceOne(0);
					this.videoSourceTwo.url = null;
				}
			} catch (error) { }
		}
		else if (response.success && response.data && response.data.length === 0) {
			this.videoFeeds = [];
			this.videoSourceOne.url = null;
			this.videoSourceTwo.url = null;
		}
		return this.videoFeeds;
	}

	async fetchUserChannelList(): Promise<number[]> {
		if (this.userChannels.length > 0) {
			return this.userChannels;
		}

		const URL = ApiConstants.URL_USER_VIDEO_CHANNELS;
		const response = await lastValueFrom(this.http.get<ApiResponse>(URL));

		let channels: number[] = [];
		if (response.success && response.data && response.data.length > 0) {
			channels = response.data;
		}

		this.userChannels = channels;
		return channels;
	}

	/**
	 * Share video
	 */
	async shareVideo(sourceName: any, alarmType: any) {
		const URL = ApiConstants.URL_VIDEO_SHARE
		const response = await lastValueFrom(this.http.post<ApiResponse>(URL, { "sourceName": sourceName, "alarmType": alarmType }));
		return !!response && response.success;
	}

	/**
	 * Unshare video
	 */
	async unShareVideo() {
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_VIDEO_UN_SHARE, {
		}));
		return !!response && response.success;
	}

	/**
	 * Control video
	 */
	async controlVideo(deviceIp: any) {
		const URL = ApiConstants.URL_VIDEO_CONTROL
		const response = await lastValueFrom(this.http.post<ApiResponse>(URL, { "deviceIP": deviceIp }));
		if (response.success === true) {
			this.dialog.open(VncDialogComponent, {
				height: '50%',
				width: '36%',
				disableClose: true,
				data: response.data.url,
				panelClass: 'custom-dialog',
				position: {
					bottom: '30px',
					right: '30px'
				}
			});
		} else {
			const title = this.translate.instant('VIDEO_CONTROL.WARNING_TITLE');
			let message = this.translate.instant('VIDEO_CONTROL.WARNING_MESSAGE');
			const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
			const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
			if (this.fullScreenVideo) {
				this.openSnackBar(message, confirmText);
			} else {
				const typeOfDialogBox = 2;
				const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
				this.dialog.open(ConfirmDialogComponent, {
					data: dialogData,
					disableClose: true
				});
			}
		}

		return !!response && response.success;
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

	async setVideoSource(windowNo: number, videoNo: number) {
		if (windowNo === 0) {
			this.setVideoSourceOne(videoNo);
		} else {
			this.setVideoSourceTwo(videoNo);
		}
	}

	async setVideoSourceOne(videoNo: number) {
		const videoChannelOne = this.userChannels[0];
		const videoUrl = this.videoFeeds[videoNo].url || '';
		const videoIp = this.videoFeeds[videoNo].ip || '';
		const videoName = this.videoFeeds[videoNo].name || '';
		this.sourceOneUrl = videoUrl;
		this.sourceOneIp = videoIp;
		this.sourceOneName = videoName;
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.START_STREAM_V2,
			[{ chNo: videoChannelOne, url: videoUrl }]));
		if (response.success && response.data && response.data.length > 0) {
			const source = response.data || this.getEmptyVideoSource();
			this.videoSourceOne.url = source;
			// Handle wrong configured url Issue #468
			response.data[0] === '__INVALID_URL__' ? this.videoSourceOne.invalidUrl = true : this.videoSourceOne.invalidUrl = false;
		}
	}

	async setVideoSourceTwo(videoNo: number) {
		const videoChannelTwo = this.userChannels[1];
		const videoUrl = this.videoFeeds[videoNo].url || '';
		const videoIp = this.videoFeeds[videoNo].ip || '';
		const videoName = this.videoFeeds[videoNo].name || '';
		this.sourceTwoUrl = videoUrl;
		this.sourceTwoIp = videoIp;
		this.sourceTwoName = videoName;
		const response = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.START_STREAM_V2,
			[{ chNo: videoChannelTwo, url: videoUrl }]));
		if (response.success && response.data && response.data.length > 0) {
			const source = response.data || this.getEmptyVideoSource();
			this.videoSourceTwo.url = source;
			response.data[0] === '__INVALID_URL__' ? this.videoSourceTwo.invalidUrl = true : this.videoSourceTwo.invalidUrl = false;
		}
	}

	getEmptyVideoSource() {
		const dummyVideoFeed: VideoFeed = {
			branch: '',
			name: '',
			url: '',
			ip: ''
		};

		return dummyVideoFeed;
	}

	async fetchVideoSourcesList_zones(sources: any) {
		await this.fetchUserChannelList();
		if (sources) {
			try {
				this.videoFeeds = sources;
				if (this.videoFeeds.length >= 2) {
					await this.setVideoSourceOne(0);
					await this.setVideoSourceTwo(1);
				} else if (this.videoFeeds.length === 1) {
					await this.setVideoSourceOne(0);
					this.videoSourceTwo.url = null;
				}
			} catch (error) {
				//
			}
			return this.videoFeeds;
		}

	}

	// Not used in RSE_V2 - Stream will stop automatically when tab lost focus.
	async stopStream(): Promise<boolean> {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_STOP_STREAM, {
			headers: new HttpHeaders().set('session_token', this.token)
		}));
		return !!response && response.success;
	}

}
