import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from 'src/models/api-response.model';
import { AuthenticationService } from './authentication.service';
import { SnackbarUtil } from '../utils/snackbar-util';

@Injectable({
	providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

	constructor(private authService: AuthenticationService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.authService.getAuthToken();
		if (token) {
			request = request.clone({
				headers: request.headers.set('session_token', token)
			});
			return next.handle(request).pipe(
				tap(evt => this.handleSuccess(evt)),
				catchError(err => this.handleAuthError(err))
			);
		}
		return next.handle(request);
	}

	handleSuccess(event: any) {
		if (event instanceof HttpResponse) {
			if (event.body && event.body.success === false) {
				SnackbarUtil.showSnackBar(((event.body) as ApiResponse).errorMessages);
			}
		}
	}

	handleAuthError(err: HttpErrorResponse): Observable<any> {
		if (err.status === 401 || err.status === 403) {
			this.authService.logout();
		} else {
			console.log('err', err.status, err.statusText);
			SnackbarUtil.showSnackBar(err);
		}
		return of(err.message);
	}
}
