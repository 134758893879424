import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../constants/route-constants';
import { AlarmService } from '../services/alarm.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public session: any;
	constructor(
		private authenticationService: AuthenticationService,
		public translate: TranslateService,
		private router: Router,
		public alarmService: AlarmService) { }

	ngOnInit() {
		this.redirectToDashboard();
		this.alarmService.startListeningToNewAlarms();
	}

	ngOnDestroy() { }

	/**
	 * Redirect to home after getting authenticated user details.
	 */
	redirectToDashboard() {
		const timeout = setInterval(() => {
			this.authenticationService.getUser().subscribe(
				(response) => {
					if (response[AppConstants.SUCCESS] === true) {
						this.session = response[AppConstants.SESSION];
						this.authenticationService.setSession(this.session);
						clearInterval(timeout);
						this.router.navigate([RouteConstants.PAGES + RouteConstants.DASHBOARD], { skipLocationChange: false });

						// Logic to load same page on refresh/reload.
						// if (this.router.url === (RouteConstants.PAGES + RouteConstants.HOME)) {
						// 	this.router.navigate([RouteConstants.PAGES + RouteConstants.DASHBOARD], { skipLocationChange: false });
						// }
						// else {
						// 	this.router.navigate([this.router.url]);
						// }
					}
				},
				(error) => {
					// error while getting auth user details.
				}
			);
		}, 100);
	}
}
