import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlarmService } from '../services/alarm.service';
import { Alarm } from 'src/models/alarm.model';
import { ricHandleStatus } from '../enums/ric-handle-status';

@Component({
  selector: 'app-alarm-tabs',
  templateUrl: './alarm-tabs.component.html',
  styleUrls: ['./alarm-tabs.component.scss']
})
export class AlarmTabsComponent implements OnInit {
  selectedTab: string = 'progress';
  @Output() tabSelected = new EventEmitter<string>();

  selectedAlarmSubscriber: Subscription;
  chatBotSelectedSubscriber: Subscription;
  public isContainZone: boolean = false;
  selectedAlarm: Alarm;
  isWorkflowHidden: boolean = false;
  hiddenWorkflowSub: Subscription;
  isDisabledWorkflow: boolean = false;
  disabledWorkflowSub: Subscription;

  constructor(public alarmService: AlarmService) { }

  ngOnInit(): void {
    this.selectedAlarmListener();
    this.chatBotSelectedListener();

    this.hiddenWorkflowSub = this.alarmService.getIsWorkflowHidden().subscribe((isHidden: boolean) => {
      this.isWorkflowHidden = isHidden;
    });

    this.disabledWorkflowSub = this.alarmService.getRicUpdates().subscribe((alarm: Alarm) => {
      if (alarm._id === this.selectedAlarm.id) {
        this.updateWorkflowStatus(alarm.ricHandleStatus);
      }
    });
  }

  selectedAlarmListener() {
    this.selectedAlarmSubscriber = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
      this.selectedAlarm = alarm;
      if (alarm) {
        // this.selectTab('progress');
        this.isContainZone = this.alarmService.isContainZone(alarm);
        this.isDisabledWorkflow = true;
        this.updateWorkflowStatus(alarm.ricHandleStatus);
        if (this.selectedTab === 'workflow') {
          this.selectTab('progress');
        }
      } else {
        this.selectTab('map');
      }
    });
  }

  private updateWorkflowStatus(status: ricHandleStatus) {
    this.isDisabledWorkflow = [
      ricHandleStatus.STARTED,
      ricHandleStatus.PENDING,
      ricHandleStatus.RUNNING
    ].includes(status);
  }

  chatBotSelectedListener() {
    this.chatBotSelectedSubscriber = this.alarmService.getChatBotSelectedListener().subscribe((selected: boolean) => {
      if (selected) {
        this.selectTab('chatbot');
      }
    });
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
    this.tabSelected.emit(this.selectedTab);
  }

  ngOnDestroy(): void {
    this.selectedAlarmSubscriber.unsubscribe();
    this.chatBotSelectedSubscriber.unsubscribe();
    this.hiddenWorkflowSub.unsubscribe();
    this.disabledWorkflowSub.unsubscribe();
  }

}
