<div class="report-details">
	<div class="header">
		<div class="box-title">
			<div class="d-flex align-items-center">
				<i class="fa fa-bars" aria-hidden="true"></i>
			</div>
			<div class="table_title">
				{{ "REPORT_DETAIL.TITLE" | translate}}
			</div>
		</div>
	</div>
	<div class="table-container">
		<table mat-table aria-describedby="report-details" matSort (matSortChange)="sortData($event)"
			[dataSource]="dataSource">
			<ng-container matColumnDef="alarmId">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.ALARM_ID" | translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.shortAlarmId || element.alarmId}}</td>
			</ng-container>
			<ng-container matColumnDef="branchName">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.BRANCH_NAME" | translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.branch.branchName}} </td>
			</ng-container>
			<ng-container matColumnDef="areaName">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.AREA_NAME" |translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.branch.areaName}} </td>
			</ng-container>
			<ng-container matColumnDef="latitude">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.LATITUDE" | translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.branch.location.latitude}} </td>
			</ng-container>
			<ng-container matColumnDef="longitude">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.LONGITUDE" | translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.branch.location.longitude}} </td>
			</ng-container>
			<ng-container matColumnDef="alarmType">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.ALARM_TYPE" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.alarmType}} </td>
			</ng-container>
			<ng-container matColumnDef="time">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.ACTIVATION_TIME" |
					translate }}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element"> {{getGmtTime(element.activationTime)}} </td>
			</ng-container>
			<!-- Last Action Time Column -->
			<ng-container matColumnDef="lastActionTime">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.LAST_ACTION_TIME" |
					translate }}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{getGmtTime(element.lastactiontime)}} </td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.STATUS" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">{{element.status}} </td>
			</ng-container>
			<ng-container matColumnDef="escalatedBy">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.ESCALATED_BY" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">
					{{ getUserName(element.escalatedBy.firstName, element.escalatedBy.lastName)}} </td>
			</ng-container>
			<ng-container matColumnDef="escalatedTo">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.ESCALATED_TO" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">
					{{ getUserName(element.escalatedTo.firstName, element.escalatedTo.lastName)}} </td>
			</ng-container>
			<ng-container matColumnDef="verifiedBy">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.VERIFY_BY" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">
					{{getUserName(element.verifiedBy.firstName, element.verifiedBy.lastName) }} </td>
			</ng-container>
			<ng-container matColumnDef="verifiedFrom">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.VERIFY_FROM" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">
					{{getUserName(element.verifiedFrom.firstName, element.verifiedFrom.lastName) }} </td>
			</ng-container>
			<ng-container matColumnDef="resolvedBy">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>{{ "REPORT_DETAIL.RESOLVED_BY" |
					translate}}
					<i class="fa fa-sort sorting-logo" aria-hidden="true"></i>
				</th>
				<td mat-cell *matCellDef="let element">
					{{getUserName(element.resolvedBy.firstName,element.resolvedBy.lastName)}}
				</td>
			</ng-container>
			<ng-container matColumnDef="report">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
				</th>
				<td mat-cell *matCellDef="let element">
					<a [href]="" (click)="getAlarmReport(element.alarmId)" class="report-icon">
						<i class="fa fa-file-text table-icon" aria-hidden="true"></i>
					</a>
				</td>
			</ng-container>

			<ng-container matColumnDef="viewDetails">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.VIEW_DETAILS" | translate}}</th>
				<td mat-cell *matCellDef="let element">
					<a [href]="" (click)="getAlarmDetails(element.alarmId)" class="info-icon">
						<i class="fa fa-eye table-icon" aria-hidden="true"></i>
					</a>
				</td>
			</ng-container>

			<ng-container matColumnDef="info">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.ALARM_INFO" | translate }}
				</th>
				<td mat-cell class="alarmInfo-column" *matCellDef="let element" [matTooltip]="element?.info"
					matTooltipPosition="below" [matTooltipClass]="'my-tooltip'">{{getAlarmInfo(element.info)}}</td>
			</ng-container>

			<ng-container matColumnDef="chat">
				<th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>
					{{ "REPORT_DETAIL.CHAT" | translate}}</th>
				<td mat-cell *matCellDef="let element">
					<a [href]="" (click)="getChatHistory(element)" class="info-icon">
						<i class="fa fa-commenting table-icon" aria-hidden="true"></i>
					</a>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
	<mat-paginator [hidden]="!dataSource" class="report-paginator" [length]="reportService.alarmCount"
		[pageSizeOptions]="appConstants.PAGINATION_OPTION" (page)="onPaginateChange($event)" showFirstLastButtons>
	</mat-paginator>
</div>