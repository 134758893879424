<div class="content">
  <table aria-describedby="options">
    <tr class="content-row">
      <!-- <td class="col-4">
            <mat-form-field appearance="fill">
              <mat-label>{{ "ADD_ALARM.SELECT_BRANCH" | translate }}</mat-label>
              <mat-select [(ngModel)]="selectedBranch" name="selectedBranch" (selectionChange)="onBranchChange($event)">
                <mat-option *ngFor="let branch of branches" [value]="branch._id">
                  <span>{{branch.branchName}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td> -->
      <td class="send-selection">
        <mat-form-field appearance="fill">
          <mat-label>{{ "ADD_ALARM.SELECT_ALARM_TYPE" | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedAlarmType" name="selectedAlarmType"
            (selectionChange)="onAlarmTypeChange($event)" [disabled]="isSelectedBranch === false">
            <mat-option *ngFor="let type of alarmTypes" [value]="type._id">
              <span>{{type.alarmType}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td class="send-selection">
        <mat-form-field appearance="fill">
          <mat-label>{{ "ADD_ALARM.SELECT_SEVERITY" | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedSeverity" name="selectedSeverity"
            (selectionChange)="onSeverityChange($event)">
            <mat-option *ngFor="let severity of severityTypes" [value]="severity.id">
              <span>{{severity.name}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>

    <tr class="content-row">
      <div class="heading">{{"COMMENT_FORM.TITLE" | translate}} :</div>
    </tr>
    <tr class="content-row">
      <textarea class="info-input" rows="5" [(ngModel)]="comment"
        [placeholder]="'COMMENT_FORM.PLACEHOLDER' | translate">
      </textarea>
    </tr>

    <tr class="content-row justify-content-center">
      <div class="d-flex buttons-section">
        <button class="btn btn-info" (click)="addAlarm()"
          [disabled]="!isSelectedBranch || !isSelectedAlarmType || !isSelectedSeverity">
          {{ "ADD_ALARM.BUTTON_LABEL" | translate }}
        </button>
      </div>
    </tr>

  </table>
</div>