<div class="progress-container">
  <p class="progress-header">
    Alarm Progress
  </p>

  <svg>
    <!-- Straight line across the middle with a solid color -->
    <line x1="0" y1="100" x2="100%" y2="100" 
      stroke="#505050" 
      stroke-width="5" />

    <!-- Central dots on the line -->
    <circle *ngFor="let step of steps; let i = index"
      [attr.cx]="getXCoordinate(i)"
      cy="100"
      r="5"
      [attr.stroke]="step.strokeColor"
      stroke-width="4"
      fill="white"
      [attr.opacity]="step.completed ? '1' : '0.3'"
    />

    <!-- Circles for each step above or below the line -->
    <circle *ngFor="let step of steps; let i = index"
      [attr.cx]="getXCoordinate(i)"
      [attr.cy]="getYCoordinate(i)"
      r="16"
      [attr.stroke]="step.strokeColor"
      stroke-width="5"
      fill="white"
      [attr.opacity]="step.completed ? '1' : '0.3'">
    </circle>
    <!-- [attr.fill]="getStrokeColor(step)" -->

    <!-- Text for step numbers -->
    <!-- <text *ngFor="let step of steps; let i = index"
      [attr.x]="getXCoordinate(i)"
      [attr.y]="getTextYCoordinate(i)"
      text-anchor="middle"
      font-size="16"
      fill="black"
      font-weight="bold">
      {{ step.number }}
    </text> -->

     <!-- Icons for step numbers -->
    <foreignObject *ngFor="let step of steps; let i = index"
       [attr.x]="getXCoordinate(i) - 10" 
       [attr.y]="getYCoordinate(i) - 10" 
       width="20" 
       height="20">
      <div style="display: flex; justify-content: center; align-items: center; height: 100%;"
           [ngStyle]="{'opacity': step.completed ? '1' : '0.3'}">
        <i [class]="'fa ' + step.icon" style="color: black; font-size: 15px;"></i>
      </div>
    </foreignObject>

    <!-- Text for step labels -->
    <text *ngFor="let step of steps; let i = index"
      [attr.x]="getXCoordinate(i)"
      [attr.y]="getLabelYCoordinate(i)"
      text-anchor="middle"
      font-size="17"
      fill="white"
      [attr.opacity]="step.completed ? '1' : '0.1'">
      {{ step.label }}
    </text>

    <!-- Text for step times -->
    <text *ngFor="let step of steps; let i = index"
      [attr.x]="getXCoordinate(i)"
      [attr.y]="getTimeYCoordinate(i)"
      text-anchor="middle"
      font-size="14"
      fill="#fabb74">
      {{ step.time }}
    </text>
  </svg>

  <p class="actions-header">
    Actions
  </p>

  <div class="actions">
    <ul #actionsList>
      <div *ngIf="actions.length === 0" class="no-actions">
        {{'ALARM_HISTORY.NO_DATA' | translate}}
      </div>
      <li *ngFor="let action of actions" class="typewriter">
        <span *ngIf="action.completed" class="checkmark">&#10004;</span>{{ action.displayedText }}
      </li>
    </ul>
  </div>
  
</div>
