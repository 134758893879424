import { Component, Input, OnInit, OnDestroy, ViewEncapsulation, SimpleChanges, AfterViewInit, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { ScaleTime, ScaleLinear } from 'd3-scale';
import { Line } from 'd3-shape';

interface DataItem {
  time: string;
  count: number;
  percentage?: number;
}

interface ChartData {
  today: DataItem[];
  previous_day?: DataItem[];
  chart: string;
}

@Component({
  selector: 'app-time-of-the-day-linechart',
  templateUrl: './time-of-the-day-linechart.component.html',
  styleUrls: ['./time-of-the-day-linechart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimeOfTheDayLinechartComponent implements AfterViewInit {
  @Input() isLive: boolean = false;
  @Input() data: ChartData = { chart: '', today: [], previous_day: [] };
  @Input() width: number = 450;
  @Input() height: number = 400;
  @Input() colors: string[] = ['#F4CEA9', '#2B79ED'];
  @Input() margin = { top: 30, right: 40, bottom: 30, left: 50 };
  @Input() legends: string[] = [];
  chartId: string = '';
  private resizeObserver: ResizeObserver;

  constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) { }

  @HostListener('window:resize')
  onResize() {
    this.drawChart();
  }

  ngOnInit() {
    this.initResizeObserver();
  }

  ngAfterViewInit() {
    if (this.data.chart) {
      this.chartId = this.data.chart;
      this.cdr.detectChanges();
      this.drawChart();
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    d3.selectAll(`#tooltip-line-${this.chartId}`).remove();
    d3.selectAll(`#tooltip-arrow-line-${this.chartId}`).remove();
    d3.select(`figure#line-${this.chartId}`).select('svg').remove();
  }

  private initResizeObserver() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.drawChart();
      }
    });

    const chartContainer = this.elementRef.nativeElement.querySelector(`#line-${this.chartId}`);
    if (chartContainer) {
      this.resizeObserver.observe(chartContainer);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['data'] && !changes['data'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['margin'] && !changes['margin'].firstChange)
    ) {
      this.drawChart();
    }
  }

  private convertToTime(time: string) {
    if (!time) return;
    const [hours, minutes] = time?.split(':')?.map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0);
    return date;
  }

  private createSvg(): d3.Selection<SVGSVGElement, unknown, HTMLElement, any> {
    d3.select(`figure#line-${this.chartId}`).select('svg').remove();
    return d3
      .select(`figure#line-${this.chartId}`)
      ?.append('svg')
      ?.attr('width', this.width)
      ?.attr('height', this.height);
  }

  private createScales(
    width: number,
    height: number
  ): { x: ScaleTime<number, number>; y: ScaleLinear<number, number> } {
    const x: ScaleTime<number, number> = d3.scaleTime().rangeRound([0, width]);
    const y: ScaleLinear<number, number> = d3
      .scaleLinear()
      .rangeRound([height, 0]);
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    const today = new Date();
    const endTime = new Date(today);

    this?.data?.chart === 'alarm-time-of-the-day' || this?.data?.chart === 'operator-activity' ? endTime.setHours(23, 0, 0, 0) : endTime.setHours(24, 0, 0, 0);
    x.domain([startTime, endTime]);

    // Check if all data points are zero
    const allZero = [...this?.data?.today, ...(this?.data?.previous_day || [])]
      .every((d: DataItem) => d.count === 0);

    if (allZero) {
      // If all data points are zero, set y domain to [0, 1]
      y.domain([0, 1]);
    } else {
      // Otherwise, use the max value as before
      y.domain([
        0,
        d3.max(
          [...this?.data?.today, ...(this?.data?.previous_day || [])],
          (d: DataItem) => d.count
        ) as number,
      ]);
    }

    return { x, y };
  }

  private appendAxes(
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    height: number,
    width: number
  ): void {
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0);
    const today = new Date();
    const endTime = new Date(today);
    this?.data?.chart === 'alarm-time-of-the-day' || this?.data?.chart === 'operator-activity' ? endTime.setHours(23, 0, 0, 0) : endTime.setHours(24, 0, 0, 0);

    chartGroup
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeHour.every(4))
          .tickValues(this?.data?.chart === 'alarm-time-of-the-day' || this?.data?.chart === 'operator-activity' ? d3.timeHour.range(startTime, endTime, 4).concat(endTime) : null)
          .tickFormat((d: Date, index: number) => {
            const hours = d.getHours();
            const minutes = d.getMinutes();
            if (hours === 0 && minutes === 0 && index === 0) {
              return '00 AM';
            }
            if ((this?.data?.chart === 'alarm-time-of-the-day' || this?.data?.chart === 'operator-activity') && hours === 23) {
              return '12 AM';
            }
            return d3.timeFormat('%I %p')(d);
          })
      )
      .selectAll('text')
      .attr('font-size', '0.8vw')
      .attr('fill', 'white');

    const yAxis = chartGroup.append('g').call(d3.axisLeft(y).ticks(4));
    yAxis.selectAll('text')?.attr('font-size', '0.8vw')?.style('fill', 'white');
    yAxis
      .append('text')
      .attr('fill', '#ffffff')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end');

    chartGroup.selectAll('.domain').style('stroke', 'none');
    yAxis
      .selectAll('.tick')
      ?.select('line')
      ?.clone()
      ?.attr('class', 'grid-line')
      ?.attr('x1', 0)
      ?.attr('x2', width)
      ?.attr('y1', 0)
      ?.attr('y2', 0)
      ?.attr('stroke', '#ffffff20')
      ?.attr('stroke-width', 0.5);
  }

  private appendLines(
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    line: Line<DataItem>
  ): void {
    const todayLine = chartGroup
      .append('path')
      .datum(this.data.today)
      .attr('fill', 'none')
      .attr('stroke', `${this.colors[0]}`)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1.5)
      .attr('d', line);

    const previousDayLine = chartGroup
      .append('path')
      .datum(this.data.previous_day || [])
      .attr('fill', 'none')
      .attr('stroke', `${this.colors[1]}`)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1.5)
      .attr('d', line);

    chartGroup
      .selectAll('.data-point-today')
      .data(this.data.today)
      .enter()
      .append('circle')
      .attr('class', 'data-point-today')
      .attr('cx', (d) => {
        return x(this.convertToTime(d?.time));
      })
      .attr('cy', (d) => y(d.count))
      .attr('fill', 'none')
      .attr('stroke', 'transparent')
      .attr('r', 3);

    chartGroup
      .selectAll('.data-point-previous')
      .data(this?.data?.previous_day || [])
      .enter()
      .append('circle')
      .attr('class', 'data-point-previous')
      .attr('cx', (d) => x(this.convertToTime(d?.time)))
      .attr('cy', (d) => y(d.count))
      .attr('fill', 'none')
      .attr('stroke', 'transparent')
      .attr('r', 3);
  }

  private createTooltip(): {
    tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
    tooltipArrow: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
  } {
    d3.select(`#tooltip-line-${this.chartId}`).remove();
    d3.select(`#tooltip-arrow-line-${this.chartId}`).remove();
    const tooltip = d3
      .select('body')
      ?.append('div')
      ?.attr('class', 'tooltip-timeoftheday')
      ?.attr('id', `tooltip-line-${this.chartId}`)
      ?.style('position', 'absolute')
      ?.style('visibility', 'hidden')
      ?.style('z-index', '1000')
      ?.style('background-color', 'white')
      ?.style('padding', '5px')
      ?.style('border-radius', '15px');

    const tooltipArrow = d3
      .select('body')
      ?.append('div')
      ?.attr('class', 'tooltip-arrow')
      ?.attr('id', `tooltip-arrow-line-${this.chartId}`)
      ?.style('position', 'absolute')
      ?.style('visibility', 'hidden')
      ?.style('z-index', '1001');

    tooltipArrow.html(`<svg width="36" height="33" viewBox="0 0 36 13" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.0503 -0.000288547L0.941395 -0.000288963C8.2062 -0.000288327 14.673 4.60335 17.051 11.468C17.3616 12.3648 18.6301 12.3648 18.9408 11.4679C21.3187 4.60334 27.7855 -0.000288229 35.0503 -0.000288547Z" fill="white"/>
        </svg>`);

    return { tooltip, tooltipArrow };
  }

  private addHoverEffect(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    height: number
  ): void {
    const { tooltip, tooltipArrow } = this.createTooltip();

    const hoverCircleToday = chartGroup
      .append('circle')
      .attr('r', 3)
      .attr('fill', 'red')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 2)
      .style('visibility', 'hidden');

    const hoverCirclePrevious = chartGroup
      .append('circle')
      .attr('r', 3)
      .attr('fill', 'red')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 2)
      .style('visibility', 'hidden');

    this.appendGradient(chartGroup);

    const hoverBar = chartGroup
      .append('rect')
      .attr('class', 'hover-bar')
      .attr('width', 40)
      .attr('height', height)
      .attr('fill', 'url(#gradient-line)')
      .style('visibility', 'hidden');

    const hoverTriangle = chartGroup
      .append('polygon')
      .attr('class', 'triangle-pointer')
      .style('fill', 'red')
      .style('visibility', 'hidden');

    let hideTimeout: number | null = null;

    const showTooltip = () => {
      if (hideTimeout) {
        clearTimeout(hideTimeout);
        hideTimeout = null;
      }
      tooltip.style('visibility', 'visible');
      tooltipArrow.style('visibility', 'visible');
      hoverCircleToday.style('visibility', 'visible');
      hoverCirclePrevious.style(
        'visibility',
        this?.data?.chart === 'alarm-time-of-the-day' ? 'visible' : 'none'
      );
      hoverBar.style('visibility', 'visible');
      hoverTriangle.style('visibility', 'visible');
    };

    const hideTooltip = () => {
      hideTimeout = window.setTimeout(() => {
        tooltip.style('visibility', 'hidden');
        tooltipArrow.style('visibility', 'hidden');
        hoverCircleToday.style('visibility', 'hidden');
        hoverCirclePrevious.style('visibility', 'hidden');
        hoverBar.style('visibility', 'hidden');
        hoverTriangle.style('visibility', 'hidden');
        hideTimeout = null;
      }, 100);
    };

    svg
      .append('rect')
      .attr('class', 'overlay')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', showTooltip)
      .on('mousemove', (event) => {
        const mouse = d3.pointer(event);

        const xTime = x.invert(mouse[0] - this.margin.left);
        const bisect = d3.bisector((d: DataItem) =>
          this.convertToTime(d.time)
        ).left;

        const combinedData = [...this.data.today];
        if (this.data.previous_day) {
          const lastTodayTime = this.convertToTime(this.data.today[this.data.today.length - 1].time);
          const laterPreviousDayPoints = this.data.previous_day.filter(d =>
            this.convertToTime(d.time).getTime() > lastTodayTime.getTime()
          );
          combinedData.push(...laterPreviousDayPoints);
        }

        const index = bisect(combinedData, xTime, 1);

        const a = combinedData[index - 1];
        const b = combinedData[index];

        const d =
          xTime.getTime() - this?.convertToTime(a?.time)?.getTime() >
            this.convertToTime(b?.time)?.getTime() - xTime?.getTime()
            ? b
            : a;

        const isFromToday = this.data.today.some(point =>
          point.time === d.time && point.count === d.count
        );

        const previousIndex = bisect(
          this.data.previous_day || [],
          this.convertToTime(d?.time),
          1
        );
        const previousA = this?.data?.previous_day?.[previousIndex - 1];
        const previousB = this?.data?.previous_day?.[previousIndex];
        const previousD =
          previousA && previousB
            ? xTime.getTime() - this.convertToTime(previousA?.time).getTime() >
              this.convertToTime(previousB?.time)?.getTime() - xTime?.getTime()
              ? previousB
              : previousA
            : previousA || previousB;
        if (isFromToday) {
          hoverCircleToday
            .attr('cx', x(this.convertToTime(d?.time)))
            .attr('cy', y(d.count));
          hoverCirclePrevious
            .attr('cx', x(this.convertToTime(previousD?.time ?? '')))
            .attr('cy', y(previousD?.count ?? 0));
        } else {
          hoverCircleToday.style('visibility', 'hidden');
          hoverCirclePrevious
            .attr('cx', x(this.convertToTime(d?.time)))
            .attr('cy', y(d.count))
            .style('visibility', 'visible');
        }
        if (hoverBar) {
          const barWidth = 40;
          const barHeight =
            height - y(Math.max(d.count, previousD?.count ?? 0)) + 10;
          const barX =
            x(this.convertToTime(d?.time)) -
            (hoverBar.node()?.getBoundingClientRect().width ?? 0) / 2;
          const barY = height - barHeight;

          hoverBar.attr('x', barX);
          hoverBar.attr('height', barHeight);
          hoverBar.attr('y', barY);

          const centerX = barX + barWidth / 2;
          const centerY = barY;
          const triangleHeight = 25;
          const triangleWidth = 3;

          const trianglePoints = [
            { x: centerX, y: height - triangleHeight },
            { x: centerX - triangleWidth / 2, y: height },
            { x: centerX + triangleWidth / 2, y: height },
          ]
            .map((p) => `${p.x},${p.y}`)
            .join(' ');

          hoverTriangle.attr('points', trianglePoints);
        }

        const formatTime = d3.timeFormat('%I:%M %p');

        const time = this.convertToTime(d?.time);
        const date = new Date(time);
        date.setHours(date.getHours() + 1);

        const formattedTime = formatTime(date);

        let timeString = this?.data?.chart === 'alarm-time-of-the-day' || this?.data?.chart === 'operator-activity' ? `${formatTime(this.convertToTime(d?.time))} - ${formattedTime}` : `${formatTime(this.convertToTime(d?.time))}`;


        tooltip.html(
          `<div style="padding:10px;display:flex; flex-direction:column;gap:10px;">
            <div style="font-weight:bold">${timeString}</div>
            <div>${this.data.chart === 'alarm-time-of-the-day' ? `${this.legends[0]}:` : ''}
            ${isFromToday ? `${d.count} Alarms ${(d.percentage ?? 0) !== 0
            ? `(<span style="display: inline-flex; align-items: center;">
                    <svg width="15" height="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="${(d.percentage ?? 0) < 0
              ? 'M12 22 L2 2 L22 2 Z'
              : 'M12 2 L22 22 L2 22 Z'}" fill="${(d.percentage ?? 0) > 0 ? 'red' : 'green'}"/>
                    </svg>
                  </span> ${(d.percentage ?? 0) > 0
              ? d.percentage
              : (d.percentage ?? 0).toString().slice(1)}%)`
            : ''}` : ''
          }</div>
             ${previousD?.count !== undefined
            ? `<div>${this.legends[1]}: ${previousD.count} Alarms</div>`
            : ''
          }
            </div>`
        );
        tooltip
          .style(
            'top',
            `${window.scrollY +
            (hoverBar.node()?.getBoundingClientRect().top ?? 0) -
            (tooltip.node()?.getBoundingClientRect().height ?? 0) -
            20
            }px`
          )
          .style(
            'left',
            `${window.scrollX +
            (hoverBar.node()?.getBoundingClientRect().left ?? 0) -
            (tooltip.node()?.getBoundingClientRect().width ?? 0) / 2 +
            (hoverBar.node()?.getBoundingClientRect().width ?? 0) / 2
            }px`
          );

        tooltipArrow
          .style(
            'top',
            `${window.scrollY +
            (tooltip.node()?.getBoundingClientRect().bottom ?? 0) -
            10
            }px`
          )
          .style(
            'left',
            `${window.scrollX +
            (tooltip.node()?.getBoundingClientRect().left ?? 0) +
            (tooltip.node()?.getBoundingClientRect().width ?? 0) / 2 -
            (hoverBar.node()?.getBoundingClientRect().width ?? 0) / 2
            }px`
          );
      })
      .on('mouseout', hideTooltip);
  }

  private appendGradient(
    g: d3.Selection<SVGGElement, unknown, HTMLElement, any>
  ): void {
    const defs = g.append('defs');
    const gradient = defs
      .append('linearGradient')
      .attr('id', 'gradient-line')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');
    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#2b2c4470');
    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#2b2c4470');
  }

  private addLiveCircle(
    svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>,
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>
  ): void {
    // Filter today's data
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const todayData = this.data.today;

    // Find the last data point of today
    const lastPoint = todayData[todayData.length - 1];
    const hoverCircleToday = chartGroup
      .append('circle')
      .attr('class', 'pulsating-circle')
      .attr('r', 3)
      .attr('fill', 'red')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 2)
      .style('visibility', 'visible')
      .attr('cx', x(this.convertToTime(lastPoint.time)))
      .attr('cy', y(lastPoint.count));
  }

  private addArea(
    chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    x: ScaleTime<number, number>,
    y: ScaleLinear<number, number>,
    height: number
  ): void {
    const areaGradient = chartGroup
      .append('defs')
      .append('linearGradient')
      .attr('id', `areaGradient-${this.chartId}`)
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    areaGradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', this.isLive ? `#1E84F1B2` : `${this.colors[0]}60`)
      .attr('stop-opacity', this.isLive ? 1 : 0.8);

    areaGradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', this.isLive ? `#1E84F1B2` : `${this.colors[0]}60`)
      .attr('stop-opacity', 0);

    const areaGradient2 = chartGroup
      .append('defs')
      .append('linearGradient')
      .attr('id', `areaGradient2-${this.chartId}`)
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    areaGradient2
      .append('stop')
      .attr('offset', '0%')
      .attr(
        'stop-color',
        this.isLive ? `${this.colors[1]}60` : `${this.colors[1]}60`
      )
      .attr('stop-opacity', this.isLive ? 1 : 0.8);

    areaGradient2
      .append('stop')
      .attr('offset', '100%')
      .attr(
        'stop-color',
        this.isLive ? `${this.colors[1]}60` : `${this.colors[1]}60`
      )
      .attr('stop-opacity', 0);

    const area = d3
      .area<DataItem>()
      .x((d) => x(this.convertToTime(d.time)))
      .y0(height)
      .y1((d) => y(d.count));

    chartGroup
      .append('path')
      .datum(this.data.previous_day || [])
      .attr('fill', `url(#areaGradient2-${this.chartId})`)
      .attr('d', area);
    chartGroup
      .append('path')
      .datum(this.data.today)
      .attr('fill', `url(#areaGradient-${this.chartId})`)
      .attr('d', area);
  }

  private drawChart(): void {
    d3.select(`figure#line-chart`).select('svg').remove();
    const svg = this.createSvg();

    const width = this.width;
    const height = this.height;
    const margin = this.margin;
    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
    const legend = svg
      .append('g')
      .attr('class', 'legend')
      .style('display', 'flex')
      .attr(
        'transform',
        `translate(${width - margin.right - 150}, ${margin.top - 20})`
      );
    if (this.legends.length > 0) {
      legend
        .append('circle')
        .attr('cx', -20)
        .attr('cy', 10)
        .attr('r', 5)
        .style('fill', this.colors[0]);
      legend
        .append('text')
        .attr('x', -10)
        .attr('y', 10)
        .text(this.legends[0])
        .style('font-size', '12px')
        .style('fill', 'white')
        .attr('alignment-baseline', 'middle');

      if (this.data.previous_day) {
        legend
          .append('circle')
          .attr('cx', 80)
          .attr('cy', 10)
          .attr('r', 5)
          .style('fill', this.colors[1]);

        legend
          .append('text')
          .attr('x', 90)
          .attr('y', 10)
          .text(this.legends[1])
          .style('font-size', '12px')
          .style('fill', 'white')
          .attr('alignment-baseline', 'middle');
      }
    }

    const { x, y } = this.createScales(
      width - margin.left - margin.right,
      height - margin.top - margin.bottom
    );

    const line = d3
      .line<DataItem>()
      .x((d: DataItem) => x(this.convertToTime(d?.time)))
      .y((d: DataItem) => {
        return y(d.count);
      });
    this.appendAxes(
      chartGroup,
      x,
      y,
      height - margin.top - margin.bottom,
      width - margin.left - margin.right
    );
    this.addArea(chartGroup, x, y, height - margin.top - margin.bottom);
    this.appendLines(chartGroup, x, y, line);

    this.addHoverEffect(
      svg,
      chartGroup,
      x,
      y,
      height - margin.top - margin.bottom
    );
    if (this.isLive) {
      this.addLiveCircle(svg, chartGroup, x, y);
    }
  }
}
