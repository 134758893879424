<div class="analytical-dashboard">
	<div class="row analytical-details">
		<div class="analytical-chart" [class.col-7]="dashboard.chartSelected !== dashboard.chartType.SITES"
			[class.col-9]="dashboard.chartSelected === dashboard.chartType.SITES">
			<app-analytical-charts></app-analytical-charts>
		</div>
		<div class="stats-summary d-flex justify-content-start"
			[class.col-5]="dashboard.chartSelected !== dashboard.chartType.SITES"
			[class.col-3]="dashboard.chartSelected === dashboard.chartType.SITES">
			<div class="update-time" *ngIf="lastUpdateMessage">
				<span class="evaluation-text">{{ lastUpdateMessage }}
					<i class="fa fa-refresh pl-2" aria-hidden="true" (click)="updateData()"></i>
				</span>
			</div>
			<app-alarms-stats-summary *ngIf="dashboard.chartSelected === dashboard.chartType.ALARM">
			</app-alarms-stats-summary>
			<app-operators-stats-summary *ngIf="dashboard.chartSelected === dashboard.chartType.OPERATOR">
			</app-operators-stats-summary>
			<app-site-stats-summary *ngIf="dashboard.chartSelected === dashboard.chartType.SITES">
			</app-site-stats-summary>
		</div>
	</div>
	<div class="row stats-block" id="stats-block">
		<div class="filter-content">
			<app-alarms-filter [filterType]="filterType" (changeHeight)="changeTableSize()"></app-alarms-filter>
		</div>
		<app-alarms-stats *ngIf="dashboard.chartSelected === dashboard.chartType.ALARM" class="stats-table">
		</app-alarms-stats>
		<app-operator-stats *ngIf="dashboard.chartSelected === dashboard.chartType.OPERATOR" class="stats-table">
		</app-operator-stats>
		<app-sites-stats *ngIf="dashboard.chartSelected === dashboard.chartType.SITES" class="stats-table">
		</app-sites-stats>
	</div>
</div>