<div class="chart-block charttwo-block total-alarms radius-15 box-border d-flex flex-column custom-mb flex-grow-1">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Total Alarms</h2>
      <app-popover [title]="'Total alarms with status'"
        [content]="'Total number of alarms with a breakdown by status'"></app-popover>
    </div>
    <h2 class="title text-white">{{ totalAlarms }}</h2>
  </div>
  <div class="chart-wrap d-flex justify-content-around h-100 flex-column">
    <app-total-alarm-progress *ngIf="alarmInsightsData.length > 0" [width]="dynamicWidth" [height]="dynamicHeight"
      [percentage]="dynamicPercentage" [intervals]="dynamicIntervals"></app-total-alarm-progress>
    <app-alarm-insights [data]="alarmInsightsData"></app-alarm-insights>
    <div *ngIf="alarmInsightsData.length === 0"
      class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
      {{ "INSIGHTS.NO_DATA" | translate }}
    </div>
  </div>

</div>