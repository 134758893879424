<mat-form-field class="date-picker" color="blue" (click)="openDatePicker()">
  <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
    <input
      matStartDate
      [placeholder]="startDateSelected ? '' : 'Start date'"
      (dateChange)="onStartDateChange($event)"
    />
    <input
      matEndDate
      [placeholder]="startDateSelected ? '' : 'End date'"
      (dateChange)="onEndDateChange($event)"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
