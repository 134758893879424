<div>
	<img class="show-image" alt="show-image"
		id="show-image"
		[src]="url"
		(error)="onError()"
		*ngIf="!imgLoadFailed">

	<button ion-button
		class="zoom-btn zoom-in-btn"
		(click)="zoomIn()"
		title="Zoom in">
		+
	</button>

	<button ion-button
		class="zoom-btn zoom-out-btn"
		(click)="zoomOut()"
		title="Zoom out">
		-
	</button>
	
	<p *ngIf="imgLoadFailed" class="error">
		{{ "ERRORS.IMAGE_LOADING_ERROR" | translate }}
	</p>

	<nb-icon nbPrefix icon="close-outline" pack="eva" class="close-asset" (click)="closeAsset()">
	</nb-icon>

</div>