import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BranchService } from 'src/app/services/branch.service';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../utils/app-utils';
import { Subscription } from 'rxjs';
import { ConfidenceMonitoringService } from '../services/confidence-monitoring.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-alarm',
  templateUrl: './add-alarm.component.html',
  styleUrls: ['./add-alarm.component.scss']
})
export class AddAlarmComponent implements OnInit {
  utility = Utility;
  branches: [];
  selectedBranch: string;
  alarmTypes: [];
  selectedAlarmType: string;
  severityTypes: [];
  isSelectedBranch: boolean = false;
  isSelectedAlarmType: boolean = false;
  isSelectedSeverity: boolean = false;
  selectedSeverity: string;
  comment: any;
  disable_add: boolean = true;

  public selectedBranchSubscriber: Subscription;

  constructor(public translate: TranslateService, public branchService: BranchService, public alarmService: AlarmService, public confidenceMonitoringService: ConfidenceMonitoringService,
    public _snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    this.selectedBranchSubscriber = this.confidenceMonitoringService.getSitesChangedNotification().subscribe(async (branch: any) => {
      if (branch) {
        this.isSelectedBranch = true;
        this.selectedAlarmType = null;
        this.selectedSeverity = null;
        this.selectedBranch = branch.id;
        this.getAlarmTypes();
      }
    });
    // await this.getBranches();
    await this.getSeverities();
    this.reset();
  }

  // async getBranches() {
  //   this.branches = await this.branchService.getOperatorBranches();
  // }

  // onBranchChange(ev: any) {
  //   this.selectedBranch = ev.source.selected.value;
  //   this.isSelectedBranch = true;
  //   this.selectedAlarmType = null;
  //   this.getAlarmTypes();
  // }

  onAlarmTypeChange(ev: any) {
    this.selectedAlarmType = ev.source.selected.value;
    this.isSelectedAlarmType = true;
  }

  onSeverityChange(ev: any) {
    this.selectedSeverity = ev.source.selected.value;
    this.isSelectedSeverity = true;
  }

  async getAlarmTypes() {
    this.alarmTypes = await this.alarmService.getAlarmsTypes(this.selectedBranch);
  }

  async getSeverities() {
    this.severityTypes = await this.alarmService.getSeverityTypes();
  }

  async addAlarm() {
    let response = await this.alarmService.addAlarm(this.selectedBranch, this.selectedAlarmType, this.selectedSeverity, this.comment);
    if (response.success === true) {
      const message = this.translate.instant('ADD_ALARM.SUCCESS_MESSAGE');
      this.openSnackBar(message);
      this.reset();
    } else {
      const message = response.errorMessages[0].message;
      this.openSnackBar(message);
    }
  }

  reset() {
    // this.selectedBranch = null;
    this.selectedAlarmType = null;
    this.selectedSeverity = null;
    // this.isSelectedBranch = false;
    this.isSelectedAlarmType = false;
    this.isSelectedSeverity = false;
    this.comment = "";
  }

  ngOnDestroy() {
    this.selectedBranchSubscriber.unsubscribe();
  }

  openSnackBar(message: string) {
    return this._snackBar.open(message, null, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5 * 1000, // 5 seconds
    });
  }

}
