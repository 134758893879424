
export class ApiConstants {
	public static API_SERVER: string = '';
	public static URL_BASE: string;
	public static URL_SESSION_ACTIVE: string;
	public static URL_SESSION: string;
	public static URL_LOGIN: string;
	public static URL_LOGOUT: string;
	public static ASSETS_URL: string;
	public static URL_ALARM: string;
	public static URL_ALARMS: string;
	public static URL_ALARMS_FILTERS: string;
	public static URL_BRANCHES: string;
	public static URL_BRANCH: string;
	public static URL_UN_ATTENDED_ALARMS: string;
	public static URL_ESCALATED_ALARMS: string;
	public static URL_LIST_VIDEO_SOURCES: string;
	public static URL_USER_VIDEO_CHANNELS: string;
	public static URL_STOP_STREAM: string;
	public static URL_VIDEO_SHARE: string;
	public static URL_VIDEO_UN_SHARE: string;
	public static URL_VIDEO_CONTROL: string;
	public static URL_QUESTIONS: string;
	public static URL_ANSWER: string;
	public static URL_CHECK_ASSIGNED: string;
	public static URL_ACTIONS: string;
	public static URL_COMMENT: string;
	public static URL_MANAGERS: string;
	public static URL_USERS: string;
	public static URL_ADMIN_USERS: string;
	public static URL_ALL_BRANCHES: string;
	public static URL_ALARM_HISTORY_REPORT: string;
	public static URL_OPERATORS_REPORT: string;
	public static URL_ALARM_STATISTIC: string;
	public static URL_ALARM_STATISTIC_CHART: string;
	public static URL_OPERATORS_STATISTIC: string;
	public static URL_SITES_STATISTIC: string;
	public static URL_ALARMS_HISTORY_REPORT: string;
	public static URL_BRANCH_STATUS_REPORT: string;
	public static URL_OPERATORS_STATUS: string;
	public static URL_ALARMS_ACTIONS: string;
	public static URL_RELATED_OPERATORS: string;
	public static URL_ACTIVE_RELATED_OPERATORS: string;
	public static URL_ONLINE_OPERATORS: string;
	public static URL_OPERATORS_STATUSES: string;
	public static URL_REGIONS: string;
	public static URL_SITES_STATUSES: string;
	public static URL_ALL_PRIORITY_TYPES: string;
	public static URL_ALARMS_TYPES: string;
	public static URL_BRANCH_ALARM_TYPES: string;
	public static URL_ALARMS_SEVERITY_TYPES: string;
	public static URL_ALARMS_STATUSES_TYPES: string;
	public static URL_SHORT_ALARM_IDS: string;
	public static URL_ADD_ALARMS: string;
	public static URL_GET_CLIENT_LOGO: string;
	public static URL_ADMIN_ZONE_DRAW: string;
	public static URL_GET_SYSTEM_LANGUAGE: string;
	public static URL_SEVERITY_TYPES: string;
	public static URL_SEND_MAIL: string;
	public static URL_ROOM: string;
	public static URL_ROOM_PURPOSES: string;
	public static URL_NOTIFICATIONS: string;
	public static URL_MEDIA_UPLOAD: string;
	public static URL_SYSTEM_USERS: string;
	public static GROUPED_NOTIFICATIONS_URL: string;
	public static ROOM_NOTIFICATIONS_URL: string;
	public static URL_SYSTEM_INFO: string;
	public static START_STREAM_V2: string;
	public static URL_ASK_AI: string;
	public static URL_TOP_SITES: string;
	public static URL_TOTAL_ALARMS: string;
	public static URL_SITES_INSIGHTS: string;
	public static URL_OPERATORS_ACTIONS_RANKING: string;
	public static URL_ALARM_OPERATOE_RATIO: string;
	public static URL_LIVE_ALARMS: string;
	public static URL_LIVE_INCIDENTS: string;
	public static URL_OPERATOR_ACTIVITY: string;
	public static URL_REPEATED_ALARM_TYPES: string;
	public static URL_FALSE_ALARMS_RATE: string;
	public static URL_ALARMS_BY_SEVERITY: string;
	public static URL_ALARMS_BY_TIME_OF_THE_DAY: string;
	public static URL_ALARMS_MANAGEMENT_OVERVIEW: string;

	static setData(ADDRESS: any) {
		ApiConstants.API_SERVER = ADDRESS;
		ApiConstants.URL_BASE = ApiConstants.API_SERVER + '/api/v1';
		ApiConstants.URL_SESSION = ApiConstants.URL_BASE + '/auth/sessions';
		ApiConstants.URL_LOGIN = ApiConstants.URL_BASE + '/auth/login';
		ApiConstants.URL_SESSION_ACTIVE = ApiConstants.URL_SESSION + '/active';
		ApiConstants.URL_LOGOUT = ApiConstants.URL_BASE + '/auth/logout';
		ApiConstants.ASSETS_URL = ApiConstants.URL_BASE + '/core/assets';
		ApiConstants.URL_ALARM = ApiConstants.URL_BASE + '/core/alarm';
		ApiConstants.URL_ALARMS = ApiConstants.URL_BASE + '/core/alarms';
		ApiConstants.URL_ALARMS_FILTERS = ApiConstants.URL_ALARMS + '/filter'
		ApiConstants.URL_ADD_ALARMS = ApiConstants.URL_ALARMS + '/add';
		ApiConstants.URL_BRANCHES = ApiConstants.URL_BASE + '/core/branches';
		ApiConstants.URL_BRANCH = ApiConstants.URL_BASE + '/core/branch';
		ApiConstants.URL_UN_ATTENDED_ALARMS = ApiConstants.URL_ALARMS + '/unattended_alarms';
		ApiConstants.URL_ESCALATED_ALARMS = ApiConstants.URL_ALARMS + '/escalated_alarms';
		ApiConstants.URL_LIST_VIDEO_SOURCES = ApiConstants.URL_BASE + '/core/getSources';
		ApiConstants.URL_USER_VIDEO_CHANNELS = ApiConstants.URL_BASE + '/core/userChannels';
		ApiConstants.URL_STOP_STREAM = ApiConstants.URL_BASE + '/core/stopStream';
		ApiConstants.URL_VIDEO_SHARE = ApiConstants.URL_BASE + '/core/share';
		ApiConstants.URL_VIDEO_UN_SHARE = ApiConstants.URL_BASE + '/core/unshare';
		ApiConstants.URL_VIDEO_CONTROL = ApiConstants.URL_BASE + '/core/control';
		ApiConstants.URL_QUESTIONS = '/questions';
		ApiConstants.URL_ANSWER = '/answer';
		ApiConstants.URL_CHECK_ASSIGNED = '/checkAssigned';
		ApiConstants.URL_ACTIONS = '/actions';
		ApiConstants.URL_COMMENT = '/comment';
		ApiConstants.URL_MANAGERS = '/managers';
		ApiConstants.URL_GET_CLIENT_LOGO = ApiConstants.API_SERVER + '/api/v1/auth/client';
		ApiConstants.URL_USERS = ApiConstants.URL_BASE + '/core/users';
		ApiConstants.URL_ADMIN_USERS = ApiConstants.URL_BASE + '/core/admin/users';
		ApiConstants.URL_RELATED_OPERATORS = ApiConstants.URL_BASE + '/core/supervisor/users';
		ApiConstants.URL_ACTIVE_RELATED_OPERATORS = ApiConstants.URL_BASE + '/core/supervisor/operators/active';
		ApiConstants.URL_ONLINE_OPERATORS = ApiConstants.URL_BASE + '/core/supervisor/operators/online';
		ApiConstants.URL_ALL_BRANCHES = ApiConstants.URL_BASE + '/core/branches/all';
		ApiConstants.URL_ALARM_HISTORY_REPORT = ApiConstants.URL_BASE + '/history/alarmhistoryreport';
		ApiConstants.URL_OPERATORS_STATUS = ApiConstants.URL_BASE + '/core/operators/status';
		ApiConstants.URL_ALARMS_ACTIONS = ApiConstants.URL_ALARMS + '/actions';
		ApiConstants.URL_OPERATORS_STATUSES = ApiConstants.URL_BASE + '/core/operators/statuses';
		ApiConstants.URL_REGIONS = ApiConstants.URL_BASE + '/core/branches/regions';
		ApiConstants.URL_ALARMS_TYPES = ApiConstants.URL_BASE + '/core/alarms/types';
		ApiConstants.URL_ALARMS_SEVERITY_TYPES = ApiConstants.URL_BASE + '/core/alarms/severitytypes';
		ApiConstants.URL_ALARMS_STATUSES_TYPES = ApiConstants.URL_BASE + '/core/alarms/statuses';
		ApiConstants.URL_ALL_PRIORITY_TYPES = ApiConstants.URL_BASE + '/core/branches/priorityTypes';
		ApiConstants.URL_SITES_STATUSES = ApiConstants.URL_BASE + '/core/branches/statuses';
		ApiConstants.URL_ALARM_STATISTIC = ApiConstants.URL_BASE + '/core/alarms/statistics';
		ApiConstants.URL_ALARM_STATISTIC_CHART = ApiConstants.URL_BASE + '/core/service/alarm/statistics/chart';
		ApiConstants.URL_OPERATORS_STATISTIC = ApiConstants.URL_BASE + '/core/operators/statistics';
		ApiConstants.URL_SITES_STATISTIC = ApiConstants.URL_BASE + '/core/branches/statistics';
		ApiConstants.URL_ALARMS_HISTORY_REPORT = ApiConstants.URL_BASE + '/history/alarmhistoryreport';
		ApiConstants.URL_BRANCH_STATUS_REPORT = ApiConstants.URL_BASE + '/history/branches/branchstatusreport';
		ApiConstants.URL_OPERATORS_REPORT = ApiConstants.URL_BASE + '/history/operatorsreport';
		ApiConstants.URL_SHORT_ALARM_IDS = ApiConstants.URL_BASE + '/core/getshortalarmids';
		ApiConstants.URL_ADMIN_ZONE_DRAW = '/admin/zone/draw';
		ApiConstants.URL_GET_SYSTEM_LANGUAGE = ApiConstants.URL_BASE + '/auth/lang';
		ApiConstants.URL_BRANCH_ALARM_TYPES = ApiConstants.URL_BASE + '/core/branches/alarmTypes/';
		ApiConstants.URL_SEVERITY_TYPES = ApiConstants.URL_BASE + '/core/alarms/severityDetails';
		ApiConstants.URL_SEND_MAIL = ApiConstants.URL_BASE + '/mail/alarm/send';
		ApiConstants.URL_ROOM = ApiConstants.URL_BASE + '/chat/room';
		ApiConstants.URL_ROOM_PURPOSES = ApiConstants.URL_BASE + '/chat/room/purposes';
		ApiConstants.URL_NOTIFICATIONS = ApiConstants.URL_BASE + '/chat/notification';
		ApiConstants.URL_MEDIA_UPLOAD = ApiConstants.URL_BASE + '/media/upload';
		ApiConstants.URL_SYSTEM_USERS = ApiConstants.URL_BASE + '/core/usersInfo';
		ApiConstants.GROUPED_NOTIFICATIONS_URL = ApiConstants.URL_BASE + '/chat/notification/GroupedByRoom';
		ApiConstants.ROOM_NOTIFICATIONS_URL = ApiConstants.URL_BASE + '/chat/notification/room';
		ApiConstants.URL_SYSTEM_INFO = ApiConstants.URL_BASE + '/core/systemInfo';
		ApiConstants.START_STREAM_V2 = ApiConstants.URL_BASE + '/rse/stream';
		ApiConstants.URL_ASK_AI = ApiConstants.URL_BASE + '/gpt/ask';
		ApiConstants.URL_TOP_SITES = ApiConstants.URL_BASE + '/core/insights/top-sites-raising-alarms';
		ApiConstants.URL_TOTAL_ALARMS = ApiConstants.URL_BASE + '/core/insights/total-alarms';
		ApiConstants.URL_SITES_INSIGHTS = ApiConstants.URL_BASE + '/core/insights/general-sites-insights';
		ApiConstants.URL_OPERATORS_ACTIONS_RANKING = ApiConstants.URL_BASE + '/core/insights/operators-actions-ranking';
		ApiConstants.URL_ALARM_OPERATOE_RATIO = ApiConstants.URL_BASE + '/core/insights/alarm-operator-ratio';
		ApiConstants.URL_LIVE_ALARMS = ApiConstants.URL_BASE + '/core/insights/incidents-till-now';
		ApiConstants.URL_LIVE_INCIDENTS = ApiConstants.URL_BASE + '/core/insights/alarms-in-realtime';
		ApiConstants.URL_OPERATOR_ACTIVITY = ApiConstants.URL_BASE + '/core/insights/operator-activity';
		ApiConstants.URL_REPEATED_ALARM_TYPES = ApiConstants.URL_BASE + '/core/insights/repeated-alarm-types';
		ApiConstants.URL_FALSE_ALARMS_RATE = ApiConstants.URL_BASE + '/core/insights/false-alarm-rate';
		ApiConstants.URL_ALARMS_BY_SEVERITY = ApiConstants.URL_BASE + '/core/insights/alarms-by-severity';
		ApiConstants.URL_ALARMS_BY_TIME_OF_THE_DAY = ApiConstants.URL_BASE + '/core/insights/alarm-by-time-of-day';
		ApiConstants.URL_ALARMS_MANAGEMENT_OVERVIEW = ApiConstants.URL_BASE + '/core/insights/alarm-management-overview';
	}
}
