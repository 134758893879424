import { Component, OnInit, Input, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
declare var bootstrap: any;

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements AfterViewInit, OnDestroy {
  constructor(private elementRef: ElementRef) { }

  @Input() title: string = '';
  @Input() content: string = '';

  private popoverInstance: any;

  ngAfterViewInit(): void {
    const popoverTriggerEl = this.elementRef.nativeElement.querySelector('[data-toggle="popover"]');
    if (popoverTriggerEl) {
      this.popoverInstance = new bootstrap.Popover(popoverTriggerEl, {
        template: `
          <div class="popover custom-popover" role="tooltip">
            <div class="arrow"></div>
            <h3 class="title popover-header"></h3>
            <div class="popover-body"></div>
          </div>
        `,
      });
    }
  }

  ngOnDestroy(): void {
    this.hidePopover();
  }

  hidePopover(): void {
    if (this.popoverInstance) {
      this.popoverInstance.hide();
      this.popoverInstance.dispose();
    }
  }
}