import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})

export class NavbarComponent implements OnInit {
  selectedTab: string = 'overview';
  isScreenHeightSmall: boolean = window.innerHeight < 900 || window.innerWidth < 1300;

  constructor() {
    this.checkScreenHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenHeight();
  }

  private checkScreenHeight() {
    this.isScreenHeightSmall =
      window.innerHeight < 900 || window.innerWidth < 1300;
    if (this.isScreenHeightSmall) {
      this.selectedTab = 'overview';
      this.tabSelected.emit(this.selectedTab);
    }
  }

  ngOnInit(): void { }

  @Output() tabSelected = new EventEmitter<string>();

  selectTab(tab: string) {
    this.selectedTab = tab;
    this.tabSelected.emit(this.selectedTab);
  }
}
