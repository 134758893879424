import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/models/api-response.model';
import { Utility } from 'src/app/utils/app-utils';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from 'src/app/constants/api-constants';
import { AlarmService } from 'src/app/services/alarm.service';
import { WorkflowService } from 'src/app/services/workflow.service';
import { AlarmStatus } from 'src/app/enums/alarm-status.enum';

export interface QuestionRequestBody {
	action?: string;
	comment?: string;
	label?: string;
	answer?: any;
}

const blankRequestData = {
	action: '',
	comment: '',
	answer: '',
	label: ''
};

@Injectable({
	providedIn: 'root'
})
export class QuestionService {

	requestData: QuestionRequestBody = blankRequestData;
	previousStatus: string;

	questionChangedListener = new Subject<any>();
	constructor(
		private http: HttpClient,
		private alarmService: AlarmService,
		private workflowService: WorkflowService
	) { }

	getQuestionChangeListener() {
		return this.questionChangedListener.asObservable();
	}

	changeQuestion(question) {
		this.questionChangedListener.next(question);
	}

	async answerToQuestion() {
		const selectedAlarm = this.alarmService.selectedAlarm;
		const response: ApiResponse = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ALARMS + '/' + selectedAlarm.id + ApiConstants.URL_ANSWER, this.requestData));
		if (response.success === true) {
			this.resetRequestData();
			const alarmList = this.alarmService.alarmList;
			if (response.data && alarmList.length > 0) {
				const currentStatus: string = response.data.currentStatus || '';
				if (this.previousStatus.toLowerCase() !== currentStatus.toLowerCase()) {
					if (currentStatus === AlarmStatus.ACKNOWLEDGED) {
						selectedAlarm.actionTime = {
							...selectedAlarm.actionTime,
							acknowledged: Date.now()
						};
					} else if (currentStatus === AlarmStatus.VERIFIED) {
						selectedAlarm.actionTime = {
							...selectedAlarm.actionTime,
							verificationTime: Date.now()
						};
					}
					this.alarmService.changeStatusAlarmNotification(selectedAlarm, currentStatus);
					this.alarmService.changeAlarmHistoryStatus(selectedAlarm.id, currentStatus);
				}
				this.workflowService.addActionHistory(response.data.prevAnswer);
				this.changeQuestion(response.data);
				this.previousStatus = currentStatus;
			}
		}
	}

	private resetRequestData() {
		this.requestData = Object.assign({}, blankRequestData);
	}

	/////////////////////////////////////////////////////////////////////

	async proceed_workflow(answer: any, action: any, comment: any, label: any) {
		const selectedAlarm = this.alarmService.selectedAlarm;
		const response: ApiResponse = await lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ALARMS + '/' + selectedAlarm.id + ApiConstants.URL_ANSWER,
			{ answer: answer, action: action, comment: comment, label: label }));
		if (response.success === true) {
			this.resetRequestData();
			const alarmList = this.alarmService.alarmList;
			if (response.data && alarmList.length > 0) {
				const currentStatus: string = response.data.currentStatus || '';
				if (this.previousStatus.toLowerCase() !== currentStatus.toLowerCase()) {
					if (currentStatus === AlarmStatus.ACKNOWLEDGED) {
						selectedAlarm.actionTime = {
							...selectedAlarm.actionTime,
							acknowledged: Date.now()
						};
					} else if (currentStatus === AlarmStatus.VERIFIED) {
						selectedAlarm.actionTime = {
							...selectedAlarm.actionTime,
							verificationTime: Date.now()
						};
					}
					this.alarmService.changeStatusAlarmNotification(selectedAlarm, currentStatus);
					this.alarmService.changeAlarmHistoryStatus(selectedAlarm.id, currentStatus);
				}
				this.workflowService.addActionHistory(response.data.prevAnswer);
				this.changeQuestion(response.data);
				this.previousStatus = currentStatus;
			}
		}

	}

	/////////////////////////////////////////////////////////////////////

	async getQuestion(alarmId: string) {
		const response: ApiResponse = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS + '/' + alarmId + ApiConstants.URL_QUESTIONS));
		if (response.success === true) {
			this.resetRequestData();
			this.changeQuestion(Utility.getPath(response, 'data.0'));
		}
	}
}
