<div class="chart-block charttwo-block radius-15 box-border d-flex flex-column flex-grow-1">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Operator Activity</h2>
      <app-popover [title]="'Operator activity'"
        [content]="'Operator activity track based on his actions and idle time in between.'">
      </app-popover>
    </div>
    <div class="card-wrap">
      <div class="chart-value  d-flex radius-15 text-center box-border">
        <div class="item-value cursor-pointer" (click)="onChartClick('bar')" [class.active]="selectedChart === 'bar'"
          [class.bar]="selectedChart === 'bar'">
          <span> <ng-icon class="arrow-icon-progress" name="FeatherBarChart" color="#fff"></ng-icon></span>
        </div>
        <div class="item-value cursor-pointer" (click)="onChartClick('line')" [class.active]="selectedChart === 'line'"
          [class.line]="selectedChart === 'line'">
          <span><ng-icon class="arrow-icon-progress" name="FeatherTrendingUp" color="#fff"></ng-icon></span>
        </div>
      </div>
    </div>
    <div class="filter-block d-flex align-items-center">
      <select class="op-select" [(ngModel)]="selectedOperatorId" (ngModelChange)="onOperatorChange()">
        <option *ngFor="let operator of operators" [ngValue]="operator.id">{{ operator.name }}</option>
      </select>
    </div>
  </div>
  <div class="chart-wrap" id="operator-activity" *ngIf="data?.today?.length > 0">
    <div class="chart">
      <app-time-of-the-day-linechart *ngIf="selectedChart === 'line'" [data]="data" [width]="dynamicWidth"
        [height]="dynamicHeight" [colors]="['#CDA73A']"></app-time-of-the-day-linechart>
      <app-barchart *ngIf="selectedChart === 'bar'" [data]="data" [width]="dynamicWidth"
        [height]="dynamicHeight"></app-barchart>
    </div>
  </div>
  <div *ngIf="data?.today?.length === 0" class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>