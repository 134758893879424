import { Component, OnInit, Input, SimpleChanges, HostListener } from '@angular/core';
import * as d3 from 'd3';
import { formatValue } from 'src/app/utils/commonfunctions';

@Component({
  selector: 'app-top-sites-donut',
  templateUrl: './top-sites-donut.component.html',
  styleUrls: ['./top-sites-donut.component.scss'],
})
export class TopSitesDonutComponent implements OnInit {
  @Input() chartId: string = '';
  @Input() data: {
    value: number;
    label: string;
    percentage: number;
  }[] = [];
  @Input() width: number = 200;
  @Input() height: number = 200;
  @Input() radius: number = 90;
  @Input() arcSize: number = 25;
  @Input() colors: string[] = [
    '#0D47A1',
    '#2d42c4',
    '#1976D2',
    '#1E88E7',
    '#1565C0',
  ];
  colorsGradients = [];
  private svg: any;

  private upArrowIcon =
    'M13.6598 2.34315C12.5408 1.22433 11.115 0.462403 9.56281 0.153721C8.01063 -0.15496 6.40174 0.00346624 4.93961 0.608967C3.47748 1.21447 2.22778 2.23985 1.34854 3.55544C0.469294 4.87103 0 6.41775 0 8C0 9.58225 0.469295 11.129 1.34854 12.4446C2.22778 13.7602 3.47748 14.7855 4.93961 15.391C6.40174 15.9965 8.01063 16.155 9.56281 15.8463C11.115 15.5376 12.5408 14.7757 13.6598 13.6569C15.1584 12.1555 16 10.121 16 8C16 5.87896 15.1584 3.84454 13.6598 2.34315ZM8.00175 12.0969C7.92086 12.0969 7.84076 12.081 7.76602 12.0501C7.69128 12.0192 7.62337 11.9738 7.56617 11.9166C7.50897 11.8594 7.46361 11.7915 7.43268 11.7168C7.40174 11.6421 7.38584 11.562 7.38589 11.4811L7.38643 6.00433L5.82556 7.56486C5.71013 7.68026 5.55357 7.7451 5.39032 7.7451C5.22708 7.7451 5.07052 7.68026 4.95509 7.56486C4.83965 7.44945 4.7748 7.29293 4.7748 7.12972C4.7748 6.96651 4.83965 6.80998 4.95509 6.69457L7.56651 4.08372C7.68194 3.96831 7.8385 3.90348 8.00175 3.90348C8.165 3.90348 8.32156 3.96831 8.43699 4.08372L11.0484 6.69457C11.1638 6.80998 11.2287 6.96651 11.2287 7.12972C11.2287 7.29293 11.1638 7.44945 11.0484 7.56486C10.933 7.68026 10.7764 7.7451 10.6132 7.7451C10.4499 7.7451 10.2934 7.68026 10.1779 7.56486L8.61707 6.00433L8.61761 11.4811C8.61766 11.562 8.60176 11.6421 8.57082 11.7168C8.53989 11.7915 8.49453 11.8594 8.43733 11.9166C8.38013 11.9738 8.31222 12.0192 8.23748 12.0501C8.16274 12.081 8.08264 12.0969 8.00175 12.0969Z';

  private downArrowIcon =
    'M2.34016 13.6569C3.45922 14.7757 4.885 15.5376 6.43719 15.8463C7.98937 16.155 9.59826 15.9965 11.0604 15.391C12.5225 14.7855 13.7722 13.7602 14.6515 12.4446C15.5307 11.129 16 9.58225 16 8C16 6.41775 15.5307 4.87103 14.6515 3.55544C13.7722 2.23985 12.5225 1.21447 11.0604 0.608967C9.59826 0.00346605 7.98937 -0.154961 6.43719 0.153721C4.885 0.462402 3.45922 1.22433 2.34016 2.34315C0.84161 3.84453 8.8481e-07 5.87895 6.99382e-07 8C5.13955e-07 10.121 0.841609 12.1555 2.34016 13.6569ZM7.99825 3.90313C8.07914 3.90309 8.15924 3.91898 8.23398 3.94991C8.30872 3.98084 8.37663 4.02619 8.43383 4.08338C8.49103 4.14056 8.53639 4.20845 8.56732 4.28318C8.59826 4.3579 8.61416 4.43799 8.61411 4.51886L8.61357 9.99567L10.1744 8.43514C10.2899 8.31974 10.4464 8.2549 10.6097 8.2549C10.7729 8.2549 10.9295 8.31974 11.0449 8.43514C11.1603 8.55055 11.2252 8.70707 11.2252 8.87028C11.2252 9.03349 11.1603 9.19002 11.0449 9.30543L8.43349 11.9163C8.31806 12.0317 8.1615 12.0965 7.99825 12.0965C7.835 12.0965 7.67845 12.0317 7.56301 11.9163L4.95159 9.30543C4.83615 9.19002 4.7713 9.03349 4.7713 8.87028C4.7713 8.70707 4.83615 8.55055 4.95159 8.43514C5.06702 8.31973 5.22358 8.2549 5.38682 8.2549C5.55007 8.2549 5.70663 8.31973 5.82206 8.43514L7.38293 9.99567L7.38239 4.51886C7.38234 4.43799 7.39824 4.3579 7.42918 4.28318C7.46011 4.20845 7.50547 4.14056 7.56267 4.08338C7.61987 4.02619 7.68778 3.98084 7.76252 3.94991C7.83726 3.91898 7.91736 3.90309 7.99825 3.90313Z';

  ngOnInit(): void {
    this.createSvg();
    this.createGradients();
    this.drawChart();
  }

  ngAfterViewInit() {
    if (this.chartId) {
      this.createSvg();
      this.createGradients();
      this.drawChart();
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.createSvg();
    this.createGradients();
    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['chartId'] && !changes['chartId'].firstChange) ||
      (changes['data'] && !changes['data'].firstChange) ||
      (changes['width'] && !changes['width'].firstChange) ||
      (changes['height'] && !changes['height'].firstChange) ||
      (changes['radius'] && !changes['radius'].firstChange) ||
      (changes['colors'] && !changes['colors'].firstChange)
    ) {
      this.createSvg();
      this.createGradients();
      this.drawChart();
    }
  }

  private createSvg(): void {
    d3.select(`figure#${this.chartId} svg`)?.remove();
    this.svg = d3
      .select(`figure#${this.chartId}`)
      ?.append('svg')
      ?.attr('width', this.width)
      ?.attr('height', this.height)
      ?.append('g')
      ?.attr('transform', `translate(${this.width / 2},${this.height / 2})`);
  }

  private createGradients(): void {
    const defs = this.svg.append('defs');
    this.data.forEach((d: any, i: number) => {
      const gradient = defs
        .append('linearGradient')
        .attr('id', `gradient${i}`)
        .attr('x1', '0%')
        .attr('y1', '0%')
        .attr('x2', '100%')
        .attr('y2', '0%');

      const lightColor = this.colors[i]?.[0];
      const darkerColor = this.colors[i]?.[1];

      gradient
        ?.append('stop')
        .attr('offset', '0%')
        .attr('stop-color', lightColor);

      gradient
        ?.append('stop')
        .attr('offset', '100%')
        .attr('stop-color', darkerColor);
      this.colorsGradients.push(`url(#gradient${i})`);
    });
  }

  private drawChart(): void {
    if (!this.data || this.data.length === 0) {
      // Early return if there is no data
      return;
    }

    const innerRadius = this.radius - this.arcSize;
    const outerRadius = this.radius;
    const hoverRadius = this.radius * 1.1;
    const hoverRadius1 = this.radius * 1.11;

    const pie = d3
      .pie<any>()
      .value((d: any) => Number(d.value))
      .padAngle(0.02);

    const arc = d3
      .arc<d3.PieArcDatum<any>>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(5);
    const hoverArc = d3
      .arc<d3.PieArcDatum<any>>()
      .innerRadius(innerRadius)
      .outerRadius(hoverRadius)
      .cornerRadius(5);
    const hoverArc1 = d3
      .arc<d3.PieArcDatum<any>>()
      .innerRadius(innerRadius)
      .outerRadius(hoverRadius1)
      .cornerRadius(5);

    function shiver(
      selection: d3.Selection<SVGPathElement, d3.PieArcDatum<any>, any, any>
    ) {
      selection
        .transition()
        .duration(70)
        .attr('d', (d: d3.PieArcDatum<any>) => hoverArc(d) || '')
        .transition()
        .duration(81)
        .attr('d', (d: d3.PieArcDatum<any>) => arc(d) || '')
        .transition()
        .duration(92)
        .attr('d', (d: d3.PieArcDatum<any>) => hoverArc1(d) || '');
    }

    this.svg
      .selectAll('pieces')
      .data(pie(this.data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d: any, i: number) => `url(#gradient${i})`)
      .attr('stroke', '#121926')
      .style('stroke-width', '1px')
      .style('cursor', 'pointer')
      .on('mouseover', (event: MouseEvent, d: d3.PieArcDatum<any>) => {
        const target = event?.currentTarget as SVGPathElement;
        shiver(d3.select(target));
        this.updateCenterText(
          this.trimBranchName(d?.data?.label),
          d?.data?.value,
          d?.data?.percentage,
          d?.data?.label
        );
      })
      .on('mouseout', (event: MouseEvent, d: d3.PieArcDatum<any>) => {
        d3.select(event.currentTarget as Element)
          ?.transition()
          ?.duration(200)
          ?.attr('d', arc(d));
      });
    this.appendCenterElements(innerRadius);
  }

  private trimBranchName(name: string, maxLength: number = 10): string {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '..';
    }
    return name;
  }

  private appendCenterElements(innerRadius: number): void {
    const hasData = this.data && this.data.length > 0;
    const defaultData = { percentage: 0, label: 'No Data', value: 0 };

    this.svg
      .append('circle')
      .attr('r', innerRadius - 10)
      .style('fill', '#282834');

    const textPadding = 25;
    const textHeight = 0;
    const text1Y = textHeight - textPadding;
    const text2Y = textHeight;
    const text3Y = textHeight + textPadding;

    const group = this.svg
      .append('g')
      .attr('transform', `translate(-10, ${text1Y + 8})`)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central');

    const arrowIconSize = 30;
    group
      .append('path')
      .attr('class', 'arrow-icon')
      .attr(
        'd',
        hasData ? (this.data[0]?.percentage > 0 ? this.upArrowIcon : this.downArrowIcon) : this.downArrowIcon
      )
      .attr(
        'transform',
        `translate(${-arrowIconSize / 2}, ${-arrowIconSize / 2})`
      )
      .attr('fill', hasData ? (this.data[0]?.percentage > 0 ? 'red' : 'green') : 'gray');

    this.svg
      .append('text')
      .attr('x', 10)
      .attr('y', text1Y)
      .attr('class', 'text1')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .style('font-size', '16px')
      .style('fill', '#ffff')
      .text(`${formatValue(hasData ? this.data[0]?.percentage : defaultData.percentage)}%`);
    this.svg
      .append('text')
      .attr('x', 0)
      .attr('y', text2Y)
      .attr('class', 'text2')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .style('font-size', '16px')
      .style('fill', '#ffff')
      .text(`${hasData ? this.data[0]?.label : defaultData.label}`)
      .append('title')
      .text(`${hasData ? this.data[0]?.label : defaultData.label}`);

    this.svg
      .append('text')
      .attr('x', 0)
      .attr('y', text3Y)
      .attr('class', 'text3')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .style('font-size', '21px')
      .style('fill', '#ffff')
      .text(`${hasData ? this.data[0]?.value : defaultData.value}`);
  }

  private updateCenterText(text1: string, text2: string, text3: number, originalText: string): void {
    this.svg.select('.text1')?.text(`${formatValue(text3)}%`);
    this.svg.select('.text2')?.text(text1)?.append('title')?.text(originalText);
    this.svg.select('.text3')?.text(text2);
    this.svg
      .select('.arrow-icon')
      ?.attr('d', text3 > 0 ? this.upArrowIcon : this.downArrowIcon);
    this.svg?.select('.arrow-icon')?.attr('fill', text3 > 0 ? 'red' : 'green');
  }
}
