import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Alarm } from 'src/models/alarm.model';
import { AlarmService } from '../services/alarm.service';
import { DateUtil } from '../utils/date-util';
import { AuthenticationService } from '../services/authentication.service';
import { Subscription } from 'rxjs';
import { AppConstants } from '../constants/app-constants';
import { Utility } from '../utils/app-utils';
import { UserSessionStateService } from '../services/user-session-state.service';
import { TranslateService } from '@ngx-translate/core';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ricHandleStatus } from '../enums/ric-handle-status';

enum AlarmSortField {
	type = 'type',
	location = 'location',
	time = 'time',
	severity = 'severity',
	status = 'status'
}
@Component({
	selector: 'app-alarms',
	templateUrl: './alarms.component.html',
	styleUrls: ['./alarms.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmsComponent implements OnInit, OnDestroy {
	alarms: Alarm[] = [];
	selectedAlarmId: string;
	unAttentedAlarmCount: any;
	escalatedAlarmCount: any;
	searchText = '';
	alarmListSubscription: Subscription;
	alarmChangeSubscription: Subscription;
	alarmStatusSubscription: Subscription;
	alarmSortFields = AlarmSortField;
	asc = true;
	toggleOnAlarmListChange = true;
	utility = Utility;
	public isThermal: boolean = false;
	newAlarm = this.alarmService.onNewAlarmEvent;
	ricChangesSub: Subscription;
	alarmStatusSub: Subscription;

	constructor(public alarmService: AlarmService,
		public authService: AuthenticationService, private changeDetector: ChangeDetectorRef,
		public translate: TranslateService, private userSessionStateService: UserSessionStateService) { }

	async ngOnInit() {
		// this.escalatedAlarmCount = await this.alarmService.getEscalatedAlarmsCount();
		// this.unAttentedAlarmCount = await this.alarmService.getUnAttendedAlarmsCount();
		this.alarmListChangeListener();
		this.alarmSelectedListener();
		this.alarmStatusChangeListener();
		this.getAlarms(false);

		this.ricChangesSub = this.alarmService.getRicUpdates().subscribe(async (updatedAlarms: any | any[]) => {
			const alarmsArray = Array.isArray(updatedAlarms) ? updatedAlarms : [updatedAlarms];
			alarmsArray.forEach(updatedAlarm => {
				const index = this.alarms.findIndex(alarm => alarm.id === updatedAlarm._id);
				if (index !== -1) {
					this.alarms[index] = { ...this.alarms[index], ...updatedAlarm };
				}
			});
			this.changeDetector.detectChanges();
		});
	}

	ngOnDestroy() {
		this.alarmChangeSubscription = Utility.Unsubscribe(this.alarmChangeSubscription);
		this.alarmListSubscription = Utility.Unsubscribe(this.alarmListSubscription);
		this.ricChangesSub = Utility.Unsubscribe(this.ricChangesSub);
		this.alarmStatusSub = Utility.Unsubscribe(this.alarmStatusSub);
	}

	alarmListChangeListener() {
		this.alarmListSubscription = this.alarmService.getOnAlarmListChangedListener()
			.subscribe(async (alarms: Alarm[]) => {
				// this.escalatedAlarmCount = await this.alarmService.getEscalatedAlarmsCount();
				// this.unAttentedAlarmCount = await this.alarmService.getUnAttendedAlarmsCount();
				this.toggleOnAlarmListChange = !this.toggleOnAlarmListChange;
				this.alarms = alarms;
				this.alarms = this.alarms.map(alarm => ({
					...alarm,
					percent: this.getStatusPercentage(alarm.status)
				}));
				this.asc = true;
				this.sortAlarms(this.alarmSortFields.time);
				this.refreshComponent();
			});
	}

	getStatusPercentage(status: string): number {
		switch (status) {
			case AlarmStatus.ACKNOWLEDGED:
				return 25;
			case AlarmStatus.VERIFIED:
				return 50;
			case AlarmStatus.ESCALATED:
				return 75;
			case AlarmStatus.MAINTENANCE:
				return 75;
			case AlarmStatus.RESOLVED:
				return 100;
			case AlarmStatus.FALSE:
				return 100;
			default:
				return 0;
		}
	}

	getColorClass(percent: number): string {
		if (percent < 25) {
			return 'percent-red';
		} else if (percent < 50) {
			return 'percent-orange';
		} else if (percent < 75) {
			return 'percent-yellowgreen';
		} else {
			return 'percent-green';
		}
	}

	getIconDetails(alarm: Alarm): { iconClass: string, colorClass: string } {
		switch (alarm.ricHandleStatus) {
			case ricHandleStatus.STARTED:
				return { iconClass: 'fa fa-spinner fa-spin', colorClass: 'text-primary' };
			case ricHandleStatus.PENDING:
				return { iconClass: 'fa fa-spinner fa-spin', colorClass: 'text-primary' };
			case ricHandleStatus.RUNNING:
				return { iconClass: 'fa fa-spinner fa-spin', colorClass: 'text-primary' };
			case ricHandleStatus.ENDED:
				switch (alarm.status) {
					case AlarmStatus.RESOLVED:
						return { iconClass: 'fa fa-check', colorClass: 'text-success' };
					case AlarmStatus.FALSE:
						return { iconClass: 'fa fa-check', colorClass: 'text-success' };
					default:
						return { iconClass: 'fa fa-exclamation', colorClass: 'text-warning' };
				}
			case ricHandleStatus.FAILED:
				return { iconClass: 'fa fa-exclamation', colorClass: 'text-danger' };
			default:
				switch (alarm.status) {
					case AlarmStatus.RESOLVED:
						return { iconClass: 'fa fa-check', colorClass: 'text-success' };
					case AlarmStatus.FALSE:
						return { iconClass: 'fa fa-check', colorClass: 'text-success' };
					default:
						return { iconClass: 'fa fa-exclamation', colorClass: 'text-warning' };
				}
		}
	}

	alarmSelectedListener() {
		this.alarmChangeSubscription = this.alarmService.getOnAlarmSelectedListener().subscribe((alarm: Alarm) => {
			this.selectedAlarmId = alarm ? alarm.id : null;
			this.isThermal = this.alarmService.isThermalAlarm(alarm);
			this.userSessionStateService.modifyThermalState(this.isThermal);
			this.refreshComponent();
		});
	}

	alarmStatusChangeListener() {
		this.alarmStatusSub = this.alarmService.getChangeAlarmStatusNotification().subscribe((updatedAlarm) => {
			// Search for selected alarm and change its status
			this.alarms.forEach(alarm => {
				if (alarm.id === (updatedAlarm?._id || updatedAlarm.id)) {
					alarm.status = updatedAlarm.status;
					// alarm.status = this.alarmService.getAlarmStatus();
					alarm.percent = this.getStatusPercentage(alarm.status);
				}
			});
			this.refreshComponent();
		});
	}

	trackByAlarmId(index: number, alarm: Alarm) {
		return alarm.id;
	}

	onTabChange(event: MatTabChangeEvent) {
		if (event.index === 0) {
			this.getAlarms(false);
		} else if (event.index === 1) {
			this.getAlarms(true);
		}
	}

	/**
	 * Fetches alarm list from the server.
	 * @param searchText Text to search in the alarms,=
	 * @param sortBy Sort by field.
	 * @param sortDir Sort direction.
	 */
	getAlarms(filter?: boolean) {
		this.alarmService.fetchAlarmsList(filter);
	}

	/**
	 * Returns time in format dd MMM yyyy | H:mm:ss a
	 * @param activationTime Input date.
	 */
	getDate(activationTime: any) {
		return DateUtil.getFormattedDate(activationTime, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
	}

	/**
	 * Triggered when an alarm is clicked.
	 * @param alarm Alarm object
	 */
	selectAlarm(alarm: Alarm) {
		// Commenting this stop selection condition due to #295 Staging- After map search, Selected alarm is not triggered.
		// stop selecting same alarm again
		// if (this.selectedAlarmId !== alarm.id) {
		this.alarmService.selectAlarm(alarm);
		// }
	}

	/**
	 * Changes the sort order
	 * @param order sort order
	 */
	changeOrder(order: string) {
		if (order === 'asc') {
			return 'dsc';
		} else {
			return 'asc';
		}
	}

	/**
	 * Sorts alarm according to the field.
	 * @param sortField Field to sort alarms.
	 */
	sortAlarms(sortField: AlarmSortField) {
		this.asc = !this.asc;
		this.alarms = this.alarms.sort((alarm1: Alarm, alarm2: Alarm) => {
			let item1, item2;
			switch (sortField) {
				case AlarmSortField.type:
					item1 = alarm1.type;
					item2 = alarm2.type;
					break;
				case AlarmSortField.location:
					item1 = alarm1.branch.name.toUpperCase();
					item2 = alarm2.branch.name.toUpperCase();
					break;
				case AlarmSortField.status:
					item1 = alarm1.status.toUpperCase();
					item2 = alarm2.status.toUpperCase();
					break;
				case AlarmSortField.severity:
					item1 = alarm1.severity.toUpperCase();
					item2 = alarm2.severity.toUpperCase();
					break;
				case AlarmSortField.time:
					item1 = alarm1.activationTime;
					item2 = alarm2.activationTime;
					break;
			}
			if (this.asc === true) {
				return item1 < item2 ? -1 : 1;
			} else {
				return item1 > item2 ? -1 : 1;
			}
		});

		this.refreshComponent();
	}

	/**
	 * We are manually detecting changes to improve performance of page.
	 * Please call this function whenever you make changes to the alarm list.
	 */
	refreshComponent() {
		this.changeDetector.markForCheck();
	}

	getTextToShowBelowIcon(type: string) {
		type = type.trim();
		return type.length > 5 ? type.substring(0, 4) + '...' : type;
	}

	toggleAudio() {
		this.alarmService.allowAudio = !this.alarmService.allowAudio;
	}
}
