<div>
  <div class="d-flex align-items-center gap-10x custom-mb">
    <i class="fa fa-line-chart" aria-hidden="true"></i>
    <h2 class="title fw-300 text-white">Live Incidents</h2>
    <app-popover [title]="'Live Incidents'"
      [content]="'Incidents happened till now from the start of the day'"></app-popover>
  </div>
  <div class="incidents-block" *ngIf="data.length > 0">
    <div class="item-value box-border d-flex justify-content-between" *ngFor="let incident of data">
      <div class="item-heading d-flex align-items-center">
        <img [src]="
            incident.key === 'handling'
              ? 'assets/images/handling.svg'
              : incident.key === 'automation'
              ? 'assets/images/automation.svg'
              : incident.key === 'escalated'
              ? 'assets/images/escalated.svg'
              : incident.key === 'false'
              ? 'assets/images/false.svg'
              : ''
          " alt="" title="" width="16" height="24" />
        <p class="title label fw-600 text-white">{{ incident.label }}</p>
      </div>
      <span class="value fw-500 text-black">{{ formatIncidentValue(incident.value) }}</span>
    </div>
  </div>
  <div *ngIf="data.length === 0" class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>