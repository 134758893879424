import { Directive, ElementRef, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
	constructor(private _elementRef: ElementRef) {
	}
	@Output()
	public clickOutside = new EventEmitter();

	@HostListener('document:click', ['$event.target'])
	public onClick(targetElement: any) {
		const clickedInside = this._elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.clickOutside.emit(null);
		}
	}
}

