<div class="chart-block charttwo-block radius-15 box-border d-flex flex-column flex-grow-1">
  <div class="block-head d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-10x">
      <h2 class="title text-white">Alarms in realtime</h2>
      <app-popover [title]="'Alarms in realtime'" [content]="'Number of alarms raised in real time'"></app-popover>
      <mat-button-toggle-group class="time-buttons" [(ngModel)]="slotMinutes" (change)="onToggleChange($event)">
        <mat-button-toggle value="5" [class.active]="slotMinutes === 5" class="toggle-time-btn">5 min
        </mat-button-toggle>
        <mat-button-toggle value="20" [class.active]="slotMinutes === 20" class="toggle-time-btn">20 min
        </mat-button-toggle>
        <mat-button-toggle value="60" [class.active]="slotMinutes === 60" class="toggle-time-btn">1 hr
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="chart-wrap" id="time-of-the-day" *ngIf="data?.today?.length> 0 && data?.previous_day?.length> 0">
    <div class="chart">
      <app-time-of-the-day-linechart
        [isLive]="true"
        [data]="data"
        [width]="dynamicWidth"
        [height]="dynamicHeight"
        [colors]="['#1E84F1', '#7A68F6']"
        [legends]="getLegends()"
      ></app-time-of-the-day-linechart>
    </div>
  </div>
  <div *ngIf="data?.today?.length === 0 && data?.previous_day?.length === 0"
    class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>
