import { Component, AfterViewInit, AfterViewChecked, HostListener, ChangeDetectorRef } from '@angular/core';
import { InsightsService } from 'src/app/services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';

@Component({
  selector: 'app-repeated-alarms',
  templateUrl: './repeated-alarms.component.html',
  styleUrls: ['./repeated-alarms.component.scss'],
})
export class RepeatedAlarmsComponent
  implements AfterViewInit, AfterViewChecked {

  dynamicWidth: number = 300;
  dynamicHeight: number = 265;

  treemapData = {
    children: [],
  };

  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) { }

  async ngOnInit() {
    this.listenToDateRangeChanges();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getRepeatedTypes(this.startDate, this.endDate);
    });
  }

  async getRepeatedTypes(startDate?: number, endDate?: number) {
    const res = await this.insightsService.getRepeatedAlarmTypes(startDate, endDate);
    if (res && res.success && res.data && res.data.results) {
      this.treemapData = {
        children: res.data.results.map(item => ({
          label: item.alarmType,
          value: item.currentPeriodAlarmsCount,
          percentage: Number(item.percentage.toFixed(2)),
          icon: item.icon
        }))
      };
    } else {
      this.treemapData = { children: [] };
    }
  }

  ngAfterViewInit() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateChartWidth();
    this.cdr.detectChanges();
  }

  private updateChartWidth() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    if (screenWidth > 3000 && screenHeight > 1300) {
      this.dynamicWidth = 500;
      this.dynamicHeight = 420;
    }
    else if (screenHeight >= 1080) {
      this.dynamicWidth = 295;
      this.dynamicHeight = 325;
    }
    else if (screenHeight === 1056) {
      this.dynamicWidth = 300;
      this.dynamicHeight = 325;
    }
    else if (screenHeight >= 1024) {
      this.dynamicWidth = 400;
      this.dynamicHeight = 285;
    } else {
      this.dynamicWidth = 300;
      this.dynamicHeight = 295;
    }
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }

}
