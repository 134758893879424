<div class="div-container p-4 justify-content-center text-center">
	<div class="heading" (mouseover)='read(QUESTION)' (mouseout)='stopRead()'>
		{{ QUESTION }}
	</div>
</div>
<div class="d-flex justify-content-center mt-2 action-buttons">
	<button class="mr-2 btn btn-info" [disabled]="success_send || failure_send" (click)="yesClicked()"
		(mouseover)='read(YES)' (mouseout)='stopRead()'>
		{{ YES }}
	</button>
	<button class="cancel-button btn btn-info" [disabled]="success_send || failure_send" (click)="noClicked()"
		(mouseover)='read(NO)' (mouseout)='stopRead()'>
		{{ NO }}
	</button>
</div>

<div class="send_response">
	<mat-card class="success-card" *ngIf="success_send">
		<span class="success_text">{{ message }}</span>
		<nb-icon nbPrefix icon="close-outline" pack="eva" class="close-card" (click)="closeCard()">
		</nb-icon>
	</mat-card>

	<mat-card class="failure-card" *ngIf="failure_send">
		<span class="failure_text">{{ message }}</span>
		<nb-icon nbPrefix icon="close-outline" pack="eva" class="close-card" (click)="closeCard()">
		</nb-icon>
	</mat-card>
</div>