import { Component, OnInit } from '@angular/core';
import { NbWindowRef, NbWindowConfig, NbWindowState } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-window-form',
  templateUrl: 'window-form.component.html',
  styleUrls: ['window-form.component.scss'],
})
export class WindowFormComponent implements OnInit {
  logoutSubscriber: Subscription;
  windowState: Subscription;
  roomId: any;

  constructor(protected windowRef: NbWindowRef, public config: NbWindowConfig,
    public authService: AuthenticationService, public chatService: ChatService) {
  }

  ngOnInit(): void {
    this.roomId = this.config.context;
    this.closeOnLogout();
    this.setWindowState();
  }

  setWindowState() {
    this.windowState = this.windowRef.stateChange.subscribe(async (state) => {
      let data = { roomId: this.roomId, state: state }
      this.chatService.windowStateChange(data);
    })
  }

  close() {
    this.windowRef.close();
  }

  closeOnLogout() {
    this.logoutSubscriber = this.authService.closeChatWindowListner().subscribe(async () => {
      this.close();
    });
  }

  ngOnDestroy(): void {
    if (this.windowRef.state === NbWindowState.MAXIMIZED) {
      this.logoutSubscriber.unsubscribe();
    };
    this.windowState.unsubscribe();
  }

}
