<div class="chart-block chartone-block radius-15 box-border d-flex flex-column flex-grow-1">
  <div class="block-head d-flex align-items-center">
    <h2 class="title text-white">Top Sites</h2>
    <app-popover [title]="'Top 5 sites'" [content]="
        'Top 5 sites by alarms raised, with percentage change from the previous 7 days or chosen period.'
      "></app-popover>
  </div>
  <div class="chart-wrap d-flex justify-content-center align-items-center" *ngIf="pieData.length > 0">
    <div class="chart">
      <app-top-sites-donut [chartId]="'top-sites-donut'" [data]="pieData" [width]="dynamicWidth"
        [height]="dynamicHeight" [radius]="dynamicRadius" [arcSize]="arcSize" [colors]="[
          ['#cc0000', '#f44336'],
          ['#FF9C45','#D24E0D'],
          ['#ffd966', '#e69138'],
          ['#3E6FC3', '#5BB1EF'],
          ['#38761d', '#b6d7a8']
        ]"></app-top-sites-donut>
    </div>
  </div>
  <div *ngIf="pieData.length === 0" class="h-100 d-flex justify-content-center align-items-center flex-grow-1">
    {{ "INSIGHTS.NO_DATA" | translate }}
  </div>
</div>