<div class="d-flex justify-content-center align-items-center">
  <figure id="progress-total" class="position-relative m-0">
    <div class="progress-left-div position-absolute">
      <div class="d-flex justify-content-center align-items-center">
        <img class="alarmicon" src="../../assets/images/Alarm.svg" />
      </div>
    </div>
    <div class="progress-right-div d-flex justify-content-center align-items-center">
      <span>{{ absolutePercentage }}%</span>
      <div class="percentage-circle d-flex justify-content-center align-items-center"
        [ngStyle]="{ background: percentage > 0 ? 'red' : 'green' }">
        <ng-icon *ngIf="percentage !== 0" class="arrow-icon-progress"
          [name]="percentage > 0 ? 'FeatherArrowUp' : 'FeatherArrowDown'" color="#fff"></ng-icon>
      </div>
    </div>
  </figure>
</div>