import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/models/api-response.model';
import { AlarmReportEntry } from 'src/models/alarm-report.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, lastValueFrom } from 'rxjs';
import { ApiConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { AuthenticationService } from './authentication.service';
import { Utility } from '../utils/app-utils';
import { DateUtil } from '../utils/date-util';
import { TranslateService } from '@ngx-translate/core';
import { AppLocalStorage } from 'src/app/utils/app-local-storage-utils';

@Injectable({
	providedIn: 'root'
})
export class ReportService {
	public static BLOB: any = 'blob';
	public static CONTENT_TYPE_KEY = 'Content-Type';
	public static CONTENT_TYPE_VALUE = 'application/json';
	public static SINGLE_ALARM_REPORT_PARAM_STRING = 'responseFormat=pdf';
	public static SINGLE_ALARM_INFO_PARAM = 'responseFormat=json';
	public static REPORT_FILE_TYPE = 'application/pdf';
	public static ELEMENT_TARGET_BLANK = '_blank';
	// public static MONTHS: string[] = ['Jan', 'Feb', 'Mar', 'Apr',
	// 	'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	alarmReport: AlarmReportEntry[] = [];
	filterDataChangeListener = new Subject<AlarmReportEntry[]>();
	alarmFilterParameter: any;
	// hours: any;
	alarmCount = 0;
	language: any;

	constructor(private http: HttpClient, public authService: AuthenticationService,
		public translateService: TranslateService) {
	}

	/**
	 * assigns changed all alarms report data on filter
	 * @returns observable of changed report data
	 */
	getOnFilterDataChangeListener() {
		return this.filterDataChangeListener.asObservable();
	}

	/**
	 * sets all alarms data to a filter data change listener
	 * @param alarmReport alarm history report data
	 */
	changeFilterData(alarmReport: AlarmReportEntry[]) {
		this.alarmReport = alarmReport;
		this.filterDataChangeListener.next(this.alarmReport);
	}

	/**
	 * receives all alarms history report data from server
	 * @param requestBody filterData requestBody
	 * @returns Promise with response data
	 */
	async getReportDetails(requestBody: any) {
		this.alarmFilterParameter = requestBody;
		const response: any = await lastValueFrom(this.http.post<any>(ApiConstants.URL_ALARM_HISTORY_REPORT, requestBody));
		if (response.success) {
			this.alarmCount = response.totalcount || 0;
			return response;
		}
	}

	/**
	 * receives all alarms history report pdf from server
	 * @param requestBody filterData requestBody
	 */
	async getReportDetailsPdf(requestBody: any) {
		// const componentRef = Utility.toggleLoadMask(false);
		try {
			await this.http.post(ApiConstants.URL_ALARM_HISTORY_REPORT, requestBody, {
				responseType: ReportService.BLOB,
				headers: new HttpHeaders().append(ReportService.CONTENT_TYPE_KEY, ReportService.CONTENT_TYPE_VALUE)
			}).subscribe(response => {
				// Utility.toggleLoadMask(componentRef);
				this.generatePdfReports(response, this.translateService.instant('REPORTS.FULL_REPORT_NAME'));
			});
		} catch (err) {
			// Utility.toggleLoadMask(componentRef);
		}
	}

	async getSingleAlarmInfo(alarmId: string) {
		const url = ApiConstants.URL_ALARM_HISTORY_REPORT + '/' + alarmId + '?' + ReportService.SINGLE_ALARM_INFO_PARAM;
		const response = await lastValueFrom(this.http.get(url, { headers: new HttpHeaders().append(ReportService.CONTENT_TYPE_KEY, ReportService.CONTENT_TYPE_VALUE) }));
		return response;
	}

	/**
	 * receives single alarm history report pdf from server
	 * @param alarmId id of alarm
	 */
	async getSingleAlarmReportPdf(alarmId: string) {
		// const componentRef = Utility.toggleLoadMask(false);
		const url = ApiConstants.URL_ALARM_HISTORY_REPORT + '/' + alarmId + '?' +
			ReportService.SINGLE_ALARM_REPORT_PARAM_STRING;
		try {
			const response = await lastValueFrom(this.http.get(url, { responseType: ReportService.BLOB, headers: new HttpHeaders().append(ReportService.CONTENT_TYPE_KEY, ReportService.CONTENT_TYPE_VALUE) }));
			if (response) {
				// Utility.toggleLoadMask(componentRef);
				this.generatePdfReports(response, this.translateService.instant('REPORTS.SINGLE_ALARM_REPORT_NAME'));
			}
		} catch (err) {
			// Utility.toggleLoadMask(componentRef);
		}
	}

	/**
	 * downloads pdf reports
	 * @param data  blob of alarm history report
	 * @param fileName string name for downloaded pdf file
	 */
	generatePdfReports2(data: any, fileName: string) {
		// const date = new Date();
		const currentTime = new Date().toLocaleString('en-US');
		const formatedTime = this.formatDate(currentTime);
		const datePostfix = formatedTime;
		// const datePostfix = date.getDate() + ReportService.MONTHS[date.getMonth()] + date.getFullYear().toString().substr(2, 2) 
		// + "_" + date.getHours() + ":" + date.getMinutes().toString() + ":" + date.getSeconds();
		try {
			const hiddenElement = document.createElement('a');
			const url = window.URL.createObjectURL(new Blob([data],
				{ type: ReportService.REPORT_FILE_TYPE }));
			hiddenElement.href = url;
			hiddenElement.target = ReportService.ELEMENT_TARGET_BLANK;
			hiddenElement.download = fileName + '-' + datePostfix + '.' + AppConstants.PDF;
			document.body.appendChild(hiddenElement);
			hiddenElement.click();
		} catch (err) {
		}
	}


	/**
 * New function for pdf reports download supporting multi-languages on pdf name.
 * @param data  blob of alarm history report
 * @param fileName string name for downloaded pdf file
 */
	async generatePdfReports(data: any, fileName: string) {
		const currentTime = new Date();
		this.language = AppLocalStorage.getLanguage();
		let lang: string = 'en-US'
		if (this.language === "ar") {
			lang = 'ar-AE'
		} else if (this.language === "ru") {
			lang = 'ru-RU'
		} else {
			lang = 'en-US'
		}
		const formatedTime = currentTime.toLocaleString(lang, {
			year: 'numeric',
			month: 'long',
			day: '2-digit',
			hour: 'numeric',
			minute: '2-digit',
			second: '2-digit',
			timeZone: this.authService.getUserTimezone()
		}).replace(/\.|:/g, "_"); // replace dots and colons with underscores

		let datePostfix = formatedTime;

		const hours = currentTime.getHours();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const hours12 = hours % 12 || 12;
		const minutes = currentTime.getMinutes();
		datePostfix = datePostfix.replace(`${hours}:${minutes}`, `${hours12}:${minutes} ${ampm}`);

		try {
			const hiddenElement = document.createElement('a');
			const url = window.URL.createObjectURL(new Blob([data], {
				type: ReportService.REPORT_FILE_TYPE
			}));
			hiddenElement.href = url;
			hiddenElement.target = ReportService.ELEMENT_TARGET_BLANK;
			hiddenElement.download = fileName + '-' + datePostfix + '.' + AppConstants.PDF;
			document.body.appendChild(hiddenElement);
			hiddenElement.click();
		} catch (err) { }
	}

	// Date format to (DD MMM YYYY | hh:mm:ss a):
	formatDate(currentTime: any) {
		return DateUtil.formatDate(currentTime, AppConstants.DATE_TIME_FORMAT, this.authService.getUserTimezone());
	}

	generateCSVReports2(data: any, fileName: string) {
		// const date = new Date();
		const currentTime = new Date().toLocaleString('en-US');
		const formatedTime = this.formatDate(currentTime);
		const datePostfix = formatedTime;
		// const datePostfix = date.getDate() + ReportService.MONTHS[date.getMonth()] + date.getFullYear().toString().substr(2, 2) 
		// + "_" + date.getHours() + ":" + date.getMinutes().toString() + ":" + date.getSeconds();
		try {
			const hiddenElement = document.createElement('a');
			const url = window.URL.createObjectURL(new Blob([data],
				{ type: ReportService.REPORT_FILE_TYPE }));
			hiddenElement.href = url;
			hiddenElement.target = ReportService.ELEMENT_TARGET_BLANK;
			hiddenElement.download = fileName + '-' + datePostfix + '.' + AppConstants.CSV;
			document.body.appendChild(hiddenElement);
			hiddenElement.click();
		} catch (err) {
		}
	}

	// New function for CSV reports download supporting multi-languages on file name
	async generateCSVReports(data: any, fileName: string) {
		const currentTime = new Date();
		this.language = AppLocalStorage.getLanguage();
		let lang: string = 'en-US'
		if (this.language === "ar") {
			lang = 'ar-AE'
		} else if (this.language === "ru") {
			lang = 'ru-RU'
		} else {
			lang = 'en-US'
		}
		const formatedTime = currentTime.toLocaleString(lang, {
			year: 'numeric',
			month: 'long',
			day: '2-digit',
			hour: 'numeric',
			minute: '2-digit',
			second: '2-digit',
			timeZone: this.authService.getUserTimezone()
		}).replace(/\.|:/g, "_"); // replace dots and colons with underscores

		let datePostfix = formatedTime;

		const hours = currentTime.getHours();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const hours12 = hours % 12 || 12;
		const minutes = currentTime.getMinutes();
		datePostfix = datePostfix.replace(`${hours}:${minutes}`, `${hours12}:${minutes} ${ampm}`);

		try {
			const hiddenElement = document.createElement('a');
			const url = window.URL.createObjectURL(new Blob([data], {
				type: ReportService.REPORT_FILE_TYPE
			}));
			hiddenElement.href = url;
			hiddenElement.target = ReportService.ELEMENT_TARGET_BLANK;
			hiddenElement.download = fileName + '-' + datePostfix + '.' + AppConstants.CSV;
			document.body.appendChild(hiddenElement);
			hiddenElement.click();
		} catch (err) { }
	}

}
