import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  assetStateSubscriber = new BehaviorSubject<any>(null);
// assetStateSubscriber = new Subject<any>();
  assetState: any;

  constructor() {}

  // Modify Asset State
	public modifyAssetState(value:any) {
		this.assetState = value;
		this.assetStateSubscriber.next(this.assetState);
	}

	public getAssetState() {
		return this.assetState;
	}

	public getAssetStateChangeNotification() {
		return this.assetStateSubscriber.asObservable();
	}

}


