import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../constants/route-constants';
import { AuthenticationService } from '../services/authentication.service';
import { AppConstants } from '../constants/app-constants';
import { AppConfigService } from 'src/app/app-config.service';
import { DateUtil } from '../utils/date-util';
import { AnalyticalDashboardService } from '../services/analytical-dashboard.service';
import { AlarmService } from '../services/alarm.service';
import { Utility } from '../utils/app-utils';
import { TranslateService } from '@ngx-translate/core';
import { AppLocalStorage } from 'src/app/utils/app-local-storage-utils';
import { ApiConstants } from '../constants/api-constants';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	/**
	 * Input  of header component
	 * session contains user's and  client's info.
	 */
	@Input() session: any;
	logoUrl: string;
	user: any;
	currentTime: string;
	currentDate: string;
	trialPeriodRemaining: any;
	trialEndTime: any;
	setTrialEndTime = false;
	errorMessage: any;
	sessionStartTime: number;
	sessionInterval = 60000;
	sessionValidityCheckTimer: any = null;
	isShowingUserDropdown: boolean = false;
	// dateFormat = AppConstants.USER_DATE_FORMAT;
	// timeFormat = AppConstants.USER_TIME_FORMAT;
	APP_CONFIG = this.appConfigService.appConfig;
	isSupervisor = false;
	getConfidenceMonitoringSubscriber: any;
	isOperator = false;
	isAdmin = false;
	utility = Utility;
	arabic: boolean = false;
	userImage: string = '';

	/**
	 * Creates an instance of header component.
	 * @param router navigation between the components.
	 * @param authenticationService provide user authentication utilities.
	 */
	constructor(
		public alarmService: AlarmService,
		private router: Router,
		private authenticationService: AuthenticationService,
		public analyticalDashboardService: AnalyticalDashboardService,
		private appConfigService: AppConfigService,
		public translate: TranslateService,
	) {
	}

	async ngOnInit() {
		this.isSupervisor = this.authenticationService.hasRole(AppConstants.Supervisor);
		this.isOperator = this.authenticationService.hasRole(AppConstants.Operator);
		this.isAdmin = this.authenticationService.hasRole(AppConstants.Admin);
		this.logoUrl = this.APP_CONFIG.API_SERVER + this.session.activeProfile.client.logUrl;
		this.userImage = this.session?.image ? ApiConstants.API_SERVER + this.session.image : 'assets/profile.png';

		// Date/Time calculation supporting multi-languages & calenders.
		const language = AppLocalStorage.getLanguage();
		const dateOptions: Intl.DateTimeFormatOptions = {
			weekday: language === 'ru' ? 'long' : 'short',
			month: 'long',
			day: 'numeric',
			timeZone: this.session.timeZone,
		};

		const timeOptions: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: 'numeric',
			hour12: language === 'ru' ? false : true,
			timeZone: this.session.timeZone,
		};

		const dateFormat = new Intl.DateTimeFormat(language, dateOptions);
		const timeFormat = new Intl.DateTimeFormat(language, timeOptions);

		this.currentDate = dateFormat.format(new Date());
		this.currentTime = timeFormat.format(new Date());

		setInterval(() => {
			this.currentDate = dateFormat.format(new Date());
			this.currentTime = timeFormat.format(new Date());
			if (!this.setTrialEndTime) {
				if (this.session.activeProfile.client.trialPeriodRemaining) {
					this.trialEndTime = new Date();
					this.trialEndTime = new Date(this.trialEndTime.getTime() + this.session.activeProfile.client.trialPeriodRemaining);
				}
				this.setTrialEndTime = true;
			}
			if (this.trialEndTime) {
				this.trialPeriodRemaining = DateUtil.getTrialPeriodRemaining(Math.abs(this.trialEndTime.getTime() - new Date().getTime()));
				if (this.trialPeriodRemaining.days === 0 && this.trialPeriodRemaining.hours === 0 && this.trialPeriodRemaining.minutes === 0 && this.trialPeriodRemaining.sec === 0) {
					this.authenticationService.logout();
				}
			}
		}, AppConstants.APP_CONFIG_INIT_TIMER);
	}

	ngOnDestroy() {
		Utility.Unsubscribe(this.getConfidenceMonitoringSubscriber);
		clearInterval(this.sessionValidityCheckTimer);
	}

	/**
	 * Redirects user to dashboard page.
	 */
	goToDashBoard() {
		this.isShowingUserDropdown = false;

		this.router.navigate([RouteConstants.PAGES + RouteConstants.DASHBOARD]);
	}

	/**
	 * Redirects user to report page.
	 */
	goToReportsPage() {
		this.isShowingUserDropdown = false;
		this.router.navigate([RouteConstants.PAGES + RouteConstants.REPORTS]);
	}

	/**
	 * Redirects user to analytical dashboard page.
	 */
	goTOAnalyticalDashboard() {
		this.analyticalDashboardService.chartSelected = 1;
		this.isShowingUserDropdown = false;
		this.router.navigate([RouteConstants.PAGES + RouteConstants.ANALYTICAL_DASHBOARD]);
	}

	/**
	 * End session.
	 * On successful session logout navigates to Login page.
	 */
	logout() {
		this.isShowingUserDropdown = false;
		this.authenticationService.logout();
	}

	showUserDropDown() {
		this.isShowingUserDropdown = !this.isShowingUserDropdown;
	}

	// UI Language Toggle functions:
	// switchToArabic(){
	// 	this.translate.use('ar');
	// 	this.arabic = true;
	// }

	// switchToEnglish(){
	// 	this.translate.use('en');
	// 	this.arabic = false;
	// }

	// useLanguage(language: string): void {
	// 	this.translate.use(language);
	// }

}
