<div class="dialog-content">
    <img class="photo" alt="photo" [src]="media.url" *ngIf="media.type ==='photo'">

    <video controls (click)="toggleVideo()" #videoPlayer *ngIf="media.type ==='video'" alt="video">
        <track label="English" kind="captions" srclang="en" default>
        <track label="Arabic" kind="captions" srclang="ar">
        <source [src]="media.url" type="video/mp4" />
        Your browser does not support HTML5 video.
    </video>

    <nb-icon nbPrefix icon="close-outline" pack="eva" class="close-dialog" (click)="closeDialog()">
    </nb-icon>
</div>