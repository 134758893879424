import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class ConfidenceMonitoringService {
	sitesSelectedNotification = new Subject<any>();
	public ASSIGN_TYPE_OF_DIALOG_BOX = 1;
	public DIFFERENCE_FOR_TABLE_HEIGHT = 150;
	public SITES_FILTER_ID = 'sites-filter';
	public INCIDENT_CONTAINER_ID = 'incident-container-id';
	public CONFIDENCE_MONITOR_SITES_ID = 'confidence-monitor-sites';

	constructor(
		public translate: TranslateService,
		public dialog: MatDialog
	) { }

	changeSelectedSite(selectedBranch: any) {
		this.sitesSelectedNotification.next(selectedBranch);
	}

	getSitesChangedNotification() {
		return this.sitesSelectedNotification.asObservable();
	}

	changeTableSizeOnAddedFilter(TableID) {
		requestAnimationFrame(() => {
			const filter = document.getElementById(this.SITES_FILTER_ID);
			const filterEle = filter.getClientRects();
			const incidentDiv = document.getElementById(this.INCIDENT_CONTAINER_ID);
			const incidentDivEle = incidentDiv && incidentDiv.getClientRects();
			const parentElement = document.getElementById(this.CONFIDENCE_MONITOR_SITES_ID);
			const parentHeight = parentElement && parentElement.getClientRects();
			if(parentHeight && filterEle && incidentDivEle){
			const newHeight = (parentHeight[0].height - filterEle[0].height) - incidentDivEle[0].height;
			document.getElementById(TableID).style.height = (newHeight - this.DIFFERENCE_FOR_TABLE_HEIGHT) + 'px';
			document.getElementById(TableID).style.maxHeight = (newHeight - this.DIFFERENCE_FOR_TABLE_HEIGHT) + 'px';
			}
		});
	}
}
