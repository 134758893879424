import { Component, Input, OnInit, EventEmitter, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss']
})
export class RangePickerComponent implements OnInit {
  @Input() ranges: { value: string, label: string }[] = [];
  @Output() rangeClick = new EventEmitter<string>();
  @Input() selectedRange: string = '';
  @Input() dateRange: { startDate: string, endDate: string } | null = null;
  @Output() rangeReset = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  onRangeClick(range: string) {
    this.rangeClick.emit(range);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dateRange'] && this.dateRange) {
      this.selectedRange = '';
      this.rangeReset.emit();
    }
  }
}
