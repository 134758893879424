import { Component, OnInit } from '@angular/core';
import { InsightsService } from 'src/app/services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from 'src/app/utils/app-utils';
import { AlarmStatus } from 'src/app/enums/alarm-status.enum';

@Component({
  selector: 'app-operator-ranking',
  templateUrl: './operator-ranking.component.html',
  styleUrls: ['./operator-ranking.component.scss'],
})

export class OperatorRankingComponent implements OnInit {
  tableData = {
    columns: [
      { label: 'Operator Name', key: 'name' },
      { label: AlarmStatus.ACKNOWLEDGED, key: 'acknowledged' },
      { label: AlarmStatus.VERIFIED, key: 'verified' },
      { label: AlarmStatus.ESCALATED, key: 'escalated' },
      { label: AlarmStatus.FALSE, key: 'false' },
      { label: AlarmStatus.RESOLVED, key: 'resolved' },
    ],
    values: []
  };

  private dateRangeSubscription: Subscription;
  private startDate: number | undefined;
  private endDate: number | undefined;

  constructor(public insightsService: InsightsService) { }

  async ngOnInit() {
    this.listenToDateRangeChanges();
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(dateRange => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      this.getOperatorsRanking(this.startDate, this.endDate);
    });
  }

  async getOperatorsRanking(startDate?: number, endDate?: number) {
    let res = await this.insightsService.getOperatorsActionRanking(startDate, endDate);
    if (res && res.success && res.data && res.data.results) {
      this.tableData.values = this.formatTableData(res.data.results);
    } else {
      this.tableData.values = [];
    }
  }

  private formatTableData(data: any[]): any[] {
    return data.map(item => ({
      name: `${item.user.firstName} ${item.user.lastName}`,
      acknowledged: item.actions.acknowledge ?? '-',
      verified: item.actions.verify ?? '-',
      escalated: item.actions.escalate ?? '-',
      false: item.actions.false ?? '-',
      resolved: item.actions.resolve ?? '-'
    }));
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }

}
