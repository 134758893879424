<div class="main-body">
	<div class="alerts">
		<app-alarms></app-alarms>
	</div>
	<div class="assets">
		<div class="box-header">
			<div class="d-flex align-items-center">
				<i class="fa fa-bars" aria-hidden="true"></i>
			</div>
			<p class="assets-header">{{"DASHBOARD.HEADER_TEXT" | translate}}</p>
		</div>

		<div class="assets-section">
			<app-video-feeds class="video-feed-box" [shouldCloseFullScreen]="assetDocSelectedChanged" *ngIf="!isThermal">
			</app-video-feeds>
			<app-thermal-feeds class="video-feed-box" [alarmId]="selectedAlarmId" *ngIf="isThermal">
			</app-thermal-feeds>
		</div>

		<div class="asset-view-section">
			<app-asset-viewer [assetDoc]="assetDoc"></app-asset-viewer>
		</div>

	</div>

	<div class="right-content">
		<app-right-section></app-right-section>
	</div>